import { useEffect, useLayoutEffect, useCallback, useState, Children, HTMLAttributes, ReactNode } from 'react';
import { MOBILE_VIEWPORT_WIDTH } from '../../Typings';
import { ReactComponent as StackSvg } from '../../assets/Stack.svg';
import DropdownMenu from './DropdownMenu';
import './Navbar.css';

export interface NavbarProps extends HTMLAttributes<HTMLElement> {
  responsiveMenuItemsArray?: Array<string>;
  onDropdownMenuItemSelect?: (text: string, index: number) => void;
  children?: ReactNode;
};

const NavLogo = ({ children, ...props }: NavbarProps) => {
  const getClassName = () => `nav-logo${props.className ? ` ${props.className}` : ''}`;

  return (
    <section {...props} className={getClassName()}>{children}</section>
  );
};

const NavStart = ({ children, ...props }: NavbarProps) => {
  const getClassName = () => `nav-content-start${props.className ? ` ${props.className}` : ''}`;

  return (
    <section className={getClassName()}>{children}</section>
  );
};

const NavEnd = ({ children, ...props }: NavbarProps) => {
  const getClassName = () => `nav-content-end${props.className ? ` ${props.className}` : ''}`;

  return (  
    <section className={getClassName()}>{children}</section>
  );
};

const Navbar = ({ responsiveMenuItemsArray = [], onDropdownMenuItemSelect = () => {}, children, ...props }: NavbarProps) => {
  const [ mobile, setMobile ] = useState(false);
  const navLogo = () => Children.map(children, (child: any) => Object.is(child.type, NavLogo) && child);
  const navStart = () => Children.map(children, (child: any) => Object.is(child.type, NavStart) && child);
  const navEnd = () => Children.map(children, (child: any) => Object.is(child.type, NavEnd) && child);
  const getClassName = () => `navbar${props.className ? ` ${props.className}` : ''}`;

  const handleWindowResize = useCallback(() => {
    const isMobile = window.innerWidth < MOBILE_VIEWPORT_WIDTH;
    setMobile(isMobile)
  }, []);

  useLayoutEffect(() => {
    const isMobile = window.innerWidth < MOBILE_VIEWPORT_WIDTH;
    setMobile(isMobile)
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleMenuItemSelect = (text: string, index: number) => onDropdownMenuItemSelect(text, index);

  return (
    <nav {...props} className={getClassName()}>
      { navLogo() }
      <section className='nav-content-container'>
        {
          (responsiveMenuItemsArray.length === 0 || !mobile) &&
            <>
              {navStart()}
              {navEnd()}
            </>
        }
        { 
          (responsiveMenuItemsArray.length > 0 && mobile) &&
            <NavEnd className='nav-end-responsive-stack'>
              <DropdownMenu
                root={ <StackSvg /> }
                handleItemSelect={handleMenuItemSelect}
                items={responsiveMenuItemsArray} 
              />
            </NavEnd>
        }
      </section>
    </nav>
  );
};

export default Navbar;
export { NavLogo, NavStart, NavEnd };