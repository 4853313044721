import { useEffect, useReducer, MouseEvent } from 'react';
import { FastFieldProps } from 'formik';
import FormFieldMessage from '../../common/FormFieldMessage';
import StateButton from '../../common/StateButton';
import ValidationErrorSvg from '../../../assets/ValidationError.svg';

export interface IChoiceAnswerProps extends FastFieldProps {
  minChoices: number;
  maxChoices: number;
  choicesJson: Array<any>;
};

type ButtonStateAction = {
  key: string;
};

const ChoiceAnswer = ({ form: { touched, errors, setFieldValue }, field, maxChoices, choicesJson}: IChoiceAnswerProps) => {
  const singleChoice = maxChoices === 1;

  const reducer = (states: Set<string>, action: ButtonStateAction) => {
    const id = action.key;
    const answer = choicesJson.find((answer) => answer.id === id);
    let newStates: Set<string>;

    if (singleChoice || answer?.mutuallyExclusive)
      newStates = new Set<string>();
    else {
      newStates = new Set(states);

      const xAnswer = choicesJson.find((choice) => choice.mutuallyExclusive);
      if (xAnswer && newStates.has(xAnswer.id))
        newStates.delete(xAnswer.id);
    }

    if (states.has(id))
      newStates.delete(id);
    else
      newStates.add(id);

    return newStates;
  };

  const getButtonStates = () => {
    const keys = choicesJson.map((choice) => choice.id);
    const states = new Set<string>();
    let answers = field.value;

    if (!Array.isArray(answers))
      answers = [answers];

    if (answers) {
      answers.forEach((id: string) => {
        const index = keys.indexOf(id);
        if (index > -1) {
          states.add(id);
        }
      });
    }

    return states;
  };

  const [ buttonStates, buttonStatesDispatch ] = useReducer(reducer, getButtonStates());

  useEffect(() => {
    // Update form field value
    const answers = Array<string>();
    buttonStates.forEach((key) => answers.push(key));
    setFieldValue(field.name, answers);
  }, [buttonStates, field.name, setFieldValue]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;
    const key = target.getAttribute('data-index');
  
    if (key)
      buttonStatesDispatch({ key: key });
  };

  const getText = (answer: any) => {
    if (!answer.subText)
      return <span>{answer.text}</span>;

    return <><span>{answer.text}</span><br /><span>{answer.subText}</span></>
  };

  const isInvalid = () => touched[field.name] && errors[field.name];

  return (
    <>
      {
        choicesJson.map(value =>
          <StateButton key={value.id} data-index={value.id} selected={buttonStates.has(value.id)} onClick={handleClick}>
            {
              getText(value)
            }
          </StateButton>
        )
      }
      {
        isInvalid() && 
          <div className='question-answer-validation'>
            <img src={ValidationErrorSvg} alt='Validation' />
            <FormFieldMessage text={errors[field.name]} />
          </div>
      }
    </>
  )
}

export default ChoiceAnswer;