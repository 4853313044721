import { FC, useLayoutEffect } from 'react';
import { Route, Redirect, RouteProps, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { QuestionnaireProvider } from '../../context/QuestionnaireContext';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';

const UserRoute: FC<RouteProps> = ({ children, ...props }) => {
  const { isUser, hasExpiredToken } = useAuth();
  const { referrer, clearReferrer } = useTruckerFyApp();
  const history = useHistory();

  useLayoutEffect(() => {
    if (referrer && isUser()) {
      clearReferrer(); // Redirection to the referrer should only occur once
    }
  }, [referrer, isUser, clearReferrer]);

  return (
    <QuestionnaireProvider>
      <Route
        exact
        {...props}
        render = {() => {
          if (isUser())
            return <>{children}</>;
          else if (hasExpiredToken()) {
            history.push('/login', { isAuthExpired: true });
            return;
          } 
            
          return <Redirect to='/login' />;
        }}
      ></Route>
    </QuestionnaireProvider>
  );
};

export default UserRoute;