import 'chartjs-adapter-date-fns';

import { Chart as ChartJS, LineController, LineElement, PointElement, registerables } from 'chart.js';
import { useEffect, useRef, useState } from 'react';

import { Chart } from 'react-chartjs-2';
import CrosshairsChartPlugin from '../../../classes/CrosshairsChartPlugin';
import InterpolatedPointChartPlugin from '../../../classes/InterpolatedPointChartPlugin';
import { useJobs } from '../../../context/JobsContext';

//import MaxPointChartPlugin from '../../../classes/MaxPointChartPlugin';

const ViewsChart = () => {
  ChartJS.register(...registerables, CrosshairsChartPlugin, InterpolatedPointChartPlugin, /* MaxPointChartPlugin, */ PointElement, LineElement, LineController);

  const chartRef = useRef(null);
  const { activeJob } = useJobs();
  const [ chartData, setChartData ] = useState<any>({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    const chart = chartRef.current;
  
    if (!chart || !activeJob) return;

    const getViewsData = () => {
      if (!activeJob.viewsByDate || activeJob.viewsByDate.length < 1)
        return undefined;

      let sortedviewsByDate = activeJob.viewsByDate.sort((l, r) => l.date.getTime() === r.date.getTime() ? 0 : l.date < r.date ? -1 : 1);
      let chartPoints = [];
      let now = new Date();
      now.setHours(0, 0, 0, 0);
      const startDate = new Date((sortedviewsByDate[0] as any).date) < now ? new Date((sortedviewsByDate[0] as any).date) : new Date(now);
      startDate.setDate(startDate.getDate() - 1);
      const endDate = new Date(now);
      endDate.setDate(endDate.getDate() + 1);
      
      let thisDate = new Date(startDate);

      while (thisDate <= endDate) {
        let existingEntry = sortedviewsByDate.find(fbd => fbd.date.getTime() === thisDate.getTime());

        if (existingEntry) {
          chartPoints.push({ x: existingEntry.date, y: existingEntry.anonymousViews + existingEntry.knownViews })
        } else { 
          chartPoints.push({ x: new Date(thisDate), y: 0 })
        }

        thisDate.setDate(thisDate.getDate() + 1);
        existingEntry = undefined;
      }
      return chartPoints;
    };

    const chartData = {
      labels: [],
      datasets: [
        {
          fill:'start',
          type: 'line' as const,
          borderWidth: 3,
          borderColor: '#2CDDC0', // var(--app-green) *ChartJS doesn't support color variables in config
          data: getViewsData(),
          backgroundColor: 'rgba(70, 149, 111, 0.2)' // var(--key-lime)
        }
      ]
    };

    setChartData(chartData);
  }, [activeJob]);

  return (
    <Chart
      ref={chartRef}
      type='line'
      data={chartData}
      options={{
        interpolatedPointLabel: 'Views',
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          // @ts-ignore: used by InterpolatedPointChartPlugin
          mode: 'interpolate'
        },
        layout: {
          padding: {
            top: 50,
            right: 30,
            bottom: 10,
            left: 30
          }
        },
        elements: {
          line: {
            tension: .4,
            borderWidth: 1.5
          },
          point: { radius: 0 }
        },
        scales: {
          gridLines: {
            display: true,
            ticks: {
              display: false
            }
          },
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: { day: 'M/d' },
              stepSize: 1
            },
            ticks: {
              font: {
                family: 'Roboto',
                size: 12,
                weight: '300'
              },
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              color: '#898a8d' // var(--medium-gray)
            },
            grid: {
              display: false,
              drawBorder: false
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              display: true,
              font: {
                family: 'Roboto',
                size: 12,
                weight: '300'
              },
              color: '#898a8d' // var(--medium-gray)
            },
            grid: {
              display: false,
              drawBorder: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            intersect: false
          }
        }
      }}
    />
  );
};

export default ViewsChart;