/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * from https://stackoverflow.com/a/21742107/2805154
 *
 * @returns {String}
 */
export const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  switch (true) {
    case /windows phone/i.test(userAgent): // Windows Phone must come first because its UA also contains "Android"
      return 'Windows Phone';
    case /android/i.test(userAgent):
      return 'Android';
    case /iPad|iPhone|iPod/.test(userAgent): // iOS detection from: http://stackoverflow.com/a/9039885/177710
      return 'iOS';
    default:
      return 'unknown';
  }
};
