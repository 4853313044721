import './Dashbar.css';

import { Area, JobModel } from '../../../Typings';
import Navbar, { NavEnd, NavLogo } from '../../common/Navbar';

import { ReactComponent as CaretDown } from '../../../assets/CaretDown.svg'
import DropdownMenu from '../../common/DropdownMenu';
import EditProfile from '../../dialogs/EditProfile';
import { PersonAvatar } from '../cloudinary/PersonAvatar';
import { getDashbarLogoContent } from '../../helper/JobHelper';
import { useAuth } from '../../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../context/ModalContext';
import { useTruckerFyApp } from '../../../context/TruckerFyAppContext';

type DashbarProps = {
  area: Area;
  job?: JobModel;
};

const Dashbar = ({ area, job }: DashbarProps) => {
  const history = useHistory();
  const { getUserInfo } = useAuth();
  const { compose, destroy } = useModal();
  const { zenDeskVisible, toggleZenDesk } = useTruckerFyApp();

  const menuItemsArray = [ 
    // 'Share with colleagues', 
    // 'Add another account', 
    'Edit Profile', 
    // 'Todo',
    `${zenDeskVisible ? 'Hide ' : ''}Help & Feedback`,
    '-', 
    'Log out'
  ];

  const redirectToLogin = () => history.push('/login', { isAuthExpired: true });

  const handleEditProfile = () => compose(<EditProfile handleEditProfileComplete={handleEditProfileComplete} redirectToLogin={redirectToLogin}/>);

  const handleEditProfileComplete = () => destroy();
  
  const handleMenuItemSelect = (text: string, index: number) => {
    const win = window as any;
    switch (text) {
      case 'Share with colleagues':
        break;
      case 'Add another account':
        history.push('/usermanager');
        break;
      case 'Edit Profile':
        handleEditProfile();
        break;
      case 'Hide Help & Feedback':
      case 'Help & Feedback':
        toggleZenDesk();
        break;
      case 'Log out':
        win.zE('webWidget', 'hide');
        history.push('/logout');
        break;
      case 'Todo':
        history.push('/todo');
        break;
    }
  };

  const composeUserAvatarNameDropdown = () => {
    return (
      <div className='dashbar-user-avatar-name'> 
        <div className='fw-3 fs-14 ls-2' style={{ flex: '1', marginRight: '10px' }}>{getUserInfo()?.firstName} {getUserInfo()?.lastName}</div>
        <PersonAvatar publicId={getUserInfo()?.photoUrl} size={44} />
        <CaretDown className='dashbar-user-avatar-name-caret' style={{ fill: '#0A0C0B', height: '14px', width: '14px', opacity: '.9', marginLeft: '5px' }} />
      </div>
    );
  };

  return (
    <Navbar className='dashbar'>
      <NavLogo>
        {getDashbarLogoContent(area, job)}
      </NavLogo>
      <NavEnd>
        <DropdownMenu
          root={ composeUserAvatarNameDropdown() }
          handleItemSelect={handleMenuItemSelect}
          style={{ marginLeft: '20px' }}
          items={menuItemsArray} 
        />
      </NavEnd>
    </Navbar>
  );
};

export default Dashbar;
