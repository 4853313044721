import React, { useRef, useState, useCallback, useEffect, HTMLAttributes } from 'react';
import { EdgeAlignment } from '../../Typings';
import Divider from './Divider';
import './DropdownMenu.css';

export interface IDropdownMenuProps extends HTMLAttributes<HTMLDivElement> {
  root: JSX.Element;
  items: Array<string>;
  selectedIndex?: number;
  containerEdgeAlignment?: EdgeAlignment;
  handleItemSelect: (text: string, index: number) => void;
};

const DropdownMenu = ({ root, items, selectedIndex, handleItemSelect, containerEdgeAlignment = EdgeAlignment.Right, ...props}: IDropdownMenuProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [ showMenu, setShowMenu ] = useState(false);
  const [ selectedInternalIndex, setSelectedInternalIndex ] = useState<number | undefined>(selectedIndex);

  const handleWindowClick = useCallback((e: MouseEvent) => {
    if (showMenu) {
      setShowMenu(false);
      const ref = contentRef.current;
      if (ref) {
        ref.classList.remove('show');
      }
    }
  }, [showMenu]);

  const handleWindowKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      showMenu && setShowMenu(false);
      const ref = contentRef.current;
      if (ref) {
        ref.classList.remove('show');
      }
    }
  }, [showMenu]);

  const handleRootComponentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const ref = contentRef.current;
    if (ref) {
      ref.classList.toggle('show');
    }
    setShowMenu(!showMenu);
    e.stopPropagation();
  };

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const indexStr = e.currentTarget.getAttribute('data-index');

    if (indexStr !== null) {
      const index = +indexStr;
      setSelectedInternalIndex(index);
      handleItemSelect(items[index], index);
    }
  };

  const getClass = () => `dropdown-menu${props.className ? ` ${props.className}` : ''}`;

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => { // Cleanup
      window.removeEventListener('click', handleWindowClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, [handleWindowClick, handleWindowKeyDown]);

  const getDropdownMenuItemClassName = (index: number) => 
    `dropdown-menu-item${(selectedIndex !== undefined && selectedInternalIndex === index) ? ' selected' : ''}`;

  const getDropdownMenuContentClassName = () => `dropdown-menu-content${containerEdgeAlignment === EdgeAlignment.Left ? ' align-left-edge' : ''}`;
  
  return (
    <div className={getClass()} {...props}>
      {
        React.cloneElement(
          root,
          {
            className: `dropdown-menu-component${showMenu ? ' show-menu' : ''}${root.props.className ? ` ${root.props.className}` : ''}`,
            onClick: handleRootComponentClick
          }
        )
      }
      <div ref={contentRef} className={getDropdownMenuContentClassName()}>
        {
          items.map((item, index) => {
            if (item === '-')
              return <Divider key={index} />;
              
            return (
              <div key={index} data-index={index} className={getDropdownMenuItemClassName(index)} onClick={handleItemClick}>
                <div className='dropdown-menu-item-check'><span>&#10003;</span></div>
                <div className='dropdown-menu-item-text'><span>{item}</span></div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default DropdownMenu;