import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FastField } from 'formik';
import { ApolloError, useMutation } from '@apollo/client';
import { getErrorMessage, Mutations } from '../../util/Graphql';
import { UNAUTHENTICATED } from '../../Typings';
import { useAuth, UserInfo } from '../../context/AuthContext';
import Button from '../common/Button';
import Input from '../common/Input';
import Loader from '../transition/Loader';
import TruckerFyLogo from '../common/TruckerFyLogo';
import Upload, { UploadType } from '../common/Upload';
import ReactGA from 'react-ga4';
import '../../App.css';
import './EditProfile.css';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  // email: Yup.string()
  //   .email('Invalid email')
  //   .required('Email is required'),
  // password: Yup.string().required('Password is required')
});

type EditProfileProps = {
  handleEditProfileComplete?: () => void;
  redirectToLogin: () => void;
};

const EditProfile = ({ handleEditProfileComplete = () => {}, redirectToLogin = () => {}}: EditProfileProps) => {
  // eslint-disable-next-line
  const [updateCarrierUserFunction, { data, loading, error }] = useMutation(Mutations.UPDATE_CARRIER_USER);
  const { getUserInfo, setUserInfo } = useAuth();
  //const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect(() => {
    ReactGA.event({
      category: 'meta',
      action: 'show_dialog',
      label: 'edit_profile', // id unique to current page load
      value: 1, // values must be integers
      nonInteraction: true, // avoids affecting bounce rate
      transport: 'xhr'
    });
  }, []);

  const updateUserInfo = (firstName: string, lastName: string, photoUrl: string) => {
    if (getUserInfo()) {
      const newUserInfo = { ...getUserInfo(), firstName: firstName, lastName: lastName, photoUrl: photoUrl } as UserInfo;
      setUserInfo(newUserInfo);
    }
  }

  const handleSubmit = (values: any) => {
    document.body.style.cursor = 'wait';
    updateCarrierUserFunction({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        // email: values.email,
        // password: values.password,
        photoUrl: values.photoUrl
      }
    })
    .then((res: any) => {
      setErrorMessage('');

      ReactGA.event({
        category: 'truckerfy_meta',
        action: 'edit_profile_dialog',
        label: 'save_changes', // id unique to current page load
        value: 1, // values must be integers
        nonInteraction: true, // avoids affecting bounce rate
        transport: 'xhr'
      });
  
      //setSuccessMessage('Changes saved!');
      updateUserInfo(res.data.updateCarrierUser.firstName, res.data.updateCarrierUser.lastName, res.data.updateCarrierUser.photoUrl);
      handleEditProfileComplete();
      //setTimeout(handleEditProfileComplete, 1500);
    })
    .catch((e: ApolloError) => {
      const errorMsg = getErrorMessage(e);
      if (errorMsg === UNAUTHENTICATED) {
        redirectToLogin();
      } else if (errorMsg.indexOf('already exists') > 0) {
        setErrorMessage('Email is already registered');
      } else {
        setErrorMessage(errorMsg);
      }
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  return (
    <section className='modal-edit-profile'>
      { loading && <Loader /> }
      <div className='edit-profile-left' style={loading ? {opacity: '.4'} : {}}>
        <TruckerFyLogo className='edit-profile edit-profile-truckerfy-logo' isNotLink={true} />
      </div>
      <Formik
        initialValues={{
          firstName: getUserInfo()?.firstName,
          lastName: getUserInfo()?.lastName,
          email: getUserInfo()?.email,
          // password: ''
          photoUrl: getUserInfo()?.photoUrl
        }}
        onSubmit={values => handleSubmit(values)}
        validationSchema={SignupSchema}
      >
        {() => (
          <Form style={loading ? {opacity: '0.4'} : {}}>
            <table>
              <thead>
                <tr><th>User Profile</th></tr>
              </thead>
              <tbody>
                <tr><td><span>First name</span></td></tr>
                <tr><td><div><FastField autoFocus component={Input} name='firstName' placeholder='First name' type='text' /></div></td></tr>
                <tr><td><span>Last name</span></td></tr>
                <tr><td><div><FastField component={Input} name='lastName' placeholder='Last name' type='text' /></div></td></tr>
                <tr><td><span>Email</span></td></tr>
                <tr><td><div><FastField component={Input} name='email' placeholder='Email address' type='email' disabled /></div></td></tr>
                {/* <tr><td><div><FastField component={Input} name='password' placeholder='Create password' type='password' /></div></td></tr> */}
                <tr><td><span>Avatar</span></td></tr>
                <tr><td><FastField component={Upload} name='photoUrl' type={UploadType.Avatar} /></td></tr>
                {/* { successMessage !== '' && <tr><td className='success-message'><span>{successMessage}</span></td></tr> } */}
                { errorMessage !== '' && <tr><td className='error-message'><span>{errorMessage}</span></td></tr> }
              </tbody>
            </table>
            <footer>
              <p>Learn how we process your data:<br /><a href='/privacy'>Privacy Policy</a>.</p>               
              <div><Button disabled={loading} type='submit'>SAVE</Button></div>
            </footer>
          </Form>
        )}
      </Formik>
    </section>
  );
};
  
export default EditProfile;