import React from 'react';

function UserManager() {
  return (
    <div>
        <span>UserManager</span>
    </div>
  );
}

export default UserManager;
