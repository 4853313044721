import './NoJobs.css';

import React from 'react';

export const NoJobs = () => (
  <div className='noJobs'>
    <p>
      It looks like you have not published any jobs yet!
      <br />
      To create one please click the <span>create job</span> button at the top
      right &#8599;
      <br />
      When you first create a job you can save it as a <i>draft</i>.<br />
      Then when it's ready to go you can <em>publish</em> it so that matching
      drivers can start applying!
    </p>
  </div>
);