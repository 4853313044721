import { ReactComponent as TrafficConeSvg } from '../../assets/TrafficCone.svg';
import { ReactComponent as TruckSideSvg } from '../../assets/TruckSide.svg';
import { useModal } from '../../context/ModalContext';
import Button, { ButtonTheme } from '../common/Button';
import './ConfirmText.css';

type ConfirmTextProps = {
  prompt: string,
  onOkButtonClick: () => void,
  onCancelButtonClick?: () => void,
};

const ConfirmText = ({ prompt, onOkButtonClick = () => {}, onCancelButtonClick = undefined }: ConfirmTextProps) => {
  const { destroy } = useModal();
  const handleCancelClick = () => (onCancelButtonClick ? onCancelButtonClick() : destroy());

  return (
    <section className='modal-confirm-text'>
      <table>
        <thead>
          <tr><th><div className='modal-edit-confirm-text-header'><TrafficConeSvg /><TruckSideSvg /><TrafficConeSvg /></div></th></tr>
        </thead>
        <tbody>
          <tr><td><span className='off-black fw-5 fs-18 ls-02'>{prompt}</span></td></tr>
        </tbody>
        <tfoot>
          <tr><td>
            <div className='modal-edit-confirm-text-footer'>
              <Button type="submit" onClick={onOkButtonClick}>OK</Button>
              <Button type="button" theme={ButtonTheme.White} style={{ marginLeft: '20px' }} onClick={handleCancelClick}>Cancel</Button>
            </div>
          </td></tr>
        </tfoot>
      </table>
    </section>
  );
}

export default ConfirmText;