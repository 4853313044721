import { /* useState, */ useEffect, useCallback, /* , MouseEvent */ } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useModal } from '../../context/ModalContext';
//import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { CheckYourEmailMode } from './CheckYourEmail';
import LoginDialog, { LoginMode } from '../dialogs/Login';
// import Button, { ButtonTheme } from '../common/Button';
// import Navbar, { NavLogo, NavStart, NavEnd } from '../common/Navbar';
// import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
// import NavbarLayout from '../layouts/NavbarLayout';
// import TruckerOnTruckStepJpg from '../../assets/TruckerOnTruckStep.jpg';
// import { ReactComponent as AppStoreBadgeSvg } from '../../assets/AppStoreBadge.svg';
// import GoogePlayBadgePng from '../../assets/GooglePlayBadge.png';
import './Login.css';

const Login = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { compose, destroy } = useModal();
  const { login } = useAuth();
  // const [ showZenDesk, setShowZenDesk ] = useState(false);
  //const { /* zenDeskVisible, toggleZenDesk, */ referrer } = useTruckerFyApp();
  // const { hash } = useLocation();

  const handleLogin = useCallback((payload: any, persist: boolean) => {
    destroy();
    const expiresAt = new Date(payload.tokens.expiresAt);
    login({
      accessToken: payload.tokens.accessToken,
      refreshToken: payload.tokens.refreshToken,
      expiresAt: expiresAt,
      userInfo: {
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        photoUrl: payload.photoUrl,
        carrierId: payload.carrierRoles.length > 0 ? payload.carrierRoles[0].carrier.id : null,
        roles: payload.carrierRoles.map((carrierRole: any) => carrierRole.role)
      }
    }, persist);
    // Note - Redirection to the referrer or dashboard occurs via UnprotectedRoutes.tsx
  }, [destroy, login]);

  const handleForgotPassword = useCallback((email: string) => {
    destroy();
    history.push('/checkyouremail', { mode: CheckYourEmailMode.ResetPassword, email: email });
  }, [destroy, history]);

  const handleCloseButtonClick = useCallback(() => {
    destroy();

    history.push('/');
  }, [destroy, history]);

  const composeLogin = useCallback(() => {
    const isAuthExpired = location.state?.isAuthExpired;
    if (isAuthExpired) {
      delete location.state.isAuthExpired;
    }
    compose(<LoginDialog mode={isAuthExpired ? LoginMode.LoginExpired : LoginMode.Login} handleLogin={ handleLogin } handleForgotPassword={ handleForgotPassword } isModalSubscriber={true} preserveOnClose={true} handleCloseButtonClick={handleCloseButtonClick} />);
  }, [location.state?.isAuthExpired, handleLogin, handleForgotPassword, handleCloseButtonClick]);

  const composeResetPassword = useCallback(() => {
    if (location.state?.resetPassword) {
      delete location.state.resetPassword;
      compose(<LoginDialog mode={LoginMode.ResetPassword} extraParams={{ email: location.state?.email, token: location.state?.token }} handleLogin={ handleLogin } handleForgotPassword={ handleForgotPassword } isModalSubscriber={true} preserveOnClose={true} handleCloseButtonClick={handleCloseButtonClick} />);
      delete location.state.email;
      delete location.state.token;
    }
  }, [location.state?.resetPassword, location.state?.email, location.state?.token, handleCloseButtonClick]); // eslint-disable-line

  // useEffect(() => {
  //   const prevTitle = document.title;
  //   document.title = 'TruckerFy - Home';

  //   return () => { document.title = prevTitle; };
  // }, []);

  // useEffect(() => {
  //   if (showZenDesk && !zenDeskVisible) {
  //     toggleZenDesk();
  //     setShowZenDesk(false);
  //   }
  // }, [showZenDesk, zenDeskVisible]); // eslint-disable-line

  // useEffect(() => {
  //   if (hash && hash.toLowerCase() === '#contactus') {  
  //     toggleZenDesk();
  //   }
  // }, [hash]); // eslint-disable-line

  useEffect(() => composeLogin(), [composeLogin]);

  useEffect(() => composeResetPassword(), [composeResetPassword]);

  return (
    <></>
  );
};

export default Login;