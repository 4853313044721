import React, { createContext, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

type TruckerFyAppService = {
  toggleZenDesk: () => void;
  zenDeskVisible: boolean;
  referrer: string | undefined;
  clearReferrer(): void;
  appVisible: boolean;
};

const TruckerFyAppContext = createContext<TruckerFyAppService>({} as TruckerFyAppService);

const TruckerFyAppProvider = ({ children }: { children: React.ReactNode }) => {
  const [ initializationComplete, setInitializationComplete ] = useState(false);
  const [ zenDeskShowing, setZenDeskShowing ] = useState(false);
  const [ referrer, setReferrer ] = useState(() => {
    const pathname = window.location.pathname;
    if (pathname.length > 1) // Always starts with a forward slash
      return pathname;
  });
  const [appVisible, setAppVisible] = useState(true);

  const onVisibilityChange = () => {
    setAppVisible(document.visibilityState === 'visible');
  };

  useEffect(() => {
    const win = window as any;
    win.zE('webWidget:on', 'close', () => {
      (window as any).zE('webWidget', 'hide');
      setZenDeskShowing(false);
    });
    setInitializationComplete(true);
    document.addEventListener('visibilitychange', onVisibilityChange, false)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, []);

  const toggleZenDesk = () => {
    const win = window as any;
    win.zE('webWidget', `${zenDeskShowing ? 'hide' : 'show'}`);
    if (!zenDeskShowing)
      win.zE('webWidget', 'open');

      ReactGA.event({
        category: 'meta',
        action: 'show_zendesk',
        label: `${zenDeskShowing ? 'show' : 'hide'}`, // id unique to current page load
        value: 1, // values must be integers
        nonInteraction: true, // avoids affecting bounce rate
        transport: 'xhr'
      });

    setZenDeskShowing(previousValue => !previousValue);
  };

  const clearReferrer = () => setReferrer(undefined);

  return (
    <TruckerFyAppContext.Provider
      value={{
        toggleZenDesk,
        zenDeskVisible: zenDeskShowing,
        referrer,
        clearReferrer,
        appVisible
      }}
    >
      { initializationComplete && children }
    </TruckerFyAppContext.Provider>
  );
};

const useTruckerFyApp = () => {
  const context = useContext(TruckerFyAppContext);
  if (!context) {
    throw new Error('useTruckerFyApp() must be used within a TruckerFyAppProvider');
  }

  return context;
}

export { TruckerFyAppProvider, useTruckerFyApp };
