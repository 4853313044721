import { ButtonHTMLAttributes, ReactNode } from 'react';
import Button, { ButtonTheme } from './Button';
import './StateButton.css';

export interface StateButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  children?: ReactNode;
};

const StateButton = ({selected, children, ...props }: StateButtonProps) => {
  const getClassName = () => `state-btn${props.className ? ` ${props.className}` : ''}${selected ? ' state-btn-selected' : ''}`;

  return (
    <Button theme={ButtonTheme.White} type='button' {...props} className={getClassName()}>{children}</Button>
  );
};

export default StateButton;