import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { getErrorMessage, Queries } from '../../util/Graphql';
import { isUuid } from '../../util/ObjectUtil';
import { ReactComponent as AppStoreBadgeSvg } from '../../assets/AppStoreBadge.svg';
import { IJobCandidate } from '../../Typings';
import DriverProfile from '../specialized/dashboard/DriverProfile';
import Navbar, { NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import NavbarLayout from '../layouts/NavbarLayout';
import Loader from '../transition/Loader';
import GoogePlayBadgePng from '../../assets/GooglePlayBadge.png';
import './Driver.css';

const Driver = () => {
  const history = useHistory();
  const { driverId } = useParams<{ driverId: string }>();
  const [ driver, setDriver ] = useState<IJobCandidate>();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - View Driver';

    return () => { document.title = prevTitle; };
  }, []);

  const handleDriverDataFetch = (data: any) => {
    if (data?.getDriverAnon) {
      setDriver({ driver: data.getDriverAnon, matchScore: undefined });
    } else {
      history.push('/notfound');
    }
  };

  const [getDriverFunction, { loading: driverLoading }] = useLazyQuery(
    Queries.GET_DRIVER_ANON, 
    { 
      fetchPolicy: 'no-cache', 
      variables: { id: driverId },
      onError(e) {
        const errorMsg = getErrorMessage(e);
        history.push('/oops', { error: errorMsg });
      },
      onCompleted(data) {
        handleDriverDataFetch(data); 
      } 
    }
  );

  useEffect(() => {
    if (isUuid(driverId))
      getDriverFunction();
    else
      history.push('/notfound');
  }, [driverId, getDriverFunction, history]);

  return (
    <NavbarLayout>
      <header className='driver-page-header'>
        <Navbar>
          <NavLogo><TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} linkAddress='/' /></NavLogo>
        </Navbar>
      </header>
      <main className='driver-page-main'>
        { driverLoading && <Loader />}
        {
          driver &&
            <div className='driver-page-container'>
              <div className='driver-page-content'>
                <DriverProfile model={driver} />
              </div>
              {/* <p className='off-black fs-19 fw-5'>Apply to this driver and many others on the TruckerFy app!</p> */}
              <div className='driver-page-badge-container'>
                <AppStoreBadgeSvg title='' onClick={() => (window.location.href = process.env.REACT_APP_APPLE_STORE_URL ?? '/')} />
                <img src={GoogePlayBadgePng} alt='Google Play' onClick={() => (window.location.href = process.env.REACT_APP_GOOGLE_PLAY_URL ?? '/')} />
              </div>
            </div>
        }
      </main>
    </NavbarLayout>
  );
};

export default Driver;