import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const Recover = () => {
  const { email, token } = useParams<{ email: string, token: string }>(); 
  const emailResolved = decodeURIComponent(email);
  const tokenResolved = decodeURIComponent(token);

  return (
    <Redirect push to={{
      pathname: '/login',
      state: { resetPassword: true, email: emailResolved, token: tokenResolved }
    }} /> 
  );
}

export default Recover;