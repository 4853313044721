import { HTMLAttributes } from 'react';
import { MatchScore } from '../../../Typings';
import RibbonGreatMatch from '../../../assets/RibbonGreatMatch.png';
import RibbonGoodMatch from '../../../assets/RibbonGoodMatch.png';
import RibbonWeakMatch from '../../../assets/RibbonWeakMatch.png';
import './MatchRibbon.css';

export interface IMatchRibbonProps extends HTMLAttributes<HTMLSpanElement> {
  matchScore: MatchScore;
};

const MatchRibbon = ({ matchScore, ...props }: IMatchRibbonProps) => {
  return (
    <div className='match-ribbon-div' {...props}>
      {matchScore?.color === 'success' && <img src={RibbonGreatMatch} alt='Great Match' />}
      {matchScore?.color === 'info' && <img src={RibbonGoodMatch} alt='Good Match' />}
      {matchScore?.color === 'error' && <img src={RibbonWeakMatch} alt='Weak Match' />}
    </div>
  );
};

export default MatchRibbon;