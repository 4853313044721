import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../context/JobsContext';
import Divider from '../../common/Divider';
import './JobsTiles.css';

export type JobsTilesProps = {
  data: Array<any>;
  jobDateLabel: string;
  children?: never;
};

const JobsTiles = ({ data, jobDateLabel }: JobsTilesProps) => {
  const history = useHistory();
  const { jobs } = useJobs();
  const composeApplicant = (jobApplicantSummary: any) =>
    <div className='jobs-applicant fw-3'>
      <div><span>{`${jobApplicantSummary.new} new`}</span></div>
      <div><span>{`${jobApplicantSummary.reviewed} reviewed`}</span></div>
      <div><span>{`${jobApplicantSummary.rejected} rejected`}</span></div>
    </div>

  const handleTileClick = (jobId: string) => {
    const job = jobs.find(job => job.id === jobId);
    if (job)
      history.push(`/dashboard/job/${job.id}`);
  };

  const composeTiles = () =>
    data.map((datum, index) => 
      <div key={`jobs-title-${index}`} className='jobs-tile' onClick={() => handleTileClick(`${datum.id}`)}>
        <div>
          <p className='fw-5 fs-14'>{datum.title}</p>
          { datum.internalTitle ? <p>{datum.internalTitle}</p> : <p>&nbsp;</p> }
          <p>{`${jobDateLabel}: ${new Date(datum.jobDate).toLocaleDateString('en-US')}`}</p>
        </div>
        <Divider />
        {composeApplicant(datum.jobApplicantSummary)}
      </div>);

  return (
    <div className='jobs-tiles fs-12 ls-2'>
      {composeTiles()}
    </div>
  );
};

export default JobsTiles;