// simple function to display relative time
// see https://stackoverflow.com/a/58918815/2805154

const minute = 60 * 1000;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30;
const year = day * 365;

// relative time for display
export const timeAgo = (prevDate: Date) => {
  const previous = typeof prevDate === 'object' ? prevDate : new Date(prevDate);
  const diff = Number(new Date()) - previous.getTime();
  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000);
      return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`;
    case diff < hour:
      const minutes = Math.round(diff / minute);
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
    case diff < day:
      const hours = Math.round(diff / hour);
      return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
    case diff < week:
      const days = Math.round(diff / day);
      return `${days} ${days > 1 ? 'days' : 'day'} ago`;
    case diff < month:
      const weeks = Math.round(diff / week);
      return `${weeks} ${weeks > 1 ? 'weeks' : 'week'} ago`;
    case diff < year:
      const months = Math.round(diff / month);
      return `${months} ${months > 1 ? 'months' : 'month'} ago`;
    case diff > year:
      const years = Math.round(diff / year);
      return `${years} ${years > 1 ? 'years' : 'year'} ago`;
    default:
      return '';
  }
};

// return a localeDate if the date is more than a day old otherwise null
export const dateIfOld = (prevDate: Date) => {
  const previous = typeof prevDate === 'object' ? prevDate : new Date(prevDate);
  const diff = Number(new Date()) - previous.getTime();
  return diff > day ? prevDate.toLocaleDateString() : null;
};
