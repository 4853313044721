import React, { useState, KeyboardEvent, InputHTMLAttributes, CSSProperties, MouseEvent } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import FormFieldMessage, { FormFieldMessageLevel } from './FormFieldMessage';
import HidePasswordSvg from '../../assets/HidePassword.svg';
import InformationSvg from '../../assets/Information.svg';
import SearchSvg from '../../assets/Search.svg';
import ShowPasswordSvg from '../../assets/ShowPassword.svg';
import ValidationErrorSvg from '../../assets/ValidationError.svg';
import './Input.css';

export enum Glyph {
  Clear,
  Password,
  Search,
  Validation
};

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  digitsOnly?: boolean; // Example is text with leading zeros
  monetary?: boolean;
  searchable?: boolean;
  onSearch?: (value: string) => void;
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  info?: string;
  suppressValidation?: boolean;
};

const Input = ({ 
  digitsOnly = false,
  monetary = false,
  searchable = false,
  onSearch = v => {},
  field, form: { touched, errors, setFieldValue },
  info,
  suppressValidation = false,
  ...props
}: IInputProps) => {

  const [ currentType, setCurrentType ] = useState(props.type);
  const [ showingPassword, setShowingPassword ] = useState(false);
  const [ isPasswordType ] = useState(currentType === 'password');
  
  const handlePasswordMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    setShowingPassword(!showingPassword);
    if (showingPassword)
      setCurrentType('password');
    else
      setCurrentType('text');
    e.preventDefault();
  };

  const handleClearMouseDown = (e: MouseEvent<HTMLImageElement>) => {  
    setFieldValue(field.name, '');
    e.preventDefault();
  };

  const handleSearchMouseDown = (e: MouseEvent<HTMLImageElement>) => {
    onSearch(field.value);
    e.preventDefault();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (searchable && e.key === 'Enter' && field.value.length) {
      onSearch(field.value);
      e.preventDefault();
    }
  };

  const isInvalid = () => !suppressValidation && touched[field.name] && errors[field.name];
  const isInformative = () => !!info;
  const getRightPadding = () => {
    let padding = 15;
    if (field.value) padding += 19;
    if (isInvalid()) padding += 24;
    if (isPasswordType) padding += 24;
    return `${padding}px`;
  };

  const getRightPosition = (glyph: Glyph) => {
    let rightPos = 7;
    const isErrorOrInfo = isInvalid() || isInformative();

    if (glyph === Glyph.Search) {
      rightPos = 29;
      if (!isPasswordType && isErrorOrInfo) rightPos = 54;
      if (isPasswordType && !isErrorOrInfo) rightPos = 54;
      if (isPasswordType && isErrorOrInfo) rightPos = 82;
    } else if (glyph === Glyph.Clear) {
      if (!isPasswordType && isErrorOrInfo) rightPos = 32;
      if (isPasswordType && !isErrorOrInfo) rightPos = 32;
      if (isPasswordType && isErrorOrInfo) rightPos = 60;
    } else { // Password
      rightPos = isErrorOrInfo ? 34 : 6;
    }
    return `${rightPos}px`;
  };

  const getClassName = () => `custom-input${props.className ? ` ${props.className}` : ''}${monetary ? ' monetary' : ''} fw-3`;

  const getStyle = (style: CSSProperties | undefined) => ({
    ...style,
    ...props.style
  });

  const handleInput = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1'); // Digits-only

  const composeErrorInfoGlyph = () => {
    if (isInvalid())
      return (
        <img
          width='20px'
          className='custom-input-validation'
          title='Please fix the error displayed beneath the field'
          src={ValidationErrorSvg}
          alt='Validation error'
        />
      );
       
    if (isInformative()) {
      return (
        <img
          width='20px'
          className='custom-input-info'
          title='Please read the message displayed beneath the field'
          src={InformationSvg}
          alt='Informational message'
        />
      );
    }
  };

  const composeErrorInfoMessage = () => {
    if (isInvalid())
      return (<FormFieldMessage text={errors[field.name]} />);
    if (isInformative())
      return (<FormFieldMessage text={info} level={FormFieldMessageLevel.Info} />);
  };

  return (
    <div className='custom-input-container'>
      <div>
        <input
          maxLength={100}
          {...field}
          {...props}
          type={currentType}
          className={getClassName()}     
          style={getStyle({ paddingRight: getRightPadding() })}
          onInput={digitsOnly ? handleInput : undefined}
          onKeyDown={handleKeyDown}
        />
        { (field.value && monetary) && <i tabIndex={-1} className='custom-input-monetary'>$</i> }
        {
          searchable &&
          field.value &&
            <img
              width='17px'
              className='custom-input-search'
              title='Search database'
              style={{ right:getRightPosition(Glyph.Search) }}
              src={SearchSvg}
              onMouseDown={handleSearchMouseDown}
              alt='Search database'
            />
        }
        {
          field.value &&
            <i
              tabIndex={-1}
              className='custom-input-clear'
              title='Clear field'
              style={{ right: getRightPosition(Glyph.Clear), display: props.disabled ? 'none' : undefined }}
              onMouseDown={handleClearMouseDown}
            >&times;
            </i>
        }
        { 
          props.type === 'password' &&
            <img
              width='20px'
              className='custom-input-pwd'
              title={`${showingPassword ? 'Hide': 'Show'} password`}
              style={{ right: getRightPosition(Glyph.Password) }}       
              src={showingPassword ? HidePasswordSvg : ShowPasswordSvg}
              onMouseDown={handlePasswordMouseDown}
              alt='Toggle password'
            />
        }
        {composeErrorInfoGlyph()}
      </div>
      {composeErrorInfoMessage()}
    </div>
  );
};

export default Input;