import { ReactComponent as JobsSvg } from '../../assets/Job.svg'
import { ReactComponent as CandidatesSvg } from '../../assets/Candidate.svg'
import { ReactComponent as ConversationsSvg } from '../../assets/Conversation.svg'
import { Area, JobModel, JobStatus, MatchScore } from '../../Typings';

const getJobStatusColor = (status: JobStatus) => {
  switch (status) {
    case JobStatus.Archived: return 'var(--sunset-orange)';
    case JobStatus.Draft: return 'var(--colorado-blue)';
    case JobStatus.Published: return 'var(--forest-green)';
    default: return 'var(--off-black)';
  };
};

const getMatchColor = (matchScore: MatchScore) => {
  switch (matchScore.color) {
    case 'success': return 'var(--app-green)';
    case 'info': return 'var(--sunset-orange)';
    case 'error': return 'var(--crimson-red)';
    default: return 'var(--off-black)';
  }
};

const getDashbarLogoContent = (area: Area, job?: JobModel) => {
  const getStyle = () => {
    if (job) return { backgroundColor: getJobStatusColor(job.publicationStatus) };
  };

  const getPreElementClassName = () => `${job ? 'job' : 'area'}-preElement`

  const getPreElement = () => {
    if (job) return <div className="job-status" style={getStyle()} />

    switch (area) {
      case Area.Jobs: return <JobsSvg />
      case Area.Candidates: return <CandidatesSvg />
      case Area.Conversations: return <ConversationsSvg />
    }
  };

  const getPostElement = () => {
    if (job) {
      const statusColor = getJobStatusColor(job.publicationStatus);
      return <div className='job-status-post-element' style={{ color: statusColor }}>
        <div style={{backgroundColor: statusColor }} />
        <span className='f-sfd fw-5 fs-12 ls-4'>{job.publicationStatus.toLowerCase()}</span>
      </div>
    }
  };

  const getTitle = () => {
    if (job) return `${(job.internalTitle ? `${job.internalTitle} / `: '')}${job.title}`;
    return Area[area].toString();
  };

  return (
    <>
      <div className={getPreElementClassName()}>
        {getPreElement()}
      </div>
      <span className="fw-b fs-18 ls-2">{getTitle()}</span>
      {getPostElement()}
    </>
  );
};

const getJobModels = (carrierJobsData: any) : Array<JobModel> => {
  const jobs = carrierJobsData.getCarrierJobs.map((job: any) => 
    new JobModel(
      job.id,
      new Date(job.createdAt),
      job.title,
      job.internalTitle,
      job.internalReference,
      job.description,
      {
        new: job.jobInterests.filter((ji: any) => ji.status === 'APPLICANT').length, 
        reviewed: job.jobInterests.filter((ji: any) => !(['APPLICANT', 'REJECTED'].includes(ji.status))).length, 
        rejected: job.jobInterests.filter((ji: any) => ji.status === 'REJECTED').length 
      },
      job.publicationStatus as JobStatus,
      job.publishedAt ? new Date(job.publishedAt) : undefined,
      job.requirements,
      job.favoritesByDate.map((fbd: any) => ({ date: new Date(fbd.date.replace('-', '/')), count: fbd.count })),
      job.viewsByDate.map((vbd: any) => ({ date: new Date(vbd.date.replace('-', '/')), anonymousViews: vbd.anonymousViews, knownViews: vbd.knownViews })),
      job.numCandidates,
      job.numUnreadMessagesForCarrier,
      job.numFavorites,
      job.jobInterests,
      job.lastUpdatedAt,
    ));

  return jobs;
};

export { getJobStatusColor, getMatchColor, getDashbarLogoContent, getJobModels };