const areDatesOnSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const getFormattedDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'p' : 'a';
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 hours)
    
    return `${day}-${month} ${hours}:${minutes}${period}`;
};

const compareDatesFromStrings = (dateStr1: string, dateStr2: string) => {
    if (!dateStr1 && !dateStr2) return 0;
    if ((!dateStr1 && dateStr2) || (new Date(dateStr1) < new Date(dateStr2))) return -1;
    if ((dateStr1 && !dateStr2) || (new Date(dateStr1) > new Date(dateStr2))) return 1;
    return 0;
};

export { areDatesOnSameDay, getFormattedDate, compareDatesFromStrings };