import { ReactComponent as AppStoreBadgeSvg } from '../../assets/AppStoreBadge.svg';
import GoogePlayBadgePng from '../../assets/GooglePlayBadge.png';
import './AppStoreButtons.css';

import { getMobileOS } from '../common/getMobileOS';

export const AppStoreButtons = () => {
  const OS = getMobileOS();
  switch (OS) {
    case 'Android':
      return (
        <div className='appStoreButtons'>
          <img src={GoogePlayBadgePng} alt='Google Play' onClick={() => (window.location.href = process.env.REACT_APP_GOOGLE_PLAY_URL ?? '/')} />
          <AppStoreBadgeSvg title='' onClick={() => (window.location.href = process.env.REACT_APP_APPLE_STORE_URL ?? '/')} />
        </div>
      );
    default:
      return (
        <div className='appStoreButtons'>
          <AppStoreBadgeSvg title='' onClick={() => (window.location.href = process.env.REACT_APP_APPLE_STORE_URL ?? '/')} />
          <img src={GoogePlayBadgePng} alt='Google Play' onClick={() => (window.location.href = process.env.REACT_APP_GOOGLE_PLAY_URL ?? '/')} />
        </div>
      );
  }
};
