import { ReactNode, SelectHTMLAttributes } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import Select from './Select';

interface PostalStateSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  children?: ReactNode;
};

const PostalStateSelect = ({ field, form, ...props }: PostalStateSelectProps) => (
  <Select field={field} form={form} {...props} placeholder='State' required>
    <option key='0' value='' hidden>State</option>
    <option key='1' value='AL'>Alabama</option>
    <option key='2' value='AK'>Alaska</option>
    <option key='3' value='AZ'>Arizona</option>
    <option key='4' value='AR'>Arkansas</option>
    <option key='5' value='CA'>California</option>
    <option key='6' value='CO'>Colorado</option>
    <option key='7' value='CT'>Connecticut</option>
    <option key='8' value='DE'>Delaware</option>
    <option key='9' value='DC'>District Of Columbia</option>
    <option key='10' value='FL'>Florida</option>
    <option key='11' value='GA'>Georgia</option>
    <option key='12' value='HI'>Hawaii</option>
    <option key='13' value='ID'>Idaho</option>
    <option key='14' value='IL'>Illinois</option>
    <option key='15' value='IN'>Indiana</option>
    <option key='16' value='IA'>Iowa</option>
    <option key='17' value='KS'>Kansas</option>
    <option key='18' value='KY'>Kentucky</option>
    <option key='19' value='LA'>Louisiana</option>
    <option key='20' value='ME'>Maine</option>
    <option key='21' value='MD'>Maryland</option>
    <option key='22' value='MA'>Massachusetts</option>
    <option key='23' value='MI'>Michigan</option>
    <option key='24' value='MN'>Minnesota</option>
    <option key='25' value='MS'>Mississippi</option>
    <option key='26' value='MO'>Missouri</option>
    <option key='27' value='MT'>Montana</option>
    <option key='28' value='NE'>Nebraska</option>
    <option key='29' value='NV'>Nevada</option>
    <option key='30' value='NH'>New Hampshire</option>
    <option key='31' value='NJ'>New Jersey</option>
    <option key='32' value='NM'>New Mexico</option>
    <option key='33' value='NY'>New York</option>
    <option key='34' value='NC'>North Carolina</option>
    <option key='35' value='ND'>North Dakota</option>
    <option key='36' value='OH'>Ohio</option>
    <option key='37' value='OK'>Oklahoma</option>
    <option key='38' value='OR'>Oregon</option>
    <option key='39' value='PA'>Pennsylvania</option>
    <option key='40' value='RI'>Rhode Island</option>
    <option key='41' value='SC'>South Carolina</option>
    <option key='42' value='SD'>South Dakota</option>
    <option key='43' value='TN'>Tennessee</option>
    <option key='44' value='TX'>Texas</option>
    <option key='45' value='UT'>Utah</option>
    <option key='46' value='VT'>Vermont</option>
    <option key='47' value='VA'>Virginia</option>
    <option key='48' value='WA'>Washington</option>
    <option key='49' value='WV'>West Virginia</option>
    <option key='50' value='WI'>Wisconsin</option>
    <option key='51' value='WY'>Wyoming</option>
  </Select>
);

export default PostalStateSelect;