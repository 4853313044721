import React, { useRef, useState, useEffect, CSSProperties, InputHTMLAttributes } from 'react';
import './TextBox.css';

export interface ITextBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  toggleClear?: boolean; 
  onTextChange?: (newValue: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const TextBox = ({ toggleClear, onTextChange = () => {}, onKeyUp = () => {}, ...props }: ITextBoxProps) => {
  const [ toggleClearInternal, setToggleClearInternal ] = useState(toggleClear);
  const [ hasValue, setHasValue ] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const getClassName = () => `textbox${props.className ? ` ${props.className}` : ''}`;

  const getStyle = (style: CSSProperties | undefined) => ({
    ...style,
    ...props.style
  });

  const clearTextBox = () => {
    if (inputRef.current) {
      // @ts-ignore
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(inputRef.current, '');
        const changeEvent = new Event('change', { bubbles: true });
        inputRef.current.dispatchEvent(changeEvent);
      }
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    setHasValue(target.value.trim().length > 0);
    onTextChange(target.value);
  };

  const handleClearButtonMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault(); // Prevents blur
  };

  const handleClearButtonClick = (e: React.MouseEvent<HTMLImageElement>) => {
    setHasValue(false);
    clearTextBox();
  };

  useEffect(() => {
    if (toggleClear !== toggleClearInternal) {
      setToggleClearInternal(prevValue => !prevValue);
      clearTextBox();
    }
  }, [toggleClear, toggleClearInternal]);

  return (
    <div className={getClassName()} style={getStyle({ position:'relative' })}>
      <input
        ref={inputRef}
        maxLength={10000}
        type='text'
        placeholder='Message'
        onChange={handleChange}
        onKeyUp={onKeyUp}
        {...props}        
      />
      { hasValue && <i tabIndex={-1} className='textbox-clear' title='Clear field' onMouseDown={handleClearButtonMouseDown} onClick={handleClearButtonClick}>&times;</i> }
    </div>
  );
};

export default TextBox;