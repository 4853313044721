import { ButtonHTMLAttributes, MouseEvent, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import './Button.css';

export enum ButtonTheme {
  Green,
  White
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonTheme;
  children?: ReactNode;
};

const Button = ({ theme = ButtonTheme.Green, children, className, ...props }: ButtonProps) => {
  const [ toggleRipple, setToggleRipple ] = useState(false);
  const spanRef = useRef<HTMLSpanElement>(null);

  const createRipple = (e: MouseEvent) => {
    const button = e.currentTarget;
    const span = spanRef.current;
    if (button && span) {      
      const rect = button.getBoundingClientRect();    
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;

      span.style.width = span.style.height = `${diameter}px`;
      span.style.left = `${e.clientX - rect.left - radius}px`;
      span.style.top = `${e.clientY - rect.top - radius}px`;

      const themeClass = `ripple-${theme === ButtonTheme.White ? 'wht' : 'grn'}`;
      span.classList.add(themeClass);
      setToggleRipple(!toggleRipple);   
    }
  };

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      const span = spanRef.current;
      if (span) {
        const className = `ripple-${theme === ButtonTheme.White ? 'wht' : 'grn'}`;
        span.classList.remove(className);
      }
    }, 600);
    return () => clearTimeout(timer);
  });

  const getClass = () => `custom-btn-${theme === ButtonTheme.White ? 'wht' : 'grn'}${className ? ` ${className}` : ''}`;

  return (
    <button type='button' {...props} className={getClass()} onMouseDown={createRipple}>
      <i ref={spanRef}></i>
      {children}
    </button>
  );
};

export default Button;