import { useState, HTMLAttributes, MouseEvent, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../context/JobsContext';
import { JobStatus, JobModel, SortDirection, JobsSort } from '../../../Typings';
import { ReactComponent as List } from '../../../assets/List.svg';
import { ReactComponent as Tile } from '../../../assets/Tile.svg';
import { PreferenceType, usePref } from '../../../context/PreferenceContext';
import Button, { ButtonTheme } from '../../common/Button';
import Grid, { ColumnType } from '../../common/Grid';
import Navbar, { NavEnd, NavStart } from '../../common/Navbar';
import GridSort, { UserSort } from '../../common/GridSort';
import JobsTiles from './JobsTiles';
import Tabbar from '../../common/Tabbar';
import TabbarLayout from '../../layouts/TabbarLayout';
import { NoJobs } from './NoJobs';
import './JobsView.css';

interface IJobsViewProps extends HTMLAttributes<HTMLDivElement> {
  children?: never;
};

const JobsView = ({ ...props }: IJobsViewProps) => {
  const { carrier, jobs } = useJobs();
  const { getPreference, setPreference, deletePreference } = usePref();
  const [ activeFilterIndex, setActiveFilterIndex ] = useState(getPreference(PreferenceType.JobsFilterIndex));
  const [ activeTabIndexInternal, setActiveTabIndexInternal ] = useState(getPreference(PreferenceType.JobsTabIndex));
  const [ toggleClearGridSortColumn, setToggleClearGridSortColumn ] = useState(false);
  const [ gridData, setGridData ] = useState<JobModel[]>([]);
  const [ activeSort, setActiveSort ] = useState<UserSort<JobsSort>[]>(getPreference(PreferenceType.JobsPublishedSort));
  const history = useHistory();

  const getSortPreferenceType = useCallback(() => {
    const preference = (activeTabIndexInternal === 0)
      ? PreferenceType.JobsDraftSort 
      : (activeTabIndexInternal === 1)
        ? PreferenceType.JobsPublishedSort
        : PreferenceType.JobsArchivedSort;
    return preference;
  }, [activeTabIndexInternal]);

  const applySort = useCallback(() => {
    const sorts = activeSort;
    const customCompare = (left: any, right: any) => {    
      let compareVal = 0;
  
      if (sorts) {
        const userSortDefChecked = sorts.filter(sort => sort.checked);
        for (let idx = 0; idx < userSortDefChecked.length; idx++) {
          const sortDef = userSortDefChecked[idx];
          const sortDir = sortDef.sort;
          switch(sortDef.type) {
            case JobsSort.JobDate:
              compareVal = new Date(left.jobDate).getTime() - new Date(right.jobDate).getTime();
              break;
            case JobsSort.PostingTitle:
              compareVal = left.title === right.title ? 0 : (left.title < right.title ? -1 : 1);
              break;
            case JobsSort.InternalTitle:
              compareVal = (left.internalTitle ?? '') === (right.internalTitle ?? '') ? 0 : ((left.internalTitle ?? '') < (right.internalTitle ?? '') ? -1 : 1);
              break;
          }
          if (compareVal !== 0) {
            return compareVal * (sortDir === 0 ? 1 : -1);
          }
        }
      }
  
      return compareVal;
    };

    setGridData(prevValue => {
      const newValue = [...prevValue];
      newValue.sort(customCompare);
      return newValue;
    });
  }, [activeSort]);

  const handleApplySorts = (sorts: Array<UserSort<JobsSort>>) => {
    setToggleClearGridSortColumn(previousVal => !previousVal);
    const preferenceType = getSortPreferenceType();
    if (sorts && sorts.length > 0)
      setPreference(preferenceType, sorts);
    else {
      deletePreference(preferenceType);
      setActiveSort(getPreference(getSortPreferenceType()) as UserSort<JobsSort>[]);
    }
  };

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Jobs';

    return () => { document.title = prevTitle; };
  }, []);

  useEffect(() => applySort(), [applySort]);

  useEffect(() => {
    const preferenceType = getSortPreferenceType();
    const sort = getPreference(preferenceType) as UserSort<JobsSort>[];
    setActiveSort(sort);
  }, [getPreference, getSortPreferenceType]);

  useEffect(() => {
    const tempJobs = jobs
      .filter(item => Object.values(JobStatus).indexOf(item.publicationStatus) === activeTabIndexInternal);
    setGridData(tempJobs);
    applySort();
  }, [jobs, activeTabIndexInternal, applySort]);

  const handleFilterClick = (e: MouseEvent<HTMLDivElement>) => {
    const indexStr = e.currentTarget.getAttribute('data-index');
    if (indexStr) {
      setPreference(PreferenceType.JobsFilterIndex, +indexStr);
      setActiveFilterIndex(+indexStr);
    }
  };

  const composeCaption = (title: string, count: number, jobStatus: JobStatus) => {
    const getStatusClassName = () => {
      let captionStatus: string;
      switch (jobStatus) {
        case JobStatus.Draft:
          captionStatus = 'caption-draft';
          break;
        case JobStatus.Published:
          captionStatus = 'caption-published';
          break;
        case JobStatus.Archived:
          captionStatus = 'caption-archived';
          break;
      }
      return `jobs-view-caption-status ${captionStatus}`;
    };

    return (
      <div className='jobs-view-caption'>
        <div className={getStatusClassName()} />
        <span>{title}</span>
        <div className='jobs-view-caption-count'>
          <span>{count}</span>
        </div>
      </div>
    );
  };

  const getTabsMeta = () => [
    { caption: composeCaption('Draft', jobs.filter(j => j.publicationStatus === JobStatus.Draft).length, JobStatus.Draft), activeClassName: 'tab-active-draft' },
    { caption: composeCaption('Published', jobs.filter(j => j.publicationStatus === JobStatus.Published).length, JobStatus.Published), activeClassName: 'tab-active-published' },
    { caption: composeCaption('Archived', jobs.filter(j => j.publicationStatus === JobStatus.Archived).length, JobStatus.Archived), activeClassName: 'tab-active-archived' }
  ];

  const Filter = ({ filterIndex }: { filterIndex: number }) => {
    const getFilterClass = (index: number) => `jobs-view-filter${index === filterIndex ? ' filter-active' : ''}`;

    return (
      <>
        <div key='jobs-view-filter-1' className={getFilterClass(0)} data-index={0} onClick={handleFilterClick} title='List view'><List /></div>
        <div key='jobs-view-filter-2' className={getFilterClass(1)} data-index={1} onClick={handleFilterClick} title='Tile view'><Tile /></div>       
      </>
    );
  };
 
  const composeFilter = () => <Filter filterIndex={activeFilterIndex} />;

  const handleActiveTabChange = (index: number) => {
    setPreference(PreferenceType.JobsTabIndex, index);
    setActiveTabIndexInternal(index);
  };

  const composeJobApplicantSummary = (item: JobModel) =>
    <div className='jobs-applicant fw-3'>
      <div><span>{`${item.jobApplicantSummary?.new ?? 0} new`}</span></div>
      <div><span>{`${item.jobApplicantSummary?.reviewed ?? 0} reviewed`}</span></div>
      <div><span>{`${item.jobApplicantSummary?.rejected ?? 0} rejected`}</span></div>
    </div>

  const dateColumnHeader = activeTabIndexInternal === 0 
    ? 'Created on' 
    : activeTabIndexInternal === 1 
      ? 'Published on' 
      : 'Archived on';

  const getColumns = () => {
    return new Map([
      [ 'id', { type: ColumnType.Key } ],
      [ 'jobDate', { caption: dateColumnHeader, type: ColumnType.Date } ],
      [ 'title', { caption: 'Posting title', type: ColumnType.Text } ],
      [ 'internalTitle', { caption: 'Internal title', type: ColumnType.Text } ],
      [ 'jobApplicantSummary', { caption: 'Applicants', type: ColumnType.Custom, noSort: true, renderer: composeJobApplicantSummary } ]
    ]);
  };

  const gridRowClickHandler = (key: any) => {
    const job = jobs.find(job => job.id === key);
    if (job)       
      history.push(`/dashboard/job/${job.id}`);
  };

  const getSortNames = () => [dateColumnHeader, 'Posting title', 'Internal title'];

  const composeToolbar = () => (
    <Navbar className='jobs-view-bar'>
      <NavStart>
        <GridSort<JobsSort> title='Sort Jobs' sortNames={getSortNames()} defaultSort={activeSort} handleApplySorts={handleApplySorts} />
      </NavStart>
      <NavEnd>
        <Button key='btn-create-job' theme={ButtonTheme.White} type='button' onClick={onCreateNewJob}>CREATE JOB</Button>
      </NavEnd>
    </Navbar>
  );

  const onCreateNewJob  = () => {
    if (carrier)
      history.push('/editjob', { carrierId: carrier.id });
  };

  const getContentClassName = () => `jobs-view-content${activeFilterIndex === 1 && jobs.length > 0 ? ' tile-view' : ''}`;

  const Jobs = () => (
    <>
      {activeFilterIndex === 0 ? (
        <Grid<JobModel>
          columns={getColumns()}
          data={gridData}
          gridRowClick={gridRowClickHandler}
          sortColumn={{ key: 'jobDate', direction: SortDirection.Desc }}
          toggleClearSortColumn={toggleClearGridSortColumn}
        />
      ) : (
        <JobsTiles data={gridData} jobDateLabel={dateColumnHeader} />
      )}
    </>
  );

  return (
    <TabbarLayout>
      <header>
        <Tabbar tabsMeta={getTabsMeta()} activeTabIndex={activeTabIndexInternal} filter={composeFilter()} onActiveTabChange={handleActiveTabChange} {...props} />
      </header>
      <main className='jobs-view-main'>
        {composeToolbar()}
        <div className={getContentClassName()}>          
          { jobs.length > 0 ? <Jobs /> : <NoJobs /> }          
        </div>
      </main>
    </TabbarLayout>
  );
};

export default JobsView;