import { useState, useLayoutEffect, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { ReactComponent as OopsTowTruck } from '../../assets/OopsTowTruck.svg';
import Button, { ButtonTheme } from '../common/Button';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import NavbarLayout from '../layouts/NavbarLayout';
import './Oops.css';

type OopsProps = {
  error?: string;
};

const Oops = ({ error }: OopsProps) => {
  const [ redirect, setRedirect ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState<string | undefined>();
  const { zenDeskVisible, toggleZenDesk, clearReferrer } = useTruckerFyApp();
  const location = useLocation<any>();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Oops';

    return () => { document.title = prevTitle; };
  }, []);
  
  useLayoutEffect(() => {
    setErrorMessage(error ?? location.state?.error ?? 'Something went wrong!')
  }, [error, location.state?.error]);

  const handleClick = () => {
    clearReferrer(); // Prevents redirecting back to 'Oops' if the user navs to the landing page and logs in
    setRedirect(true);
  };

  const handleContactUs = () => {
    if (!zenDeskVisible) toggleZenDesk();
  };

  if (redirect)
    return <Redirect push to='/login' />

  return (
    <NavbarLayout>
      <header className='oops-header'>
        <Navbar>
          <NavLogo>
            <TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} />
          </NavLogo>
          <NavEnd>
            <Button type='button' theme={ButtonTheme.White} onClick={handleContactUs}>
              Contact Us
            </Button>
          </NavEnd>
        </Navbar>
      </header>
      <main className='oops-main'>
        <div>
          <p className='fs-38 ls-4' style={{ lineHeight: '150%' }}>
            Oops...
            </p>
          <p className='fs-16 ls-2'>
            {errorMessage}
          </p>
          <br/>
          <Button onClick={handleClick}>Back to Homepage</Button>
        </div>
        <OopsTowTruck style={{ width: '554px', height: '188px' }} />
      </main>
    </NavbarLayout>
  );
};

export default Oops;