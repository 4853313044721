import { HTMLAttributes } from 'react';
import './Loader.css';

const Loader = (props: HTMLAttributes<HTMLDivElement>) => {
  const getClassName = () => `loader${props.className ? ` ${props.className}` : ''}`;

  return (
    <div className={getClassName()}>   
      <div className='loader_cogs'>
        <div className='loader_cogs__top'>
          <div className='top_part'></div>
          <div className='top_part'></div>
          <div className='top_part'></div>
          <div className='top_hole'></div>
        </div>
        <div className='loader_cogs__left'>
          <div className='left_part'></div>
          <div className='left_part'></div>
          <div className='left_part'></div>
          <div className='left_hole'></div>
        </div>
        <div className='loader_cogs__bottom'>
          <div className='bottom_part'></div>
          <div className='bottom_part'></div>
          <div className='bottom_part'></div>
          <div className='bottom_hole'></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;