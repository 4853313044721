import * as Yup from 'yup';
import { useCallback, useEffect, useRef, useState, MouseEvent, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FastField } from 'formik';
import { ApolloError, useMutation } from '@apollo/client';
import { getErrorMessage, Mutations } from '../../util/Graphql';
import { MOBILE_VIEWPORT_WIDTH, UNAUTHENTICATED } from '../../Typings';
import { useAuth } from '../../context/AuthContext';
import EmailSvg from '../../assets/vector-icons/materialicons-email.svg';
import Button from '../common/Button';
import Input from '../common/Input';
import ReactGA from 'react-ga4';
import './SignupForm.css';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required')
});

type SignupFormProps = {
  hostedExternally?: boolean; 
  handleLoading?: (loading: boolean) => void;
  handleLogin?: (payload: any, persist: boolean) => void;
  handleSignup?: (email: string) => void;
};

const SignupForm = ({ hostedExternally = false, handleLoading = () => {}, handleLogin = () => {}, handleSignup = () => {} }: SignupFormProps) => {
  const [createCarrierUserFunction, { loading: loadingCreateCarrierUser }] = useMutation(Mutations.CREATE_CARRIER_USER);
  const [createCarrierUserWithGoogleFunction, { loading: loadingCreateCarrierUserWithGoogle }] = useMutation(Mutations.CREATE_CARRIER_USER_WITH_GOOGLE);
  const [errorMessage, setErrorMessage] = useState('');
  const [compactContent, setCompactContent] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const signInWithGoogleRef = useRef<HTMLDivElement>(null);
  const { login } = useAuth();
  const history = useHistory();
  const [hostIsMobile, setHostIsMobile] = useState(false);
  const [clientViewportWidth, setClientViewportWidth] = useState(275);
  const [hostOrigin, setHostOrigin] = useState<string | undefined>();
  const [googleResponse, setGoogleResponse] = useState<any | undefined>();
  const [hostStyle, setHostStyle] = useState<any | undefined>();

  const postMessage = useCallback((message: any) => {
    if (hostOrigin && process.env.REACT_APP_ALLOWED_HOST_ORIGINS?.split(',').find(s => s === hostOrigin)) {
      window.parent.postMessage(message, hostOrigin);
    }
  }, [hostOrigin]);

  const handleLoginInternal = useCallback((payload: any, persist: boolean) => {
    const expiresAt = new Date(payload.tokens.expiresAt);
    login({
      accessToken: payload.tokens.accessToken,
      refreshToken: payload.tokens.refreshToken,
      expiresAt: expiresAt,
      userInfo: {
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        photoUrl: payload.photoUrl,
        carrierId: payload.carrierRoles.length > 0 ? payload.carrierRoles[0].carrier.id : null,
        roles: payload.carrierRoles.map((carrierRole: any) => carrierRole.role)
      }
    }, persist);
    postMessage({ href: 'dashboard' });
  }, [login, postMessage]);
  
  const handleSubmit = (values: any) => {
    document.body.style.cursor = 'wait';
    createCarrierUserFunction({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        photoUrl: ''
      }
    })
    .then(() => {
      setErrorMessage('');

      ReactGA.event({
        category: 'meta',
        action: 'sign_up',
        label: 'true', // id unique to current page load
        value: 1, // values must be integers
        nonInteraction: true, // avoids affecting bounce rate
        transport: 'xhr'
      });

      if (hostedExternally) {
        postMessage({ href: 'checkyouremail', email: values.email });
      } else {
        handleSignup(values.email);
      }
    })
    .catch((e: ApolloError) => {
      let errorMsg = getErrorMessage(e);
      errorMsg = errorMsg === 'duplicate' ? 'Email is already registered' : errorMsg;
      setErrorMessage(errorMsg);
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  const handleGoogleResponse = useCallback((credential: any) => {
    document.body.style.cursor = 'wait';
    createCarrierUserWithGoogleFunction({
      variables: {
        credential: credential
      }
    })
    .then((res: any) => {
      setErrorMessage('');

      ReactGA.event({
        category: 'meta',
        action: 'sign_up_with_google',
        label: 'true', // id unique to current page load
        value: 1, // values must be integers
        nonInteraction: true, // avoids affecting bounce rate
        transport: 'xhr'
      });

      let isMobile: boolean;
      if (hostedExternally) {
        isMobile = hostIsMobile;
      } else {
        isMobile = window.innerWidth < MOBILE_VIEWPORT_WIDTH;
      }

      if (isMobile) {
        if (hostedExternally) {
          postMessage({ href: 'nomobile' });
        } else {
          history.push('/nomobile');
        }
      } else {
        const payload = res.data.createCarrierUserWithGoogle;
        if (hostedExternally) {
          handleLoginInternal(payload, false);
        } else {
          handleLogin(payload, false);
        }
      }
    })
    .catch((e: ApolloError) => {
      let errorMessage = getErrorMessage(e);
      if (errorMessage === UNAUTHENTICATED)
        errorMessage = 'Unknown email';
      setErrorMessage(errorMessage);
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  }, 
  // eslint-disable-next-line
  [createCarrierUserWithGoogleFunction, hostedExternally, hostIsMobile, handleLoginInternal, history, postMessage]);

  const handleContinueWithEmailButtonClick = (e: MouseEvent<HTMLImageElement>) => {
    setCompactContent(prevValue => !prevValue);
    e.preventDefault();
  };

  const handleMessageReceived = useCallback((e: MessageEvent) => {
    const origin = e.data.origin;
    if (process.env.REACT_APP_ALLOWED_HOST_ORIGINS?.split(',').find(s => s === origin)) {
      setHostOrigin(origin);

        const isMobile = e.data.isMobile;
      if (typeof isMobile === 'boolean') {
        setHostIsMobile(isMobile);
      }

      const style = e.data.style;
      if (style) {
        setHostStyle(style);
        if (style.orUseEmailLabelColor) {
          document.documentElement.style.setProperty('--divider-color', style.orUseEmailLabelColor);
        }
        if (style.inputPlaceholderColor) {
          document.documentElement.style.setProperty('--placeholder-color', style.inputPlaceholderColor);
        }
        if (style.inputPlaceholderOpacity) {
          document.documentElement.style.setProperty('--placeholder-opacity', style.inputPlaceholderOpacity);
        }
      }
    }
  }, [setHostOrigin, setHostIsMobile, setHostStyle]);

  const handleWindowResize = useCallback((e: UIEvent) => {
    setClientViewportWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessageReceived);
    window.addEventListener('resize', handleWindowResize);
    return () => { // Cleanup
      window.removeEventListener('message', handleMessageReceived);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleMessageReceived, handleWindowResize]);

  useEffect(() => {
    if (googleResponse) {
      handleGoogleResponse(googleResponse.credential);
    }
  }, [googleResponse, handleGoogleResponse]);

  useLayoutEffect(() => {
    const google = window.google;
    if (google) {
      // Should only call initialize once
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response: any) => setGoogleResponse(response),
        auto_select: false
      });
    }
  }, []);

  useEffect(() => {
    const form = formRef.current;
    if (form && hostedExternally) {
      postMessage({ href: 'changeheight', height: form.offsetHeight + 'px' });
    }
  }, [formRef, hostedExternally, compactContent, errorMessage, postMessage]);

  useEffect(() => {
    const google = window.google;
    if (google) {
      const ref = signInWithGoogleRef.current;
      if (ref) {
        const width = `${hostedExternally ? clientViewportWidth - 1 : window.innerWidth - 35}px`;
        google.accounts.id.renderButton(ref, { text: 'continue_with', width: width });
      }
    }
  }, [hostedExternally, compactContent, signInWithGoogleRef, clientViewportWidth]);

  useEffect(() => handleLoading(loadingCreateCarrierUser || loadingCreateCarrierUserWithGoogle), [handleLoading, loadingCreateCarrierUser, loadingCreateCarrierUserWithGoogle]);

  const getFormClass = () => `signup-form${hostedExternally ? ' hosted-externally' : ''}${compactContent ? ' compact-content' : ' compact-content'}`;

  const getSignupLabelStyle = (style: React.CSSProperties) => {
    if (hostStyle?.signupLabelColor) {
      style.color = hostStyle.signupLabelColor;
    }
    return style;
  };

  const getOrUseEmailLabelStyle = (style: React.CSSProperties) => {
    if (hostStyle?.orUseEmailLabelColor) {
      style.color = hostStyle.orUseEmailLabelColor;
    }
    return style;
  };

  const getCompactContent = () => (
    <form ref={formRef} className={getFormClass()}>
      <table>
          <thead>
            {
              hostedExternally && <tr><th style={getSignupLabelStyle({ paddingBottom: '10px', textAlign: 'center' })}>Sign Up</th></tr>
            }
            <tr><th><div ref={signInWithGoogleRef} style={{ height: '40px' }} /></th></tr>
            <tr><th><div style={{ height: '6px' }}/></th></tr>
            <tr>
              <th>
                <div className='continue-with-email-btn' onMouseDown={handleContinueWithEmailButtonClick}>
                <img
                    width='24px'
                    className='continue-with-email-img'
                    title='Search database'
                    src={EmailSvg}
                    alt='Continue with Email'
                  />
                  <span style={{marginLeft: '-19px'}}>Continue with Email</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody></tbody>
      </table>
    </form>
  );

  const getFullContent = () => (
    <Form ref={formRef} className={getFormClass()} style={loadingCreateCarrierUser ? {opacity: '.4'} : {}}>
      <table>
        <thead>
          {
            hostedExternally && <tr><th style={getSignupLabelStyle({ paddingBottom: '10px', textAlign: 'center' })}>Sign Up</th></tr>
          }
          <tr><th><div ref={signInWithGoogleRef} style={{ height: '40px' }} /></th></tr>
          <tr>
            <th>
              <div className='or-use-email'>
                <div style={getOrUseEmailLabelStyle({})}>or use email</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr><td><div><FastField autoFocus={hostedExternally} component={Input} name='firstName' placeholder='First name' type='text' className='foo' /></div></td></tr>
          <tr><td><div><FastField component={Input} name='lastName' placeholder='Last name' type='text' /></div></td></tr>
          <tr><td><div><FastField component={Input} name='email' placeholder='Email address' type='email' /></div></td></tr>
          <tr><td><div><FastField component={Input} name='password' placeholder='Create password' type='password' /></div></td></tr>
          { errorMessage !== '' && <tr><td className='error-message'><span>{errorMessage}</span></td></tr> }
        </tbody>
      </table>
      <footer>
        <>
          <div><Button disabled={loadingCreateCarrierUser || loadingCreateCarrierUserWithGoogle} type='submit'>NEXT</Button></div>
          {
            !hostedExternally &&
              <p>Learn how we process your data:&nbsp;&nbsp;<a href='/privacy'>Privacy Policy</a>.</p>
          }
        </>
      </footer>
    </Form>
  );

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      }}
      onSubmit={values => handleSubmit(values)}
      validationSchema={SignupSchema}
    >
      { () => ((hostedExternally && compactContent) ? getCompactContent() : getFullContent()) }
    </Formik>
  );
};
  
export default SignupForm;