import { useEffect, useState } from 'react';
import { IModalSubscriber, ModalButton } from '../../context/ModalContext';
import Loader from '../transition/Loader';
import ReactGA from 'react-ga4';
import SignupForm from '../shared/SignupForm';
import TruckerFyLogo from '../common/TruckerFyLogo';
import '../../App.css';
import './Signup.css';

type SignupBaseProps = {
  handleLogin?: (payload: any, persist: boolean) => void;
  handleSignup?: (email: string) => void
  handleCloseButtonClick?: () => void;
};

export type SignupProps = SignupBaseProps & IModalSubscriber

const Signup = ({
  handleLogin = () => {},
  handleSignup = () => {},
  handleCloseButtonClick = () => {},
  buttonClick
}: SignupProps) => {
  const [loading, setLoading] = useState(false);
  
  const handleLoading = (isLoading: boolean) => setLoading(isLoading);

  useEffect(() => {
    ReactGA.event({
      category: 'meta',
      action: 'show_dialog',
      label: 'sign_up', // id unique to current page load
      value: 1, // values must be integers
      nonInteraction: true, // avoids affecting bounce rate
      transport: 'xhr'
    });
  }, []);

  useEffect(() => {
    if (buttonClick && buttonClick.button === ModalButton.Close) {
      handleCloseButtonClick();
    }
  }, [buttonClick, handleCloseButtonClick]);

  return (
    <section className='modal-signup'>
      { loading && <Loader /> }
      <div className='signup-left' style={loading ? {opacity: '0.4'} : {}}>
        <TruckerFyLogo className='signup signup-truckerfy-logo' isNotLink={true} />
        <p className='signup'>
          Great!<br/>
          Let's get started.
        </p>
      </div>
      <SignupForm handleLoading={handleLoading} handleLogin={handleLogin} handleSignup={handleSignup} />
    </section>
  );
};
  
export default Signup;