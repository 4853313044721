import * as Yup from 'yup';
import { useCallback, useEffect, useLayoutEffect, useRef, useState, FocusEvent } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as TruckFrontSvg } from '../../assets/TruckFront.svg';
import { FastField, Field, Form, Formik } from 'formik';
import { Carrier, UNAUTHENTICATED, UserInfo } from '../../Typings';
import { deepEqual } from '../../util/ObjectUtil';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useAuth } from '../../context/AuthContext';
import { useJobs } from '../../context/JobsContext';
import { getErrorMessage, Mutations, Queries } from '../../util/Graphql';
import { PersonAvatar } from '../specialized/cloudinary/PersonAvatar';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo from '../common/TruckerFyLogo';
import Upload, { UploadType } from '../common/Upload';
import NavbarLayout from '../layouts/NavbarLayout';
import Input from '../common/Input';
import Button from '../common/Button';
import PostalStateSelect from '../common/PostalStateSelect';
import Divider from '../common/Divider';
import Loader from '../transition/Loader';
import Textarea from '../common/Textarea';
import './EditCarrier.css';

const EditCarrier = () => {
  const message = '\nLeave page?\n\nChanges you made may not be saved.';
  const location = useLocation<any>();
  const history = useHistory();
  const { carrier } = useJobs();
  const { getUserInfo, setUserInfo } = useAuth();
  const [ createCarrierFunction, { loading: createCarrierLoading } ] = useMutation(Mutations.CREATE_CARRIER);
  const [ updateCarrierFunction, { loading: updateCarrierLoading } ] = useMutation(Mutations.UPDATE_CARRIER);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ carrierInternal, setCarrierInternal ] = useState<Carrier>();
  const [ createNewCarrier, setCreateNewCarrier ] = useState(false);
  const [ hasPendingChanges, setHasPendingChanges ] = useState(false);
  const [ previousDotNumber, setPeviousDotNumber ] = useState('');
  const [ fmcsaErrorMessage, setFmcsaErrorMessage ] = useState<string | undefined>();

  const formikRef = useRef(null);
  const fmcsaOffline = 'Unable to prefill.  The FMCSA database is offline.'

  const editCarrierSchema = Yup.object().shape({
    dotNumber: Yup.string()
      .required('Dot number is required')
      .max(8),
      // // .matches(/^(?=(?:.{6}|.{8})$)[0-9]*$/, 'Must be 6 or 8 digits (leading 0s may be needed)'),
    name: Yup.string().required('Company name is required'),
    postalCode: Yup.string()
      .required('Zip code is required')
      .matches(/^\d{5}(?:[- ]?\d{4})?$/, 'Invalid zip code') 
  });

  const handleWindowBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    if (hasPendingChanges) {
      e.preventDefault();
      return e.returnValue = 'Are you sure you want to exit?';
    }
  }, [hasPendingChanges]);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Edit Carrier';

    return () => { document.title = prevTitle; };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleWindowBeforeUnload);
  }, [handleWindowBeforeUnload]);

  useLayoutEffect(() => {
    if (location.state?.createNewCarrier) {
      setCreateNewCarrier(location.state?.createNewCarrier);
    } else if (carrier) {
      setCarrierInternal(carrier);
      setCreateNewCarrier(false);
    }
  }, [location.state, carrier]);
  
  const handleCarrierDataFetched = (e: ApolloError | undefined, data: any) => {
    if (e) {
      const errorMsg = getErrorMessage(e);
      console.error(errorMsg);
      if (errorMsg.indexOf('parse') < 0) { // Redirects to the FMCSA maintenance page when API is offline hence JSON parse error
        setFmcsaErrorMessage(errorMsg);
      } else {
        setFmcsaErrorMessage(fmcsaOffline);
      }
    } else if (data && data.fmcsa) {
      const current: any = formikRef.current;
      const json = data.fmcsa;

      current.setFieldValue('name', json.legalName);
      current.setFieldValue('street1', json.street1);
      current.setFieldValue('city', json.city);
      current.setFieldValue('state', json.state);
      current.setFieldValue('postalCode', json.postalCode);

      setFmcsaErrorMessage(undefined);
      setPeviousDotNumber(current.values.dotNumber);
    }
  };

  const [ getCarrierDataByDotFunction, { error: carrierDataByDotError, loading: carrierDataByDotLoading, data: carrierDataByDotData }] = useLazyQuery(
    Queries.GET_CARRIER_DATA_BY_DOT, 
    {
      fetchPolicy: 'no-cache',
      onError() {
        if (carrierDataByDotError) {
          // Note that the message will look like "DOT # 1111 not found!" if the DOT # is not found
          const errorMsg = getErrorMessage(carrierDataByDotError, true);
          console.error(errorMsg);
          if (errorMsg.indexOf('parse') < 0) { // Redirects to the FMCSA maintenance page when API is offline hence JSON parse error
            setFmcsaErrorMessage(errorMsg);
          } else {
            setFmcsaErrorMessage(fmcsaOffline);
          }
        }
        
        if (formikRef.current) {
          const current: any = formikRef.current;
          current.validateField('dotNumber');
        }
      },
      onCompleted() { handleCarrierDataFetched(carrierDataByDotError, carrierDataByDotData); } }
  );

  const handleSubmit = (createdOrUpdatedCarrier: Carrier) => {
    document.body.style.cursor = 'wait';
    const carrierFunction = carrierInternal ? updateCarrierFunction : createCarrierFunction;
    const carrierFunctionVars = carrierInternal
      ? {
          id: createdOrUpdatedCarrier.id,
          name: createdOrUpdatedCarrier.name,
          dotNumber: createdOrUpdatedCarrier.dotNumber,
          postalCode: createdOrUpdatedCarrier.postalCode,
          ou: createdOrUpdatedCarrier.ou,
          description: createdOrUpdatedCarrier.description,
          street1: createdOrUpdatedCarrier.street1,
          street2: createdOrUpdatedCarrier.street2,
          city: createdOrUpdatedCarrier.city,
          state: createdOrUpdatedCarrier.state,
          photoUrl: createdOrUpdatedCarrier.photoUrl,
          bannerPhoto: createdOrUpdatedCarrier.bannerPhoto
        }
      : {
          name: createdOrUpdatedCarrier.name,
          dotNumber: createdOrUpdatedCarrier.dotNumber,
          postalCode: createdOrUpdatedCarrier.postalCode,
          ou: createdOrUpdatedCarrier.ou,
          description: createdOrUpdatedCarrier.description,
          street1: createdOrUpdatedCarrier.street1,
          street2: createdOrUpdatedCarrier.street2,
          city: createdOrUpdatedCarrier.city,
          state: createdOrUpdatedCarrier.state,
          photoUrl: createdOrUpdatedCarrier.photoUrl,
          bannerPhoto: createdOrUpdatedCarrier.bannerPhoto
        };
    carrierFunction({
      variables: carrierFunctionVars
    })
    .then((res: any) => {
      setErrorMessage('');
      setHasPendingChanges(false);
      const targetCarrier = carrierInternal ? res.data.updateCarrier : res.data.createCarrier;
      if (!carrierInternal) {
        const newUserInfo = { ...getUserInfo(), carrierId: targetCarrier?.id } as UserInfo;
        setUserInfo(newUserInfo);
      }
      history.push('/questionnaire/carrier/1', { targetId: targetCarrier?.id, savedAnswers: targetCarrier?.profile, isEditMode: carrierInternal ? true : false });
    })
    .catch((e: ApolloError) => {
      let errorMsg = getErrorMessage(e);
      if (errorMsg === UNAUTHENTICATED) {
        history.push('/login', { isAuthExpired: true });
      } else {
        errorMsg = errorMsg === 'duplicate' ? 'Company is already registered' : errorMsg;
        setErrorMessage(`Unable to save.  ${errorMsg}`);
      }
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  const composeHeaderText = () => {
    if (carrierInternal) {
      return (
        <>
          <span className='edit-carrier-main-text'>Welcome back, {getUserInfo()?.firstName}!</span><br/>
          <span className='edit-carrier-main-subtext'>Be sure to include details about your company to help you find the perfect truck driver matches.</span>
        </>
      );
    }

    return (
      <>
        <span className='edit-carrier-main-text'>Welcome to your TruckerFy account, {getUserInfo()?.firstName}!</span><br/>
        <span className='edit-carrier-main-subtext'>We'll ask you details about your company to set up your account and help you find the perfect truck driver matches.</span>
      </>
    );
  };
  
  const validate = (values: any) => {
    if (formikRef.current) {
      const current: any = formikRef.current;
      const initialValues = current.initialValues;
      const isEqual = deepEqual(initialValues, values)
      setHasPendingChanges(!isEqual);
    }
    return {};
  };

  const onDotNumberInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (formikRef.current && createNewCarrier && !carrierDataByDotLoading) {
      const dotNumber = e.currentTarget.value;
      if (dotNumber.length && (previousDotNumber !== dotNumber || carrierDataByDotError)) {
        return getCarrierDataByDotFunction({
          variables: { dotNumber: dotNumber }
        });
      }
    }
  };

  const composeFormik = () => {
    return (createNewCarrier || carrierInternal) &&
      <Formik
        innerRef={formikRef}
        initialValues={{
          id: carrierInternal?.id ?? '',
          dotNumber: carrierInternal?.dotNumber ?? '',
          name: carrierInternal?.name ?? '',
          ou: carrierInternal?.ou ?? '',
          description: carrierInternal?.description ?? '',
          street1: carrierInternal?.street1 ?? '',
          street2: carrierInternal?.street2 ?? '',
          city: carrierInternal?.city ?? '',
          state: carrierInternal?.state ?? '',
          postalCode: carrierInternal?.postalCode ?? '',
          photoUrl: carrierInternal?.photoUrl,
          bannerPhoto: carrierInternal?.bannerPhoto
        }}
        onSubmit={carrier => handleSubmit(carrier)}
        validationSchema={editCarrierSchema}
        validate={validate}
      >
        {({handleBlur}) => (
          <Form className='edit-carrier-main-form' style={(createCarrierLoading || updateCarrierLoading) ? {opacity: '0.4'} : {}}>
            <table>
              <thead>
                <tr><th style={{width: '350px'}}></th><th style={{width: '350px'}}></th><th style={{width: '175px'}}></th></tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <span>DOT number</span>
                    <Field /* Cannot use FastField due to dynamic 'info' prop */
                      autoFocus
                      component={Input}
                      name='dotNumber'
                      placeholder='DOT number'
                      type='text'
                      maxLength={8}
                      digitsOnly={true}
                      info={fmcsaErrorMessage}
                      onBlur={(e: FocusEvent<any, Element>) => { handleBlur(e); onDotNumberInputBlur(e);}}
                    />
                  </td>
                </tr>
                <tr><td colSpan={3}><span>Company name</span><FastField component={Input} name='name' placeholder='Company name' type='text' /></td></tr>
                <tr><td colSpan={3}><span>Division, department, team or office (optional)</span><FastField component={Input} name='ou' placeholder='Division, department, team or office (optional)' type='text' /></td></tr>
                <tr><td colSpan={3}><span>Description (describe your company to candidate drivers)</span><FastField component={Textarea} name='description' placeholder='Company description' rows={5} /></td></tr>
                <tr><td colSpan={3}><span>Address 1</span><FastField component={Input} name='street1' placeholder='Address 1' type='text'/></td></tr>
                <tr><td colSpan={3}><span>Address 2</span><FastField component={Input} name='street2' placeholder='Address 2' type='text'/></td></tr>
                <tr>
                  <td><span>City</span><FastField component={Input} name='city' placeholder='City' type='text'/></td>
                  <td style={{ paddingLeft: '20px', paddingRight: '20px' }}><span>State</span><FastField component={PostalStateSelect} name='state'/></td>
                  <td><span>Zip Code</span><FastField component={Input} name='postalCode' placeholder='Zip Code' type='text'/></td>
                </tr>
                <tr>
                  <td colSpan={1} style={{verticalAlign: 'top'}}><span>Logo</span><FastField component={Upload} name='photoUrl' type={UploadType.Logo} /></td>
                  <td colSpan={2} style={{verticalAlign: 'top', paddingLeft: '20px'}}><span>Banner</span><FastField component={Upload} name='bannerPhoto' type={UploadType.Banner} /></td>
                </tr>
                { errorMessage !== '' && <tr><td colSpan={3} className='error-message'><span style={{color: 'darkred'}}>{errorMessage}</span></td></tr> }
              </tbody>
            </table>
            <footer>
              <Button formNoValidate disabled={(createCarrierLoading || updateCarrierLoading)} type='submit'>CONTINUE</Button>
            </footer>
          </Form>
        )}
      </Formik>
  };

  return (
    <>
      <Prompt when={hasPendingChanges} message={message} />
      <NavbarLayout>
        { (createCarrierLoading || updateCarrierLoading || carrierDataByDotLoading) && <Loader /> }
        <header className='edit-carrier-header'>
          <Navbar>
            <NavLogo><TruckerFyLogo /></NavLogo> 
            <NavEnd>
              <span style={{color: 'white', paddingRight: '10px'}}>{getUserInfo()?.firstName} {getUserInfo()?.lastName}</span>
              <PersonAvatar publicId={getUserInfo()?.photoUrl} size={44} />
            </NavEnd>
          </Navbar>
        </header>
        <main className='edit-carrier-main'>
          <div>
            <div>
              <p>
                <TruckFrontSvg className='edit-carrier-main-image' />
                { composeHeaderText() }
              </p>
              <Divider className='edit-carrier-main-divider' />
              {/* <p className='edit-carrier-main-header'>Tell us about your company</p>
              <p className='edit-carrier-main-subheader'>You can leave this blank for now and you’ll be able to complete this information later.</p> */}
            </div>
            { composeFormik() }
          </div>
        </main>
      </NavbarLayout>
    </>
  );
};

export default EditCarrier;
