import { HTMLAttributes, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../context/JobsContext';
import { ReactComponent as JobsSvg } from '../../../assets/Job.svg'
import { ReactComponent as CandidatesSvg } from '../../../assets/Candidate.svg'
import { ReactComponent as ConversationsSvg } from '../../../assets/Conversation.svg'
import { ReactComponent as CaretDown } from '../../../assets/CaretDown.svg'
import { getJobStatusColor } from '../../helper/JobHelper';
import { Area, EdgeAlignment, JobModel, JobStatus } from '../../../Typings';
import { CarrierLogo } from '../cloudinary/CarrierLogo';
import Accordion, { AccordionSection, SelectedItem } from '../../common/Accordion';
import Button, { ButtonTheme } from '../../common/Button';
import Divider, { DividerTheme } from '../../common/Divider';
import TruckerFyLogo from '../../common/TruckerFyLogo';
import DropdownMenu from '../../common/DropdownMenu';
import './Sidebar.css';

interface ISidebarProps extends HTMLAttributes<HTMLDivElement> {
  onAccordionItemSelect: (area: Area, job?: JobModel) => void;
  selectedSectionIndex: number;
  jobId?: string;
};

const Sidebar = ({ onAccordionItemSelect, selectedSectionIndex, jobId, ...props }: ISidebarProps) => {
  const { carrier, jobs, activeJob, setActiveJob } = useJobs();
  const [ filteredSortedJobs, setFilteredSortedJobs ] = useState<Array<JobModel>>([]);
  const [ accordionSections, setAccordionSections ] = useState<Array<AccordionSection>>([]);
  const [ selectedItem, setSelectedItem ] = useState<SelectedItem | undefined>();
  const history = useHistory();

  useEffect(() => {
    if (activeJob && accordionSections.length) {
      const tempJob = jobs.find(job => job.id === activeJob?.id);
      if (tempJob) {
        tempJob.publicationStatus = activeJob.publicationStatus;
        const tempChild = accordionSections[0].children.find(child => child[3] === activeJob.id);
        if (tempChild)
          tempChild[1] = getJobStatusColor(activeJob.publicationStatus);
      }
    }
  }, [activeJob, jobs, accordionSections]);

  useEffect(() => {
    if (jobs) {
      if (activeJob) {
        const tempJob = jobs.find((item: JobModel) => item.id === activeJob.id);
        if (tempJob) tempJob.publicationStatus = activeJob.publicationStatus;
      }

      const tempJobs = jobs
        .filter((item: JobModel) => item.publicationStatus !== JobStatus.Archived)
        .sort((item1: JobModel, item2: JobModel) => { 
          if (item1.publicationStatus === item2.publicationStatus) 
            return ((item1.internalTitle ?? item1.title) < (item2.internalTitle ?? item2.title) ? -1 : 1);
          return item1.publicationStatus === JobStatus.Draft ? -1 : 1;
        });
        setFilteredSortedJobs(tempJobs);

      if (activeJob || jobId) {
        const index = tempJobs.findIndex((item: JobModel) => item.id === (activeJob?.id ?? jobId));
        if (index > -1) {
          setSelectedItem({
            tag: Area.Jobs.toString(),
            sectionIndex: 0,
            childIndex: index
          });
        }
      }

      const accordionJobs: Array<[string, string, string, string]> = tempJobs.map((job: JobModel) => [ job.internalTitle ?? job.title, getJobStatusColor(job.publicationStatus), job.publicationStatus.toString(), job.id ]);
      const accordionSections = [
        {
          preElement: <JobsSvg/>,
          title: 'Jobs',
          tag: Area.Jobs.toString(),
          children: accordionJobs ?? []
        }, {
          preElement: <CandidatesSvg/>,
          title: 'Candidates',
          tag: Area.Candidates.toString(),
          children: []
        }, {
          preElement: <ConversationsSvg/>,
          title: 'Conversations',
          tag: Area.Conversations.toString(),
          children: []
        }
      ];

      setAccordionSections(accordionSections);
       //setSelectedSectionIndexInternal(0);
    }
  }, [jobs, activeJob]);

  const handleAccordionItemSelect = (item: SelectedItem) => {
    const tag = item.tag;
    const areaStr = Object.values(Area)[+tag];
    const area: Area = Object.values(Area).indexOf(areaStr);
    const childIndex = item.childIndex;
    let job: JobModel | undefined;

    if (childIndex !== undefined) {
      job = filteredSortedJobs[childIndex];
      history.push(`/dashboard/job/${job?.id}`);
    } else {
      setActiveJob(undefined);
      history.push(`/dashboard/${Area[area].toLowerCase()}`);
    }
  };

  const getClassName = () => `sidebar${props.className ? ` ${props.className}` : ''}`;

  const menuItemsArray = [ 
    'Edit Carrier', 
    'View Public Carrier Page'
  ];

  const handleEditCarrier = () => {
    history.push('/editcarrier', { carrier: carrier });
  };

  const handleCreateJob = () => {
    if (carrier)
      history.push('/editjob', { carrierId: carrier.id });
  };

  const handleMenuItemSelect = (text: string, index: number) => {
    switch (text) {
      case 'Edit Carrier':
        handleEditCarrier();
        break;
      case 'View Public Carrier Page':
        if (carrier)
          window.open('/carrier/' + carrier.id, '_blank', 'noopener,noreferrer');
        break;
    }
  };

  const composeTip = () => {
    if (jobs.length <= 3) {
      return (
        <div className='sidebar-tip'>
          <p className='sidebar-tip-title'>Tip</p>
          <p className='sidebar-tip-subtitle'>You can start creating a job post today and save it as a draft.</p>
          <Button type='button' theme={ButtonTheme.White} onClick={handleCreateJob}>Create Job</Button>
        </div>
      );
    }
  };

  const composeCarrierLogoNameDropdown = () => {
    return (
      <div className='sidebar-carrier-logo-name'> 
        <CarrierLogo size={44} publicId={carrier?.photoUrl} />
        <div style={{ color: 'white', marginLeft: '10px', flex: '1' }}>{carrier?.name}</div>
        <CaretDown className='sidebar-carrier-logo-name-caret' style={{ fill: 'white', height: '14px', width: '14px', opacity: '.9' }} />
      </div>
    );
  };

  return (
    <section className={getClassName()}>
      <DropdownMenu
        root={composeCarrierLogoNameDropdown()}
        handleItemSelect={handleMenuItemSelect}
        style={{ marginLeft: '2px'}}
        items={menuItemsArray}
        containerEdgeAlignment={EdgeAlignment.Left}
      />
      <Divider className='sidebar-divider' theme={DividerTheme.OnDark} />
      <Accordion sections={accordionSections} onItemSelect={handleAccordionItemSelect} selectedSectionIndex={selectedSectionIndex} selectedItem={selectedItem} />
      { composeTip() }
      <Divider className='sidebar-divider' theme={DividerTheme.OnDark} />
      <TruckerFyLogo className='sidebar-logo' isNotLink={true} />
    </section>
  );
};

export default Sidebar