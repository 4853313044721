import { FieldInputProps, FormikProps } from 'formik';
import React, { ReactNode, SelectHTMLAttributes, CSSProperties } from 'react';
import ChevronSvg from '../../assets/Chevron.svg';
import ValidationErrorSvg from '../../assets/ValidationError.svg';
import FormFieldMessage from './FormFieldMessage';
import './Select.css';

enum Glyph {
  Clear,
  Dropdown,
  Validation
}

export interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  suppressValidation?: boolean;
  children?: ReactNode;
};

const Select = ({ field, form: { touched, errors, setFieldValue }, suppressValidation = false, ...props }: ISelectProps) => {
  const handleClearMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {  
    setFieldValue(field.name, '');
    e.preventDefault();
  };

  const isInvalid = () => !suppressValidation && touched[field.name] && errors[field.name];
  const getRightPadding = () => {
    let padding = 15;
    if (field.value) padding += 19;
    if (isInvalid()) padding += 24;
    return `${padding}px`;
  };

  const getRightPosition = (glyph: Glyph) => {
    let rightPos;
  
    if (glyph === Glyph.Clear) {
      rightPos = 8
      if (isInvalid()) rightPos = 32
      rightPos += 22;
    } else { // Dropdown
      rightPos = isInvalid() ? 37 : 13;
    }
    return `${rightPos}px`;
  };

  const getClass = () => `custom-select${props.className ? ` ${props.className}` : ''}`;

  const getStyle = (style: CSSProperties | undefined) => ({
    ...style,
    ...props.style
  });

  return (
    <div style={getStyle({ position:'relative' })}>
      <select
        {...field}
        {...props}
        className={getClass()}
        style={{ paddingRight: getRightPadding() }}
      >
        {props.children}
      </select>
      { field.value && <i tabIndex={-1} className='custom-select-clear' title='Clear field' style={{ right:getRightPosition(Glyph.Clear) }} onMouseDown={handleClearMouseDown}>&times;</i> }
      <img height='8px' width='8px' className='custom-select-dropdown' style={{ right: getRightPosition(Glyph.Dropdown) }} src={ChevronSvg} alt='Dropdown' />
      {
        isInvalid() && 
          <>
            <img height='8px' width='8px' className='custom-select-validation' title='Please fix the error displayed beneath the field' src={ValidationErrorSvg} alt='Validation' />
            <FormFieldMessage text={errors[field.name]} />
          </>
      }
    </div>
  );
};

export default Select;
