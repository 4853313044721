import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { useTruckerFyApp } from './TruckerFyAppContext';
import { Queries, getErrorMessage } from '../util/Graphql';
import Questionnaire from '../classes/Questionnaire';
import useTimer from '../util/Timer';

type QuestionnaireService = {
  carrierQuestionnaire : Questionnaire | undefined;
  jobQuestionnaire : Questionnaire | undefined;
  driverQuestionnaire : Questionnaire | undefined;
  getQuestionnaire: (id: string) => Questionnaire | undefined;
};

const QuestionnaireContext = createContext<QuestionnaireService>({} as QuestionnaireService);

const QuestionnaireProvider = ({ children }: { children: React.ReactNode }) => {
  const [ carrierQuestionnaire, setCarrierQuestionnaire ] = useState<Questionnaire>();
  const [ jobQuestionnaire, setJobQuestionnaire ] = useState<Questionnaire>();
  const [ driverQuestionnaire, setDriverQuestionnaire ] = useState<Questionnaire>();
  const [ initializationComplete, setInitializationComplete ] = useState(false);
  const history = useHistory();
  const { appVisible } = useTruckerFyApp();

  const { /* loading: questionnairesLoading,*/ refetch } = useQuery(
    Queries.GET_QUESTIONNAIRES,
    { 
      fetchPolicy: 'no-cache', 
      onCompleted(data) { 
        if (data?.carrier && data?.job && data?.driver) {
          setCarrierQuestionnaire(new Questionnaire(data.carrier.questionnaire));
          setJobQuestionnaire(new Questionnaire(data.job.questionnaire));
          setDriverQuestionnaire(new Questionnaire(data.driver.questionnaire));
          setInitializationComplete(true);
        }
      },
      onError(e) {
        const errorMsg = getErrorMessage(e);
        history.push('/oops', { error: errorMsg });
      }
    }
  );

  const refreshQuestionnaires = () => {
    console.log('refreshQuestionnaires()');
    refetch();
  };

  // const refresh = () => refreshQuestionnaires();

  // We probably don't need to do an immediate refresh of Questionnaires when the app becomes visible again...
  // useEffect(() => {
  //   if (appVisible)
  //     refreshQuestionnaires();
  // }, [appVisible]);

  const timerCallback = () => {
    if (appVisible)
      refreshQuestionnaires();
  };

  useTimer({ callback: timerCallback, delay: parseInt(process.env.REACT_APP_QUESTIONNAIRES_REFRESH_INTERVAL_MS ?? '900000') }); // Default to 15 mins

  const getQuestionnaire = (id: string) : Questionnaire | undefined => {
    switch (id.toUpperCase()) {
      case 'CARRIER':
        return carrierQuestionnaire;
      case 'JOB':
        return jobQuestionnaire;
      case 'DRIVER':
        return driverQuestionnaire;
      }
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        carrierQuestionnaire,
        jobQuestionnaire,
        driverQuestionnaire,
        getQuestionnaire
      }}
    >
      { initializationComplete && children }
    </QuestionnaireContext.Provider>
  );
};

const useQuestionnaire = () => {
  const context = useContext(QuestionnaireContext);
  return context;
}

export { QuestionnaireProvider, useQuestionnaire };
