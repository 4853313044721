import { HTMLAttributes } from 'react';
import './Divider.css';

export enum DividerTheme {
  OnDark,
  OnLight
};

export interface IDividerProps extends HTMLAttributes<HTMLDivElement> {
   theme?: DividerTheme;
};

const Divider = ({ theme = DividerTheme.OnLight, ...props }: IDividerProps) => {
  const getClassName = () => `divider-${theme === DividerTheme.OnDark ? 'dark' : 'light'}${props.className ? ` ${props.className}` : ''}`;

  return (
    <div {...props} className={getClassName()} />
  );
};

export default Divider;