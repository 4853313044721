import { FieldInputProps, FormikProps, FieldMetaProps } from 'formik';
import BooleanAnswer from './BooleanAnswer';
import ChoiceAnswer from './ChoiceAnswer';
import DateAnswer from './DateAnswer';
import IntegerAnswer from './IntegerAnswer';
import MapAnswer from './MapAnswer';
import RangeAnswer from './RangeAnswer';
import SelectAnswer from './SelectAnswer';
import TextAnswer from './TextAnswer';
import './Question.css';

type QuestionProps = {
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  meta: FieldMetaProps<any>
  templateJson: any;
  questionJson: any;
};

const Question = ({ field, form, meta, questionJson, ...props }: QuestionProps) => {
  
  const composeAnswers = () => { 
    let qType = questionJson.type;

    if (qType === 'single' && questionJson.presentation === 'bubbles')
      qType = 'multiple';

    switch (qType) {
      case 'multiple':
        return <ChoiceAnswer 
          key={questionJson.id}
          field={field}
          form={form}
          meta={meta}
          minChoices={questionJson.minChoices}
          maxChoices={ questionJson.type === 'single' ? 1 : questionJson.maxChoices }
          choicesJson={questionJson.choices}
        />
      case 'single':
        return <SelectAnswer
          key={questionJson.id}
          field={field}
          form={form}
          placeholderText={questionJson.placeholderText}
          dropdownWidth={questionJson.dropdownWidth}
          choicesJson={questionJson.choices}
        />
      case 'map':
        return <MapAnswer 
          key={questionJson.id}
          field={field}
          form={form}
          meta={meta}
          minChoices={questionJson.minChoices}
          maxChoices={questionJson.maxChoices}
          choicesJson={questionJson.choices}
        />
      case 'date':
        return <DateAnswer 
          key={questionJson.id}
          field={field}
          form={form}
          placeholderText={questionJson.placeholderText}
        />
      case 'range':
        return <RangeAnswer 
          key={questionJson.id}
          field={field}
          form={form}
          min={questionJson.min}
          max={questionJson.max}
          step={questionJson.step}
          placeholderTextMin={questionJson.placeholderTextMin}
          placeholderTextMax={questionJson.placeholderTextMax}
          subText={questionJson.subText}
          formatAsCurrency={questionJson.formatAsCurrency}
          inputWidth={questionJson.inputWidth}
        />
      case 'text':
        return <TextAnswer 
          key={questionJson.id}
          field={field}
          form={form}
          placeholderText={questionJson.placeholderText}
          subText={questionJson.subText}
          lines={questionJson.lines}
        />
      case 'integer':
        return <IntegerAnswer 
          key={questionJson.id}
          field={field}
          form={form}
          placeholderText={questionJson.placeholderText}
          subText={questionJson.subText}
        />
      default:
        return <BooleanAnswer
          key={questionJson.id}
          field={field}
          form={form}
          meta={meta}
          templateJson={props.templateJson[questionJson.type]}
        />
    }
  };

  return (
    <>
      <p className='question-title'>{questionJson.title}</p>
      <p className='question-sub-title'>{questionJson.subTitle}</p>
      {composeAnswers()}
    </>
  );
};

export default Question;