import { getErrorMessage, Mutations } from '../../util/Graphql';
import { useHistory } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { useAuth } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { UNAUTHENTICATED } from '../../Typings';
import Button, { ButtonTheme } from '../common/Button';
import Loader from './Loader';
import NavbarLayout from '../layouts/NavbarLayout';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import { ReactComponent as MegaphoneSvg } from '../../assets/Megaphone.svg';
import './Validate.css';

const Validate = () => {
  const [ errorMessage, setErrorMessage ] = useState<string | undefined>();
  const { login } = useAuth();
  const { zenDeskVisible, toggleZenDesk } = useTruckerFyApp();
  const history = useHistory();

  const {
    id: idParam,
    email: emailParam,
    token: tokenParam,
  } = useParams<{ id: string; email: string; token: string }>();

  const emailId = decodeURIComponent(idParam);
  const email = decodeURIComponent(emailParam);
  const tokenResolved = decodeURIComponent(tokenParam);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Validate';

    return () => { document.title = prevTitle; };
  }, []);

  const [validateEmailFunction, { loading }] = useMutation(
    Mutations.VALIDATE_EMAIL,
    {
      variables: {
        emailId,
        email,
      },
      onError(e: ApolloError) {
        let errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          errorMsg = 'The link in the validation email has expired.';
        }
        setErrorMessage(errorMsg);
      },
      onCompleted(data: any) {
        const {
          id,
          firstName,
          lastName,
          photoUrl,
          carrierRoles,
          tokens: { accessToken, refreshToken, expiresAt },
        } = data.validateEmail;

        login (
          {
            accessToken,
            refreshToken,
            expiresAt: new Date(expiresAt),
            userInfo: {
              id,
              firstName,
              lastName,
              email,
              photoUrl,
              carrierId: carrierRoles.length > 0 ? carrierRoles[0].carrier.id : null,
              roles: carrierRoles.map((carrierRole: any) => carrierRole.role)
            }
          },
          true /* persist */
        );

        history.push('/dashboard');
      } 
    }
  );

  const handleButtonClick = () => {
    if (tokenResolved)
      validateEmailFunction(); 
    else
      setErrorMessage('Invalid access token');
  };

  const handleContactUs = () => {
    if (!zenDeskVisible) toggleZenDesk();
  };

  if (errorMessage) {
    if (errorMessage.indexOf('already been verified!') >= 0) 
      history.push('/login');
    else
      history.push('/oops', { error: errorMessage });
  }

  return (
    <div className='validate'>
      { loading && <Loader /> }
      <NavbarLayout>
        <header className='validate-header'>
          <Navbar>
            <NavLogo><TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} /></NavLogo> 
            <NavEnd><Button type='button' theme={ButtonTheme.White} onClick={handleContactUs}>Contact us</Button></NavEnd>       
          </Navbar>
        </header>
        <main className='validate-main'>
          <MegaphoneSvg style={{ width: '134px', height: '134px' }}/>
          <p className='fw-5 fs-24 ls-2'>Almost There!</p>
          <p className='fw-3 fs-16 ls-2'>Click the button below, and we'll take you to your dashboard.</p>
          <span>
            <br/>
            <Button type='button' theme={ButtonTheme.White} onClick={handleButtonClick}>Activate My Account</Button>
          </span>
        </main>
      </NavbarLayout>
    </div>
  );
};

export default Validate;