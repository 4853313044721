import { FC } from 'react';
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';

const AdminRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const { isAdmin } = useAuth();
  
  return (
    <Route
      {...rest}
      render={() =>
        isAdmin() ? (
          <>{children}</>
        ) : (
          <Redirect to='/' />
        )
      }
    ></Route>
  );
};

export default AdminRoute;