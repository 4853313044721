import { useEffect, useReducer, ChangeEvent } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import FormFieldMessage from '../../common/FormFieldMessage';
import NumberBox from '../../common/NumberBox';
import ValidationErrorSvg from '../../../assets/ValidationError.svg';
import './RangeAnswer.css';

export type RangeAnswerProps = {
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  min?: number;
  max: number;
  step: number;
  placeholderTextMin: string;
  placeholderTextMax: string;
  subText: string;
  formatAsCurrency?: boolean;
  inputWidth: number;
};

type RangeTupleAction = {
  isLowerValue: boolean;
  value: number | undefined;
};

const RangeAnswer = ({ form: { touched, errors, setFieldValue }, field, min = 0, max, step, placeholderTextMin, placeholderTextMax, subText, formatAsCurrency, inputWidth }: RangeAnswerProps) => {
  const reducer = (range: [number | undefined, number | undefined], action: RangeTupleAction) => {
    const newRange: [number | undefined, number | undefined] = [ range[0], range[1] ];
    if (action.isLowerValue)
      newRange[0] = action.value;
    else
      newRange[1] = action.value;

    return newRange;
  };

  const getRangeTuple = () => {
    const answers = field.value;
    const range: [number, number] = [min, max];

    if (answers && answers.length === 2) {
      range[0] = answers[0];
      range[1] = answers[1];
    }

    return range;
  };

  const [ rangeTuple, rangeTupleDispatch ] = useReducer(reducer, getRangeTuple());

  useEffect(() => {
    // Update form field value
    const answers = Array<number>();
    if (rangeTuple[0] === undefined || rangeTuple[1] === undefined)
      setFieldValue(field.name, []);
    else {
      answers.push(rangeTuple[0]);
      answers.push(rangeTuple[1]);
      setFieldValue(field.name, answers);
    }
  }, [rangeTuple, field.name, setFieldValue]);

  const handleLowerValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value === '' ? undefined : +e.currentTarget.value;
    rangeTupleDispatch({ isLowerValue: true, value: newValue });
  };

  const handleUpperValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value === '' ? undefined : +e.currentTarget.value;
    rangeTupleDispatch({ isLowerValue: false, value: newValue });
  };

  const isInvalid = () => touched[field.name] && errors[field.name];

  return (
    <>
      <div className='range-answer-values'>
        <div style={{ width: `${inputWidth}px` }}>
          <NumberBox
            key='Min Income'
            className='integer-answer'
            placeholder={placeholderTextMin}
            monetary={formatAsCurrency}
            min={min}
            max={max}
            step={step}
            defaultValue={rangeTuple[0]}
            onChange={handleLowerValueChange}
          />
          <span>&nbsp;</span>
        </div>
        <div style={{ width: `${inputWidth}px` }}>
          <NumberBox
            key='Max Income'
            className='integer-answer'
            placeholder={placeholderTextMax}
            monetary={formatAsCurrency}
            min={min}
            max={max}
            step={step}
            defaultValue={rangeTuple[1]}
            onChange={handleUpperValueChange}
          />
          <span>{subText}</span>
        </div>
      </div>
      {
        isInvalid() && 
          <div className='question-answer-validation'>
            <img src={ValidationErrorSvg} alt='Validation' />
            <FormFieldMessage text={errors[field.name]} />
          </div>
      }
    </>
  );
};

export default RangeAnswer;