import { useEffect } from 'react';
import { ReactComponent as MegaphoneSvg } from '../../assets/Megaphone.svg';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import Button, { ButtonTheme } from '../common/Button';
import NavbarLayout from '../layouts/NavbarLayout';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { useHistory } from 'react-router-dom';
import './Welcome.css';

const Welcome = () => {
  const { zenDeskVisible, toggleZenDesk } = useTruckerFyApp();
  const history = useHistory();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Welcome';

    return () => { document.title = prevTitle; };
  }, []);
  
  const handleContactUs = () => {
    if (!zenDeskVisible) toggleZenDesk();
  };

  return (
    <NavbarLayout>
      <header className='welcome-header'>
        <Navbar>
          <NavLogo><TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} /></NavLogo> 
          <NavEnd><Button type='button' theme={ButtonTheme.White} onClick={handleContactUs}>Contact us</Button></NavEnd>       
        </Navbar>
      </header>
      <main className='welcome-main'>
        <MegaphoneSvg style={{ width: '134px', height: '134px' }}/>
        <p className='fw-5 fs-24 ls-2'>Welcome!</p>
        <p className='fw-3 fs-16 ls-2'>You've set up your account! All your information can be edited later.</p>
        <p className='fw-3 fs-16 ls-2'>Let's create a job post now!</p>
        <span>
          <br/>
          <Button type='button' theme={ButtonTheme.White} onClick={() => history.push('/dashboard') }>Go to Dashboard</Button>
          <Button style={{ marginLeft: '20px' }} type='button' onClick={() => history.push('/editJob') }>Create Job</Button>
        </span>
      </main>
    </NavbarLayout>
  );
};

export default Welcome;