import { useState, useEffect, MouseEvent } from 'react';
import { FastFieldProps } from 'formik';
import FormFieldMessage from '../../common/FormFieldMessage';
import StateButton from '../../common/StateButton';
import ValidationErrorSvg from '../../../assets/ValidationError.svg';

export interface IBooleanAnswerProps extends FastFieldProps {
  templateJson: any;
};

const BooleanAnswer = ({ form: { touched, errors, setFieldValue }, field, templateJson }: IBooleanAnswerProps) => {
  const [ isYes, setIsYes ] = useState<boolean>(field.value);

  useEffect(() => setFieldValue(field.name, isYes), [setFieldValue, field.name, isYes]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;
    const key = target.getAttribute('data-index');
  
    if (key)
      setIsYes(key === 'yes');
  };

  const isSelected = (key: string) => (key === 'yes' && isYes === true) || (key === 'no' && isYes === false);
  const isInvalid = () => touched[field.name] && errors[field.name];

  return (
    <>
      {
        Object.entries(templateJson).map(([key, value]) =>
          <StateButton key={key} data-index={key} selected={isSelected(key)} onClick={handleClick}>{value as string}</StateButton>
        )      
      }
      {
        isInvalid() && 
          <div className='question-answer-validation'>
            <img src={ValidationErrorSvg} alt='Validation' />
            <FormFieldMessage text={errors[field.name]} />
          </div>
      }
    </>
  );
};

export default BooleanAnswer;