import React from 'react';

function Account() {
  return (
    <div>
        <span>Account</span>
    </div>
  );
}

export default Account;
