import { useRef, useState, ChangeEvent, DragEvent, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom';
import { FieldInputProps, FormikProps } from 'formik';
import { ReactComponent as UploadSvg } from '../../assets/vector-icons/eva-cloud-upload-outline.svg'
import { ReactComponent as SearchSvg } from '../../assets/Search.svg'
import { CarrierLogo } from '../specialized/cloudinary/CarrierLogo';
import { PersonAvatar } from '../specialized/cloudinary/PersonAvatar';
import { BannerImage } from '../specialized/cloudinary/BannerImage';
import { useLazyQuery } from '@apollo/client';
import { getErrorMessage, Queries } from '../../util/Graphql';
import { UNAUTHENTICATED } from '../../Typings';
import Button, { ButtonTheme } from './Button';
import FormFieldMessage from './FormFieldMessage';
import ProgressBar from './ProgressBar';

import './Upload.css';

export enum UploadType {
  Avatar,
  Logo, 
  Banner
};

export type UploadProps = {
  type: UploadType;
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
};

const Upload = ({ type, field, form: { touched, errors, setFieldValue }, ...props }: UploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [ hideProgressBar, setHideProgressBar ] = useState(true);
  const [ progress, setProgress ] = useState(0);
  const [ isDragOver, setIsDragOver ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState<string>();
  const history = useHistory();

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (fileInputRef.current)
      fileInputRef.current.click();
    e.preventDefault();
  };

  const uploadPreset = type === UploadType.Logo 
    ? `${process.env.REACT_APP_CLOUDINARY_LOGO_UPLOAD_PRESET}` 
    : type === UploadType.Avatar  
      ? `${process.env.REACT_APP_CLOUDINARY_AVATAR_UPLOAD_PRESET}`
      : `${process.env.REACT_APP_CLOUDINARY_BANNER_UPLOAD_PRESET}`;
  const tags = 'carrier_app_upload';

  const [ getCloudinarySignatureFunction ] = useLazyQuery(
      Queries.GET_CLOUDINARY_SIGNATURE, 
      { 
        variables: { upload_preset: uploadPreset, tags },
        fetchPolicy: 'no-cache', 
        onError(e) {
          const errorMsg = getErrorMessage(e);
          if (errorMsg === UNAUTHENTICATED) {
            history.push('/login', { isAuthExpired: true });
          } else {
            history.push('/oops', { error: errorMsg });
          }
        }
      }
    );

  const uploadFile = async (file: File) => {

    const url = `${process.env.REACT_APP_CLOUDINARY_API}/${process.env.REACT_APP_CLOUDINARY_CLOUD}/upload`;

    const result = await getCloudinarySignatureFunction();
    const { signature, timestamp } = result.data?.signCloudinary;

    const xhr = new XMLHttpRequest();

    return new Promise<[boolean, string]>((resolve, reject) => {
      
      xhr.open('POST', url, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      
      xhr.upload.addEventListener('progress', e => {
        const progress = Math.round((e.loaded * 100.0) / e.total);
        setProgress(progress);
      });

      xhr.onreadystatechange = (e) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          const cloudinaryUrl = response.public_id;
          setProgress(100);          
          resolve([ true, cloudinaryUrl ]);
        } else if (xhr.status >= 400) {
          // Some kind of non-network error happened
          const errorMsg = xhr.responseText.length 
            ? `Cloudinary Upload Error: ${JSON.parse(xhr.response)?.error?.message}, status: ${xhr.status}`
            : xhr.statusText;
          console.error(errorMsg);
          setErrorMessage(errorMsg);
          reject([ false, xhr.responseText || errorMsg ]);
        }
      };

      xhr.onerror = (e) => {
        // Handles network errors only
        const errorMsg = xhr.responseText || 'Unable to upload image due to a network issue.';
        console.error(errorMsg);
        setErrorMessage(errorMsg);
        reject([ false, errorMsg ]);
      }
        
      const fd = new FormData();
      fd.append('file', file);
      fd.append('api_key', `${process.env.REACT_APP_CLOUDINARY_API_KEY}`); // This is not a secret, it's basically an account id
      fd.append('tags', 'carrier_app_upload'); // Optional - add tag for image admin in Cloudinary
      fd.append('upload_preset', uploadPreset);
      fd.append('timestamp', timestamp);
      fd.append('signature', signature);
      setProgress(0);          
      xhr.send(fd);
    });
  };

  const processFile = async (file: File) => {
    const fileExtensions = [ 'BMP', 'JPEG', 'JPG', 'PNG', 'WEBP' ]; //, 'GIF', 'TIFF', 'ICO', 'HEIC', 'EPS', 'PSD', 'SVG', 'JXR', 'WDP' ];
    const hasGoodExtension = fileExtensions.some(fe => file.name.toUpperCase().endsWith(fe));
    if (hasGoodExtension) {
      setProgress(0);
      setHideProgressBar(false);
      setErrorMessage(undefined);
      try {
        const result = await uploadFile(file);

        if (result[0]) {
          setFieldValue(field.name, result[1]);
          setProgress(100);
        } else {
          setErrorMessage(result[1]);
        }
      }
      catch (result: any) {
        setErrorMessage(result[1]);
      }
    } else {
      setErrorMessage('Please select another image.  Acceptable types are BMP, JPEG, JPG, PNG, WEBP.'); // , GIF, TIFF, ICO, HEIC, EPS, PSD, SVG, JXR, and WDP.');
    }
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    if (target.files && target.files.length) {
      const file = target.files[0];
      await processFile(file);
    }
  };

  const getUploadClassName = () => `upload${isDragOver ? ' drag-over' : ''}`;
  const getProgressBarClassName = () => `${hideProgressBar ? 'progress-bar-hidden' : ''}`;

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    setIsDragOver(false);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);

    if (e.dataTransfer.files.length === 1) {
      const file = e.dataTransfer.files[0];
      await processFile(file);
    }
  };


  return (
    <section>
      <div className={getUploadClassName()} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        <div>
          {type === UploadType.Logo && field.value && <CarrierLogo size={64} publicId={field.value} />}
          {type === UploadType.Avatar && field.value && <PersonAvatar size={64} publicId={field.value} />}
          {type === UploadType.Banner && field.value && <BannerImage width={384} publicId={field.value} />}
          {!field.value && <UploadSvg />}
        </div>
        <span className='off-black fw-5 ls-2'>Drop File Here</span>
        <span className='off-black fw-3 ls-1'>({type === UploadType.Banner ? 'wide' : 'square'} images work best)</span>
        <input
          ref={fileInputRef}
          type='file'
          onChange={handleInputChange}
        />
        { !errorMessage && <ProgressBar progress={progress} className={getProgressBarClassName()} /> }
        { errorMessage && <FormFieldMessage text={errorMessage} /> }
        <div><Button theme={ButtonTheme.White} onClick={handleButtonClick}><SearchSvg />&nbsp;&nbsp;Choose File</Button></div>
      </div>
    </section>
  );
};

export default Upload;