import { useState, useEffect, useReducer, useCallback, MouseEvent, Fragment, TransitionEvent } from 'react';
import { ReactComponent as CaretDown } from '../../assets/CaretDown.svg'
import './Accordion.css';

type SelectedItem = {
  tag: string,
  sectionIndex: number,
  childIndex?: number
};

type AccordionSection = {
  preElement: JSX.Element;
  title: string;
  tag: string;
  children: Array<[string, string, string, string]>;
};

type AccordionProps = {
  sections: Array<AccordionSection>;
  selectedSectionIndex?: number;
  selectedItem?: SelectedItem;
  onItemSelect: (item: SelectedItem) => void;
};

type AccordionSectionExpandedAction = {
  index: number;
  value: boolean;
};

const Accordion = ({ sections, selectedSectionIndex, selectedItem, onItemSelect }: AccordionProps) => {
  const [ selectedItemInternal, setSelectedItemInternal ] = useState<SelectedItem | undefined>();

  const reducer = (selection: Array<boolean>, action: AccordionSectionExpandedAction) => {
    const newSelection: Array<boolean> = [...selection];
    newSelection[action.index] = action.value;

    return newSelection;
  };

  const getSectionExpanded = () => {
      const selections = new Array(sections.length).fill(false);
      selections[0] = true; // Initially auto-expand the Jobs section
      return selections;
  };

  const [ sectionExpanded, sectionExpandedDispatch ] = useReducer(reducer, getSectionExpanded());

  const getContentClass = (index: number) => `accordion-content${sectionExpanded[index] ? '' : ' content-closed'} hide-scrollbar`;

  const getContentSectionClass = (parentIndex: number, childIndex: number) => {
    const isSelected = selectedItemInternal?.sectionIndex === parentIndex && selectedItemInternal?.childIndex === childIndex;
    return `${isSelected ? ' section-selected' : ''}`;
  };

  const handleSectionClick = (e: MouseEvent<HTMLDivElement>) => {
    const dataIndex = e.currentTarget.getAttribute('data-index');
    if (dataIndex) {
      const values = dataIndex.split('|');
      const tag = values[0];
      const parentIndex = +values[1];
      const childIndex = values[2] === '' ? undefined : +values[2];
      const selection = { tag: tag, sectionIndex: parentIndex, childIndex: childIndex };
      setSelectedItemInternal(selection);
      onItemSelect(selection);
    }
  };

  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (sectionExpanded[0]) {
      target.classList.remove('hide-scrollbar')
      target.classList.add('show-scrollbar')
    } else {
      target.classList.remove('show-scrollbar')
      target.classList.add('hide-scrollbar')
    }
  };

  const composeContent = (tag: string, parentIndex: number, children: Array<[string, string, string, string]>) => {
      return (
        <div key={`content-div-container-${parentIndex}`} onTransitionEnd={handleTransitionEnd} className={getContentClass(parentIndex)}>
          {
            children.map((child, childIndex) =>
              <div key={`content-div-${childIndex}`} className={getContentSectionClass(parentIndex, childIndex)} data-index={`${tag}|${parentIndex}|${childIndex}`} onClick={handleSectionClick}>
                <span className='accordion-dot' style={{backgroundColor: child[1]}} ></span>
                <span>{child[0]}</span>
              </div>
            )
          }
        </div>
      );
  };

  const composeAccordionButton = (sectionIndex: number, section: AccordionSection) => {
    if (section.children.length > 0)
      return (
        <button className='accordion-button' data-index={sectionIndex} onClick={handleButtonClick}>
          <CaretDown className={getSvgClass(sectionIndex)} />
        </button>
      );
  };

  const handleButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const indexStr = e.currentTarget.getAttribute('data-index');
    if (indexStr !== null) {
      const index = +indexStr;
      sectionExpandedDispatch({ index: index, value: !sectionExpanded[index] });
    }
    e.stopPropagation();
  }, [sectionExpanded]);

  const getSectionClass = (index: number) => {
    const isSelected = selectedItemInternal?.sectionIndex === index && selectedItemInternal?.childIndex === undefined;
    return `accordion-section${isSelected ? ' section-selected' : ''}`;
  };

  const getSvgClass = (index: number) => `accordion-button-caret-${sectionExpanded[index] ? 'up' : 'down'}`;

  useEffect(() => {
    if (sections.length && selectedSectionIndex !== undefined) {
      const section = sections[selectedSectionIndex];
      const selection = { tag: section.tag, sectionIndex: selectedSectionIndex };
      setSelectedItemInternal(selection);
    }
}, [sections, selectedSectionIndex]);

  useEffect(() => {
    setSelectedItemInternal(selectedItem);
  }, [selectedItem]);

  return (
    <div className='accordion show-scrollbar'>
      {
        sections.map((section, index) => (
            <Fragment key={`fragment-${index}`}>  
              <div tabIndex={0} className={getSectionClass(index)} data-index={`${section.tag}|${index}|`} onClick={handleSectionClick}>
                {section.preElement}
                <div>
                  <span>{section.title}</span>
                </div>
                {composeAccordionButton(index, section)}
              </div>
              {composeContent(section.tag, index, section.children)}           
            </Fragment>
          )
        )
      }
      <div style={{ flex: '1' }} />
    </div>
  );
};

export default Accordion;

export type { SelectedItem, AccordionSection, AccordionProps };