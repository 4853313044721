import './App.css';

import AppRoutes from './components/routes/AppRoutes';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { TruckerFyAppProvider } from './context/TruckerFyAppContext';

const App = () => {
  return (
    <Router>
      <HelmetProvider>
        <TruckerFyAppProvider>
          <AppRoutes />
        </TruckerFyAppProvider>
      </HelmetProvider>
    </Router>
  );
};

export default App;
