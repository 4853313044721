import { ReactComponent as Megaphone } from '../../assets/Megaphone.svg';
import { useModal } from '../../context/ModalContext';
import Button from '../common/Button';
import './Announcement.css';

type AnnouncementProps = {
  announcementMessage: string,
  handleAnnouncementContinue?: () => void
};

const Announcement = ({ announcementMessage, handleAnnouncementContinue = undefined }: AnnouncementProps) => {
  const { destroy } = useModal();
  const handleContinueClick = () => { handleAnnouncementContinue ? handleAnnouncementContinue() : destroy() };
  
  return (
    <section className='modal-announcement'>
      <Megaphone />
      <span>{announcementMessage}</span>
      <Button onClick={handleContinueClick}>CONTINUE</Button>
    </section>
  );
}

export default Announcement;