import React, { createContext, useContext, useCallback, useEffect, useState, ReactNode, MouseEvent } from 'react';
import { ReactComponent as CloseSvg } from '../assets/Close.svg';
import './Modal.css';

type ModalAction = {
  destroy(): void;
  compose(node: ReactNode): void;
};

export enum ModalButton {
  Back,
  Close
};

export type ModalButtonEvent = {
  button: ModalButton
};

export interface IModalSubscriber {
  isModalSubscriber?: boolean;
  showBackButton?: (display: boolean) => void;
  buttonClick?: ModalButtonEvent;
  preserveOnClose?: boolean;
};

const ModalContext = createContext<ModalAction>({} as ModalAction);

const Modal = ({ content, destroy }: any) => {
  const [ showBackButton, setShowBackButton ] = useState(false);
  const [ buttonClick, setButtonClick ] = useState<ModalButtonEvent | undefined>();

  useEffect(() => {
    const bind = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) return;

      if (!isSubscriber(content.props) || !!!content.props.preserveOnClose)
        destroy();
    };

    document.addEventListener('keyup', bind);
    return () => document.removeEventListener('keyup', bind);
  }, [content.props, destroy]);

  const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement>) => e.preventDefault(), []);

  const handleBackButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => setButtonClick({ button: ModalButton.Back }), []);

  const handleCloseButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setButtonClick({ button: ModalButton.Close });

    if (!isSubscriber(content.props) || !!!content.props.preserveOnClose)
      destroy();
  }, [content.props, destroy]);

  const isSubscriber = (props: IModalSubscriber): props is IModalSubscriber => (props as IModalSubscriber).isModalSubscriber ?? false;

  const composeBackButton = () => {
    if (showBackButton && isSubscriber(content.props))
      return (
        <button className='modal-back-btn' onClick={handleBackButtonClick} onMouseDown={handleMouseDown}>
          <svg width='7' height='12' viewBox='0 0 7 12' aria-hidden='true' focusable='false'>
            <path d='M6 11L1 6L6 1' stroke='#979797' strokeWidth='2.6' strokeLinecap='round' strokeLinejoin='round'/>
          </svg>
        </button>
      );
  };

  const handleShowBackButton = useCallback((display: boolean) => setShowBackButton(display), []);

  const composeContent = () => {
    if (isSubscriber(content.props))
      return React.cloneElement(
        content,
        {
          showBackButton: handleShowBackButton,
          buttonClick: buttonClick,
        }
      );

    return content;
  };

  return (
    <div className='modal'>
      <div className='modal-inner'>
        {composeBackButton()}
        <button className='modal-close-btn' onClick={handleCloseButtonClick} onMouseDown={handleMouseDown}>
          <CloseSvg />
        </button>
        {composeContent()}
      </div>
    </div>
  );
};

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [content, setContent] = useState<ReactNode>();
  const destroy = useCallback(() => setContent(undefined), [setContent]);

  return (
    <ModalContext.Provider
      value={{
        destroy,
        compose: (node: ReactNode) => setContent(node)
      }}
    >
      {children}
      {content && <Modal content={content} destroy={destroy} />}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal() must be used within a ModalProvider');
  }

  return context;
};

export { ModalProvider, useModal };
