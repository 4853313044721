import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { ReactComponent as Error404TruckSvg } from '../../assets/Error404Truck.svg';
import Button, { ButtonTheme } from '../common/Button';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import NavbarLayout from '../layouts/NavbarLayout';
import './NotFound.css';

const NotFound = () => { // HTTP 404
  const [ redirect, setRedirect ] = useState(false);
  const { zenDeskVisible, toggleZenDesk } = useTruckerFyApp();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Not Found';

    return () => { document.title = prevTitle; };
  }, []);

  const handleClick = () => {
    setRedirect(true);
  };

  const handleContactUs = () => {
    if (!zenDeskVisible) toggleZenDesk();
  };

  if (redirect)
    return <Redirect push to='/login' />

  return (
    <NavbarLayout>
      <header className='not-found-header'>
        <Navbar>
          <NavLogo>
            <TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} />
          </NavLogo> 
          <NavEnd>
            <Button theme={ButtonTheme.White} onClick={handleContactUs}>
              Contact us
            </Button>
          </NavEnd>       
        </Navbar>
      </header>
      <main className='not-found-main fw-3'>
        <div>
          <p className='fs-38 ls-4' style={{ lineHeight: '150%' }}>
            Page not found!
          </p>
          <p className='fs-16 ls-2'>
            The page you're looking for does not exist.
          </p>
          <br/>
          <Button onClick={handleClick}>Back to Homepage</Button>
        </div>
        <Error404TruckSvg />
      </main>
    </NavbarLayout>
  );
};

export default NotFound;