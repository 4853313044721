import React, { useEffect, useState, useRef, ReactNode, TextareaHTMLAttributes, CSSProperties } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import FormFieldMessage from './FormFieldMessage';
import ValidationError from '../../assets/ValidationError.svg';
import './Textarea.css';

enum Glyph {
  Clear,
  Password,
  Validation
}

export interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  suppressValidation?: boolean;
  children?: ReactNode;
};

const Textarea = ({ field, form: { touched, errors, setFieldValue }, suppressValidation = false, ...props }: ITextareaProps) => {
  const [ scrollbarWidth, setScrollbarWidth ] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleClearMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {  
    setFieldValue(field.name, '');
    e.preventDefault();
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const scrollbarWidth = textarea.offsetWidth - textarea.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [field.value]);

  const isInvalid = () => !suppressValidation && touched[field.name] && errors[field.name];
  const getRightPadding = () => {
    let padding = 15;
    if (field.value) padding += 19;
    if (isInvalid()) padding += 24;
    return `${padding}px`;
  };

  const getRightPosition = (glyph: Glyph) => {
    let rightPos = 7 + scrollbarWidth;
  
    if (glyph === Glyph.Clear) {
      if (isInvalid()) rightPos = 32 + scrollbarWidth;
    } else if (glyph === Glyph.Validation) {
      rightPos = 12 + scrollbarWidth;
    }
    return `${rightPos}px`;
  };

  const getClassName = () => `custom-text-area${props.className ? ` ${props.className}` : ''} fw-3`;

  const getStyle = (style: CSSProperties | undefined) => ({
    ...style,
    ...props.style
  });
  
  return (
    <div style={getStyle({ position: 'relative' })}>
      <textarea
        ref={textareaRef}
        {...field}
        {...props}
        className={getClassName()}     
        style={{ paddingRight:getRightPadding(), overflowY: 'auto' }}
      >
         {props.children}
      </textarea>
      { 
        field.value &&
          <i 
            tabIndex={-1}
            className='custom-text-area-clear'
            title='Clear field'
            style={{right:getRightPosition(Glyph.Clear)}}
            onMouseDown={handleClearMouseDown}>&times;
          </i>
      }
      {
        isInvalid() && 
          <>
            <img
              height='8px' 
              width='8px'
              className='custom-text-area-validation'
              title='Please fix the error displayed beneath the field'
              style={{right:getRightPosition(Glyph.Validation)}}
              src={ValidationError} alt='Validation'
            />
            <FormFieldMessage text={errors[field.name]} />
          </>
      }
    </div>
  );
};

export default Textarea;
