import { CdlClassSvg, DaysOutSvg, EndorsementsSvg, IncomeSvg, NoPetsSvg, PetsOkSvg, SoloSvg, TeamSvg, WorkRouteSvg } from '../../../assets/vector-icons'
import { HTMLAttributes, useLayoutEffect, useState } from 'react'
import { CarrierLogo } from '../cloudinary/CarrierLogo'
import Divider from '../../common/Divider'
import { JobLdJson } from './JobLdJson';
import { JobModel } from '../../../Typings'
import ReadOnlyMapAnswer from '../questionnaire/ReadOnlyMapAnswer'
import { timeAgo } from '../../../util/TimeAgo'
import { useJobs } from '../../../context/JobsContext'
import { useQuestionnaire } from '../../../context/QuestionnaireContext';
import { BannerImage } from '../cloudinary/BannerImage';
import './JobPost.css';

export interface IJobPostProps extends HTMLAttributes<HTMLDivElement> {
  job: JobModel;
  hidePersonalData?: boolean;
};

const JobPost = ({ job, hidePersonalData = false }: IJobPostProps) => {
  const questionnaireService = useQuestionnaire();
  const { carrier: jobsCarrier } = useJobs();
  const [ carrier, setCarrier ] = useState<any>();
  const headerQuestionIds = ['cdlClass', 'workRoutes', 'workType', 'endorsements', 'income', 'daysOut', 'pets'];

  useLayoutEffect(() => {
    if (jobsCarrier) {
      setCarrier(jobsCarrier)
    } else if ((job as any).carrier) {
      setCarrier((job as any).carrier)
    }
  }, [jobsCarrier, job]);

  const composeCdlClassAndIncome = () => {
    const cdlClassText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('cdlClass', job.requirements, 'n/a');
    let incomeText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('income', job.requirements, 'n/a');
    incomeText = incomeText === 'n/a' ? incomeText : incomeText + '+/month';
    return (
      <>
        <CdlClassSvg /><span>{cdlClassText}</span>
        <IncomeSvg /><span>{incomeText}</span>
      </>);
  };

  const composeWorkRoutesAndDaysOut = () => {
    const workRoutesText = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextCsv('workRoutes', job.requirements, 'n/a');
    const daysOutText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('daysOut', job.requirements, 'n/a');
    return (
      <>
        <WorkRouteSvg width={22} /><span>{workRoutesText}</span>
        <DaysOutSvg width={22} /><span>{daysOutText}</span>
      </>
    );
  };

  const composeWorkTypeAndPets = () => {
    const workType = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextCsv('workType', job.requirements, 'n/a') ?? '';
    let workTypeText = workType;
    let workTypeIcon: React.ReactNode = <SoloSvg />;
    if (workType?.indexOf('Team') >= 0) {
      workTypeIcon = <TeamSvg />;
    }

    const petText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('pets', job.requirements, 'n/a');
    let petTextResolved = petText;
    let petIcon: React.ReactNode;
    switch (petText) {
      case 'Yes':
        petTextResolved = 'Pets OK';
        petIcon = <PetsOkSvg />;
        break;
      case 'No':
        petTextResolved = 'No Pets';
        petIcon = <NoPetsSvg style={{width: '30px', height: '30px'}} />;
        break;
    }

    return (
      <>
        {workTypeIcon || <div key='workTypeIcon' />}<span>{workTypeText}</span>
        {petIcon || <div key='petIcon' />}<span>{petTextResolved}</span>
      </>);
  };

  const composeHeader = () => (
    <div className={`job-post-header${hidePersonalData ? ' no-auth' : ''}`}>
      <BannerImage publicId={carrier?.bannerPhoto} className='job-post-header-carrier-banner' />
      <div className='job-post-header-banner-match'>
        {!carrier?.bannerPhoto && <CarrierLogo size={91} publicId={carrier?.photoUrl} className='job-post-header-carrier-logo' />}
        {/* {
          !hidePersonalData &&
            <div key='match_div' className='job-post-header-match-container'>
              <MatchRibbon matchScore={{score: 100, color: 'success', label: 'Great Match'}} />
            </div>
        } */}
      </div>
      <div key='tile_div' className='job-post-header-text fw-4 fs-14'>
        <p key='jobTitle_p' className='fw-b fs-18'>{job?.title}</p>
        <p key='carrierName_p' className='fw-5 fs-16'>{carrier?.name}</p>
        <span className='ls-4' style={{ color: 'var(--medium-gray)', float: 'right'}}>{job.publishedAt ? timeAgo(job.publishedAt) : ''}</span>
        <span key='carrierDot_s'>DOT#: {carrier?.dotNumber}</span>
        {/* {
          (carrier?.city || carrier?.state) && 
            <p key='carrierCityState_p' style={{ color: 'var(--medium-gray)' }}>
              <i>{`${carrier?.city ?? ''}${carrier?.state ? `${(carrier?.city && carrier?.state) ? ', ' : ''} ${carrier?.state}` : ''}`}</i>
            </p>
        } */}
      </div>
      {/* {
        !hidePersonalData &&
          <div key='introduceYourself-div' className='job-post-header-btn-svg-container'>
            <div>
              <Button className='job-post-header-btn-svg-container-btn' tabIndex={-1}>Introduce Yourself</Button>
            </div>
            <TruckFastOutlineSvg />
          </div>
      }  */}
    </div>
  );

  const composeEndorsements = () => {
    let endorsementsText = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextCsv('endorsements', job.requirements, 'n/a');
    return (
      <>
        <EndorsementsSvg style={{ width: '26px', height: '26px' }} />
        <span style={{ gridColumn: 'span 3' }}>{endorsementsText}</span>
      </>
    );
  };

  const composeJobQuestionsAndAnswers = () => {
    return questionnaireService.jobQuestionnaire?.questions
      .filter((q:any) => headerQuestionIds.indexOf(q.id) < 0)
      .map((question, index) => {
        const elements = Array<JSX.Element>();
        let element: JSX.Element;
        let answers: string = '';

        elements.push(<p key={`question${index}_p`} className='off-black fw-b fs-15' style={{ marginTop: '20px', marginBottom: '5px' }}>{question.title}</p>);
        if (question.type === 'map') {
          const answersArray = questionnaireService.jobQuestionnaire?.getMultipleAnswerIdArray(question.id, job.requirements) ?? [];
          element = <ReadOnlyMapAnswer key={`question${index}_map`} className='job-post-map-answer' answers={answersArray} choicesJson={question.choices} />
          elements.push(element);
        } else {
          switch (question.type) {
            case 'boolean':
            case 'single':
              answers = questionnaireService.jobQuestionnaire?.getSingleAnswerText(question.id, job.requirements) ?? '';
              break;
            case 'range':
              const answerArray = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextArray(question.id, job.requirements);
              answers = answerArray?.length ? '$' + answerArray[0] + '+/month' : 'n/a';
              break;   
            case 'multiple':
              answers = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextCsv(question.id, job.requirements) ?? '';
              break;
          }
          
          element = (
            <div key={`question${index}_div`} className='off-black fs-14'>
              {answers}
            </div>
          );
          elements.push(element);
        }

        return (
          <div key={`questionsAndAnswers${index}_div`}>
            {elements.map(e => e)}
          </div>
        );
      });
  };

  const composePrequalifications = () => {
    const maxJobs = +(questionnaireService.jobQuestionnaire?.getSingleAnswerText('previousJobs', job.requirements) || 0);
    const allowMovingViolations = questionnaireService.jobQuestionnaire?.getBooleanAnswer('violations', job.requirements);
    const allowPreventableAccidents = questionnaireService.jobQuestionnaire?.getBooleanAnswer('preventable', job.requirements);
    const allowNonPreventableAccidents = questionnaireService.jobQuestionnaire?.getBooleanAnswer('nonPreventable', job.requirements);
    const allowDrugTest = questionnaireService.jobQuestionnaire?.getBooleanAnswer('drugTest', job.requirements);
    const allowSuspensions = questionnaireService.jobQuestionnaire?.getBooleanAnswer('suspensions', job.requirements);
    const allowConvictions = questionnaireService.jobQuestionnaire?.getBooleanAnswer('convictions', job.requirements);
    const allowDui = questionnaireService.jobQuestionnaire?.getBooleanAnswer('dui', job.requirements);

    if (maxJobs
      || !allowMovingViolations
      || !allowPreventableAccidents
      || !allowNonPreventableAccidents
      || !allowDrugTest
      || !allowSuspensions
      || !allowConvictions
      || !allowDui)
    return (
      <div key='prequalifications_div' className='off-black fs-14' style={{ marginTop: '10px' }}>
        <p className='fw-5 fs-15 fw-b' style={{ marginTop: '20px' }}>Prequalifications</p>
        <p style={{ marginBottom: '0px' }}>Carrier will only consider candidates who:</p>
        <ul style={{ lineHeight: '20px', paddingLeft: '15px', marginTop: '6px' }}>
          {maxJobs > 0 && <li>have had <strong>{maxJobs}</strong> job{maxJobs > 1 ? 's' : ''} or less in the past 3 years.</li>}
          {!allowMovingViolations && <li>have <strong>not</strong> had a moving violation in the past 3 years.</li>}
          {!allowPreventableAccidents && <li>have <strong>not</strong> had a preventable accident in the past 3 years.</li>}
          {!allowNonPreventableAccidents && <li>have <strong>not</strong> had a non-preventable accident in the past 3 years.</li>}
          {!allowDrugTest && <li>have <strong>not</strong> refused a drug test in the past 3 years.</li>}
          {!allowSuspensions && <li>have <strong>not</strong> had their license suspended in the past 3 years.</li>}
          {!allowConvictions && <li>have <strong>no</strong> criminal convictions in the past 5 years.</li>}
          {!allowDui && <li>have <strong>not</strong> had a DUI in the past 5 years.</li>}
        </ul>
      </div>
    );
  };

  return (
    <>
      <JobLdJson carrier={carrier} job={job} />
      {composeHeader()}
      <Divider className='job-post-divider' style={{ marginTop: '20px' }} />
      <p key='jobDetails_p' className='off-black fw-b fs-18' style={{ marginTop: '20px', marginRight: '25px' }}>Job details</p>
      <div key='jobDetails_div' className='job-post-grid fs-14'>
        {composeCdlClassAndIncome()}
        {composeWorkRoutesAndDaysOut()}
        {composeWorkTypeAndPets()}
        {composeEndorsements()}
      </div>
      <Divider className='job-post-divider' style={{ marginTop: '20px' }} />
      <p key='jobDescription_p' className='off-black fw-b fs-18' style={{ marginTop: '20px' }}>Job description</p>
      <div key='jobDescription_div' className='job-post-answer off-black fs-14'>
        {job.description}
      </div>
      <p key='aboutTheCompany_p' className='fw-b fs-18' style={{ marginTop: '10px' }}>About the company</p>
      <div key='carrierDescription_div' className='job-post-answer'>
        {carrier?.description}
      </div>
      <p key='moreAboutThisJob_p' className='fw-b fs-18' style={{ marginTop: '10px' }}>More About this Job</p>
      <p key='companySize_p' className='fw-b fs-15' style={{ marginTop: '20px', marginBottom: '5px' }}>Company Size</p>
      <div key='companySize_div' className='fs-14'>
        {questionnaireService.carrierQuestionnaire?.getSingleAnswerText('companySize', carrier?.profile) ?? ''}
      </div>
      {composeJobQuestionsAndAnswers()}
      {composePrequalifications()}
    </>
  );
};

export default JobPost;
