// handle non-JSON responses from server
// https://github.com/apollographql/apollo-feature-requests/issues/153#issuecomment-476832408
export const customFetch = async (url: string, options: any) => {
  const optionsObj: any = {...options};
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const userInfoStr = localStorage.getItem('userInfo');
  const userInfo = userInfoStr === null ? null : JSON.parse(userInfoStr);

  if (refreshToken) optionsObj.headers['x-refresh-token'] = refreshToken;
  else if (accessToken) optionsObj.headers['x-access-token'] = accessToken;

  if (userInfo?.carrierId) optionsObj.headers["x-carrier-id"] = userInfo.carrierId;

  const response = await fetch(url, {...optionsObj});

  if (response.headers.has('x-refresh-token')) {
    const refreshToken = response.headers.get('x-refresh-token');
    if (refreshToken) {
      const refreshTokenJson = JSON.parse(atob(refreshToken.split('.')[1]));
      const expiresAt = new Date(refreshTokenJson.exp * 1000);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('expiresAt', expiresAt.toString());
    }
  }

  return response.status >= 500 ? Promise.reject(response.status) : response;
};