import React, { createContext, useContext, useState } from 'react';
import { useApolloClient } from "@apollo/client";
import ReactGA from 'react-ga4';
import { UserInfo } from '../Typings';

interface AuthInfo {
  accessToken: string | null
  refreshToken: string | null
  expiresAt: Date | null
  userInfo: UserInfo | null
}

interface Auth {
  getUserInfo(): UserInfo | null;
  login(info: AuthInfo, persist: boolean): void;
  logout(suppressAuthStateChange?: boolean): void;
  resetAuthState(suppressAuthStateChange?: boolean): void;
  hasExpiredToken(): boolean;
  isAdmin(): boolean;
  isUser(): boolean;
  setUserInfo(userInfo: UserInfo): void;
  authStateChange: boolean;
}

const AuthContext = createContext<Auth>({} as Auth);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const client = useApolloClient();

  const setAuth = ({ accessToken, refreshToken, expiresAt, userInfo }: AuthInfo, persist: boolean) => {
    
    // TODO: we're doing absolutely nothing with the value of persist here...
    
    if (expiresAt) {
      expiresAt.setMinutes(expiresAt.getMinutes() - 1 /* Slop for network delays */);
    }
  
    localStorage.setItem('accessToken', accessToken as string);
    localStorage.setItem('refreshToken', refreshToken as string);
    localStorage.setItem('expiresAt', expiresAt === null ? '' : expiresAt.toString());
    localStorage.setItem('userInfo', JSON.stringify(userInfo));

    setAuthStateChange(previous => !previous);
  };

  const [ authStateChange, setAuthStateChange ] = useState(false);

  const resetAuthState = (suppressAuthStateChange = false) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('userInfo');

    if (!suppressAuthStateChange)
      setAuthStateChange(previous => !previous);
  };

  const logout = (suppressAuthStateChange = false) => {
    resetAuthState(suppressAuthStateChange);
    ReactGA.event({
      category: 'meta',
      action: 'logout',
      label: 'true', // id unique to current page load
      value: 1, // values must be integers
      nonInteraction: true, // avoids affecting bounce rate
      transport: 'xhr'
    });
  };

  const hasValidToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    const expiresAtStr = localStorage.getItem('expiresAt');
    const expiresAt = expiresAtStr === null ? null : new Date(expiresAtStr);

    if (!accessToken || !expiresAt) {
      return false;
    }

    return Date.now() <= expiresAt.getTime();
  };

  const hasExpiredToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    const expiresAtStr = localStorage.getItem('expiresAt');
    const expiresAt = expiresAtStr === null ? null : new Date(expiresAtStr);

    if (accessToken && expiresAt && (Date.now() > expiresAt.getTime())) {
      return true;
    }

    return false;
  };

  const getUserInfo = () => {
    const userInfoStr = localStorage.getItem('userInfo');
    return userInfoStr === null ? null : JSON.parse(userInfoStr);
  };

  const setUserInfo = (userInfo: UserInfo) => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  };

  const isAdmin = () => hasValidToken() && getUserInfo()?.roles.some((item: String) => item === 'ADMIN');
  
  const isUser = () => hasValidToken() && getUserInfo() !== null;
  
  const login = (authInfo: AuthInfo, persist: boolean) => {
    setAuth(authInfo, persist);

    ReactGA.event({
      category: 'meta',
      action: 'login',
      label: 'true', // id unique to current page load
      value: 1, // values must be integers
      nonInteraction: true, // avoids affecting bounce rate
      transport: 'xhr'
    });

    client.resetStore();
  };

  return (
    <AuthContext.Provider
      value={{
        getUserInfo,
        login,
        logout,
        resetAuthState,
        hasExpiredToken,
        isAdmin,
        isUser,
        setUserInfo,
        authStateChange
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
export type { AuthInfo, UserInfo };