import './TruckerFyLogo.css';

import { HTMLAttributes, MouseEvent } from 'react';

import { ReactComponent as BlackLogo } from '../../assets/TruckerFy-black.svg';
import { ReactComponent as WhiteLogo } from '../../assets/TruckerFy-white.svg';
import { useHistory } from 'react-router-dom';

export enum TruckerFyLogoTheme {
  OnWhite,
  OnBlack
};

export interface ITruckerFyLogoProps extends HTMLAttributes<HTMLDivElement> {
  theme?: TruckerFyLogoTheme;
  className?: string;
  isNotLink?: boolean;
  linkAddress?: string;
}

const TruckerFyLogo = ({ theme = TruckerFyLogoTheme.OnBlack, className, isNotLink = false, linkAddress = '/login', ...props }: ITruckerFyLogoProps) => {
  const history = useHistory();

  const handleDivClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!isNotLink)
      history.push(linkAddress);
  };

  return (
    <div 
      {...props}
      className={`truckerfy-logo${className ? ` ${className}` : ''}`}
      onClick={handleDivClick}
      style={{ cursor: isNotLink ? 'default' : 'pointer' }}
    >
      { theme === TruckerFyLogoTheme.OnBlack ? <WhiteLogo /> : <BlackLogo /> }
    </div>
  );
};

export default TruckerFyLogo;
