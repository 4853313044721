import { useState, HTMLAttributes, useLayoutEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useJobs } from '../../../context/JobsContext';
import { JobTab } from '../../../Typings';
import Tabbar from '../../common/Tabbar';
import TabbarLayout from '../../layouts/TabbarLayout';
import JobCandidates from './JobCandidates';
import JobConversations from './JobConversations';
import JobOverview from './JobOverview';
import './JobView.css';

interface IJobViewProps extends HTMLAttributes<HTMLDivElement> {
  children?: never;
};

const JobView = ({ ...props }: IJobViewProps) => {
  const { activeJob, refresh: refreshJobs } = useJobs();
  const [ activeTabIndex, setActiveTabIndex ] = useState(0);
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const { tabName: tabNameParam } = useParams<{ tabName: string | undefined }>();
  const { conversationId: conversationIdParam } = useParams<{ conversationId: string | undefined }>();
  const history = useHistory();
  
  useLayoutEffect(() => {
    if (conversationIdParam)
      setActiveTabIndex(2 /* Conversations */);
    else if (tabNameParam) {
      const jobTabName = `${tabNameParam[0].toUpperCase()}${tabNameParam.slice(1).toLowerCase()}`;    
      const jobTab = jobTabName as keyof typeof JobTab;
      const tab = JobTab[jobTab];

      if (tab !== undefined)
        setActiveTabIndex(tab);
      else
        history.push('/oops', { error: 'Invalid parameters passed to job page.' });
    } else {
      setActiveTabIndex(0 /* Overview */);
    }
  }, [tabNameParam, conversationIdParam, history]);
  
  const getTabsMeta = () =>
    Object.keys(JobTab).filter((key: any) => !isNaN(Number(JobTab[key]))).map(tab => ({ caption: tab }));

  const handleActiveTabChange = (index: number) => {
    if (index === 0) refreshJobs();
    history.push(`/dashboard/job/${jobIdParam}/${JobTab[index].toLowerCase()}`)
  };

  const composeActiveTab = () => {
    if (activeJob)
      switch (activeTabIndex) {
        case 0:
          return <JobOverview job={activeJob} setParentTabIndex={handleActiveTabChange} />
        case 1:
          return <JobCandidates job={activeJob} />
        default:
          return <JobConversations job={activeJob} />
      }
  };

  return (
    <TabbarLayout>
      <header>
        <Tabbar
          tabsMeta={getTabsMeta()}
          activeClassName='tab-active'
          activeTabIndex={activeTabIndex}
          onActiveTabChange={handleActiveTabChange}
          {...props}
        />
      </header>
      {composeActiveTab()}
    </TabbarLayout>
  );
};

export default JobView;