import { HTMLAttributes } from 'react';
import { MatchScore } from '../../../Typings';
import { getMatchColor } from '../../helper/JobHelper';
import './MatchPercent.css';

export interface IMatchPercentProps extends HTMLAttributes<HTMLSpanElement> {
  matchScore: MatchScore;
};

const MatchPercent = ({ matchScore, ...props }: IMatchPercentProps) => {
  const getClassName = () => `fw-3${props.className ? ` ${props.className}` : ''}`;

  return (
    <div {...props} className={getClassName()}>
      <span className='match-percent-dot' title={`${matchScore.label}`} style={{backgroundColor: getMatchColor(matchScore)}} />
      {/* <span className='match-percent-text' style={{color: getMatchColor(matchScore)}}>{`${Math.floor(matchScore.score)}%`}</span> */}
    </div>
  );
};

export default MatchPercent;