import './DriverApp.css';

import Navbar, { NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';

import { AppStoreButtons } from '../common/AppStoreButtons';
import { Link } from 'react-router-dom';

export const ValidEmailConfirmation = () => (
  <section className='landing'>
    <Navbar>
      <NavLogo>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} isNotLink={true} />
        </Link>
      </NavLogo>
    </Navbar>
    <div className='driverApp-main'>
      <h1>Your email address has been confirmed!</h1>
      <p>
        However it does not appear that you are on the smart phone from which
        you originally entered your email address. Do you need to reinstall or
        restart the TruckerFy application?
      </p>
      <AppStoreButtons />
    </div>
  </section>
);
