import { useState, useReducer, ChangeEvent, HTMLAttributes, MouseEvent, useEffect } from 'react';
import { EdgeAlignment, SortDirection } from '../../Typings';
import { ReactComponent as ArrowUpSvg } from '../../assets/ArrowUp.svg';
import { ReactComponent as CheckSvg } from '../../assets/Check.svg';
import { ReactComponent as ChevronSvg } from '../../assets/Chevron.svg';
import { ReactComponent as CloseSvg } from '../../assets/Close.svg';
import { ReactComponent as SortSvg } from '../../assets/Sort.svg';
import { ReactComponent as ResetSvg } from '../../assets/Reset.svg';
import Button from './Button';
import DropdownContainer from './DropdownContainer';
import './GridSort.css';

type UserSort<T> = { type: T, sort: SortDirection, checked: boolean };

export interface GridSortProps<T> extends HTMLAttributes<HTMLElement> {
  title: string;
  sortNames: string[];
  defaultSort: UserSort<T>[];
  handleApplySorts: (sorts: UserSort<T>[]) => void;
};

enum SortActionStep {
  MoveUp,
  MoveDown,
  Select,
  ToggleChecked,
  ToggleSortDirection,
  Update
};

const GridSort = <T extends unknown>({ title, sortNames, defaultSort, handleApplySorts }: GridSortProps<T>) => {
  const [ toggleHideSortDropdown, setToggleHideSortDropdown ] = useState(false);
 
  type UserPreference = Array<{ type: T, sort: SortDirection, checked: boolean, selected: boolean }>;
  
  interface SortAction {
    type: T;
    step: SortActionStep;
  };

  const reducer = (data: UserPreference, action: SortAction) => {
    let index: number;
    let element: any;
    const newData: UserPreference = [];

    if (action.step === SortActionStep.Update) {
      data = getCandidateSortData();
      return data;
    }
  
    // Deep clone
    data.forEach(datum => {
      const newDatum = {...datum};
      newData.push(newDatum);
    });

    switch (action.step) {
      case SortActionStep.MoveDown:
        index = newData.findIndex(datum => datum.type === action.type);
        if (index < newData.length) {
          element = newData.splice(index, 1)[0];
          newData.splice(index + 1, 0, element);
        }
        break;
      case SortActionStep.MoveUp:
        index = newData.findIndex(datum => datum.type === action.type);
        if (index > 0) {
          element = newData.splice(index, 1)[0];
          newData.splice(index - 1, 0, element);
        }
        break;
      case SortActionStep.Select:
        newData.forEach(datum => datum.selected = datum.type === action.type);
        break;
      case SortActionStep.ToggleChecked:
        element = newData.find(datum => datum.type === action.type);
        if (element)
          element.checked = !element.checked;
        break;
      case SortActionStep.ToggleSortDirection:
        element = newData.find(datum => datum.type === action.type);
        if (element)
          element.sort = element.sort === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        break;
    }

    return newData;
  };

  const getCandidateSortData = () => {
    const combinedPrefs: UserPreference = [];
    for (let index = 0; index < defaultSort.length; index++)
      combinedPrefs.push({...defaultSort[index], ...{ selected: false }});
    return combinedPrefs;
  };

  const [ sortData, sortDataDispatch ] = useReducer(reducer, getCandidateSortData());

  useEffect(() => sortDataDispatch({ type: undefined as T, step: SortActionStep.Update }), [defaultSort]);

  const handleMoveButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;
    const dataIndex = target.getAttribute('data-index');

    if (dataIndex) {
      const elem = sortData.find(datum => datum.selected);
      if (elem)
        sortDataDispatch({ type: elem.type as T, step: dataIndex === '0' ? SortActionStep.MoveUp : SortActionStep.MoveDown });
    }
  };

  const composeSortContent = () => {
    const handleSortRowOnClick = (e: MouseEvent<HTMLTableRowElement>) => {
      const target = e.currentTarget;
      const dataIndex = target.getAttribute('data-index');
      if (dataIndex && !target.classList.contains('grid-sort-selected')) {
        const itemType = parseInt(dataIndex) as T;
        sortDataDispatch({ type: itemType, step: SortActionStep.Select });
      }
    };

    const handleSortCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget.parentElement?.parentElement;
      const dataIndex = target?.getAttribute('data-index');
      if (dataIndex) {
        const itemType = parseInt(dataIndex) as T;
        sortDataDispatch({ type: itemType, step: SortActionStep.ToggleChecked });
      }
    };

    const handleSortDirectionCellOnClick = (e: MouseEvent<HTMLTableCellElement>) => {
      const target = e.currentTarget.parentElement;
      const dataIndex = target?.getAttribute('data-index');
      if (dataIndex) {
        const itemType = parseInt(dataIndex) as T;
        sortDataDispatch({ type: itemType, step: SortActionStep.ToggleSortDirection });
      }
    };

    const getMoveUpButtonDisabled = () => sortData[0].selected || !sortData.some(datum => datum.selected);
    const getMoveDownButtonDisabled = () => sortData[sortData.length - 1].selected || !sortData.some(datum => datum.selected);

    const handleCloseApplyButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
      const target = e.currentTarget;
      const dataIndex = target.getAttribute('data-index');
  
      switch (dataIndex) {
        case '0': // Close
          sortDataDispatch({ type: undefined as T, step: SortActionStep.Update })
          break;

        case '1': // Apply
          const sorts: UserSort<T>[] = [];
          sortData.forEach(datum => {
            const pref = {...datum};
            sorts.push(pref);
          });
          handleApplySorts(sorts);
          break;

        case '2': // Reset
          handleApplySorts([]);
          break;
      }

      setToggleHideSortDropdown(prevValue => !prevValue);
    };
  
    return (
      <>
        <div className='grid-sort-content off-black fs-14'>
          <table>
            <tbody>
              {
                sortData.map((pref, index) => (
                  <tr key={index} data-index={pref.type} className={pref.selected ? 'grid-sort-selected' : ''} onClick={handleSortRowOnClick}>
                    <td><input type='checkbox' onChange={handleSortCheckboxChange} checked={pref.checked} /></td>
                    <td onClick={handleSortDirectionCellOnClick} title={`${pref.sort === SortDirection.Asc ? 'Ascending' : 'Descending'} order`}><ArrowUpSvg className={pref.sort === SortDirection.Desc ? 'arrow-down' : ''}/></td>
                    <td><span>{sortNames[pref.type as number]}</span></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className='grid-sort-btn-container'>
            <button data-index='0' style={{marginTop: '-4px'}} title='Do not apply sort' onClick={handleCloseApplyButtonClick}>
              <CloseSvg />
            </button>
            <div className='grid-sort-btn-container-up-down'>
              <Button data-index='0' disabled={getMoveUpButtonDisabled()} onClick={handleMoveButtonClick}>
                <ChevronSvg className='chevron-up' />
              </Button>
              <Button data-index='1' disabled={getMoveDownButtonDisabled()} onClick={handleMoveButtonClick}>
                <ChevronSvg />
              </Button>
            </div>
            <button data-index='1' title='Apply sort' onClick={handleCloseApplyButtonClick}>
              <CheckSvg height='20' width='20' />
            </button>
            <button data-index='2' style={{marginBottom: '-6px'}} title='Reset Sort' onClick={handleCloseApplyButtonClick}>
              <ResetSvg height='21' width='21' />
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <DropdownContainer
      toggleHideDropdown={toggleHideSortDropdown}
      containerEdgeAlignment={EdgeAlignment.Left}
      root={
        <div className='grid-sort-container fs-12' title={title} >
          <SortSvg />
          <span>&nbsp;Sort</span>
        </div>
      }
    >
      {composeSortContent()}
    </DropdownContainer>
  );
};

export default GridSort;
export type { UserSort };