import * as Yup from 'yup';
import { useState, useEffect, useRef, MouseEvent } from 'react';
import { Redirect, Prompt, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, FastField } from 'formik';
import { useModal } from '../../../context/ModalContext';
import { ApolloError, useMutation } from '@apollo/client';
import { getErrorMessage, Mutations } from '../../../util/Graphql';
import { deepEqual } from '../../../util/ObjectUtil';
import Announcement from '../../dialogs/Announcement';
import Button, { ButtonTheme } from '../../common/Button';
import Question from './Question';
import { JobStatus, UNAUTHENTICATED } from '../../../Typings';
import './Section.css';

type SectionProps = {
  questionnaireId: string;
  targetId: string;
  isEditMode: boolean;
  templateJson: any;
  sectionJson: any;
  answers: any;
  index: number;
  lastIndex: number;
  handleAnswersChange: (a: any) => void;
  handleMutating: (isMutating: boolean) => void;
};

function Section(props: SectionProps) {
  const { compose, destroy } = useModal();
  const [ sectionUrl, setSectionUrl ] = useState<string>();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ buttonAction, setButtonAction ] = useState<string>();
  const [ returnToDashboard, setReturnToDashboard ] = useState(false);
  const [ hasPendingChanges, setHasPendingChanges ] = useState(false);
  const history = useHistory();
  const location = useLocation<any>();

  const { handleMutating } = props;
  const formikRef = useRef(null);

  const [ updateJobPublicationStatusFunction ] = useMutation(Mutations.UPDATE_JOB_PUBLICATION_STATUS);
  const [ updateQuestionnaireAnswersFunction, { loading, error } ] = useMutation(Mutations.UPDATE_QUESTIONNAIRE_ANSWERS);

  useEffect(() => {
    handleMutating(loading && !error)
  }, [loading, error, handleMutating]);

  const getInitialValues = () => {
    const values: {[key: string]: any} = {};
    props.sectionJson.columns.map((column: any) => column.questions.map((question: any) => values[question.id] = undefined));
    return {...values, ...props.answers};
  };

  const getSchema = () => {
    const schema: {[key: string]: any} = {};
    const message = 'Please make a selection.';
    const getMessage = (minChoices: number) => {
      if (minChoices === 1) return message;
      return `Please make at least ${minChoices} selections.`;
    };

    props.sectionJson.columns.map((column: any, index: number) =>
      column.questions.forEach((question: any) => {
        switch (question.type) {
          case 'boolean':
            if (question.minChoices)
              schema[question.id] = Yup.boolean().required(message);
            break;
          case 'date':
            if (question.minChoices)
              schema[question.id] = Yup.date().required(message);

            break;
          case 'integer':
            if (question.minChoices)
              schema[question.id] = Yup.number().required(message);
            break;
          case 'text':
            if (question.minChoices)
              schema[question.id] = Yup.string().required(message);
            break;
          case 'single':           
            if (question.presentation === 'dropdown') {
              if (question.minChoices)
                schema[question.id] = Yup.string().required(message);
            } else if (question.minChoices) {
              schema[question.id] = Yup.array()
                .min(1, message)
                .max(1, 'Please make a single selection.');
            }
            break;
          case 'map':
          case 'multiple':
            if (question.minChoices)
              schema[question.id] = Yup.array().min(question.minChoices, getMessage(question.minChoices));
            break;
          case 'range':
            const arraySchema = Yup.array()
              .test('isRequired', 'Please supply both values.', list =>
                !!list && list[0] !== undefined && list[0] !== null && list[1] !== undefined && list[1] !== null
              )
              .test('isMinIncomeLessThanMaxIncome', 'Min income cannot exceed max income.', list =>
                !list || list[0] === undefined || list[0] === null || list[1] === undefined || list[1] === null || list[0] <= list[1]
              )
              .test('isMinIncomeGreaterThanOrEqualToMinValue', `Min income is ${question.min}.`, list =>
                !list || list[0] === undefined || list[0] === null || list[0] >= question.min
              )
              .test('isMaxIncomeLessThanOrEqualToMaxValue', `Max income is ${question.max}.`, list =>
                !list || list[1] === undefined || list[1] === null || list[1] <= question.max
              );

            schema[question.id] = arraySchema;
            break;
          }        
      }));

    return Yup.object().shape(schema);
  };

  const publishJob = async () => {
    await updateJobPublicationStatusFunction({
      variables: {
        id: props.targetId,
        publicationStatus: JobStatus.Published
      }
    }).catch((e: ApolloError) => {
      const errorMsg = getErrorMessage(e);
      if (errorMsg === UNAUTHENTICATED) {
        history.push('/login', { isAuthExpired: true });
      } else {
        history.push('/oops', { error: errorMsg });
      }
    });
  };

  const handleSubmit = (values: any) => {
    document.body.style.cursor = 'wait';

    const answers = Object.entries(values).map((k) => ({ questionId: k[0], answer: k[1] }));

    updateQuestionnaireAnswersFunction({
      variables: {
        questionnaireId: props.questionnaireId,
        targetId: props.targetId,
        answerData: JSON.stringify(answers)
      }
    })
    .then((res: any) => {
      setErrorMessage('');
      setHasPendingChanges(false);
      props.handleAnswersChange(values);
     
      switch (buttonAction) {
        case 'back':
          setSectionUrl(`/questionnaire/${props.questionnaireId.toLowerCase()}/${props.index}`);
          break;
        case 'continue':
          setSectionUrl(`/questionnaire/${props.questionnaireId.toLowerCase()}/${props.index + 2}`);
          break;
        default:
          if (props.questionnaireId.toLowerCase() === 'carrier') {
            if (props.isEditMode) {
              compose(<Announcement announcementMessage='Congrats! You successfully edited your carrier!' handleAnnouncementContinue={ handleAnnouncementContinue } />);
            } else {
              setSectionUrl('/welcome');
            }
          } else {
            if (buttonAction === 'publish') {
              publishJob();
            }
            compose(<Announcement announcementMessage={`Congrats! You successfully ${props.isEditMode ? 'edited your' : 'created a'} job post!`} handleAnnouncementContinue={ handleAnnouncementContinue } />);
          }
          break;
      }
    })
    .catch((e: ApolloError) => {
      const errorMsg = getErrorMessage(e);
      if (errorMsg === UNAUTHENTICATED) {
        history.push('/login', { isAuthExpired: true });
      } else {
        setErrorMessage(errorMsg);
      }
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };
  
  const handleAnnouncementContinue = () => {
    destroy();
    setReturnToDashboard(true);
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {

    const target = document.getElementsByClassName('questionnaire-main')[0];
    target.scrollTo(0, 0);

    const dataIndex = e.currentTarget.getAttribute('data-index');
    if (dataIndex)
      setButtonAction(dataIndex);
  };

  const composeSection = () =>
    <div className='questionnaire-section'>
      {
        props.sectionJson.columns.map((column: any, index: number) =>
          <div key={index} className='questionnaire-section-column'>
            {
              column.questions.map((question: any) =>
                <FastField component={Question} key={question.id} name={question.id} templateJson={props.templateJson} questionJson={question} autoFocus={index === 0} />
              ) 
            }
          </div>
        )
      }
    </div>;

  if (returnToDashboard)
    return <Redirect to='/dashboard' /> // TODO:  Didn't add "push" here bcs we really don't want to be able to return to here via the back button from the dashboard, but this isn't really enough to prevent issues...

  if (sectionUrl)
    return <Redirect push to={{
      pathname: sectionUrl,
      state: {
        isQuestionnaire: true,
        targetId: props.targetId,
        savedAnswers: props.answers,
        isEditMode: props.isEditMode
      } 
    }} />;

  const validate = (values: any) => {
    if (formikRef.current) {
      const current: any = formikRef.current;
      const initialValues = current.initialValues;
      const isEqual = deepEqual(initialValues, values)
      setHasPendingChanges(!isEqual);
    }
    return {};
  };
  
  const getPromptMessage = (destinationLocation: any) => {
    if (!props.isEditMode && location.state.isQuestionnaire) {
      if (props.questionnaireId.toLowerCase() === 'carrier')
        return '\nLeave page?\n\nYou have not saved the carrier questionnaire.';
      return '\nLeave page?\n\nYou have not saved or published this job.';
    }
    if (hasPendingChanges) {
      return '\nLeave page?\n\nChanges you made may not be saved.';
    }
    return true;
  }

  return (
    <>
      <Prompt message={getPromptMessage} />
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        onSubmit={values => handleSubmit(values)}
        validationSchema={getSchema()}
        validate={validate}
      >
        {() => (
          <Form>
            <p className='questionnaire-section-header'>{props.sectionJson.title}</p>
            <p className='questionnaire-section-sub-header'>{props.sectionJson.subTitle}</p>
            { composeSection() }
            { errorMessage !== '' && <p className='error-message'>{errorMessage}</p> }
            <footer>
              {
                (props.index > 0) && <Button formNoValidate  type='submit' data-index='back' onClick={handleButtonClick} theme={ButtonTheme.White}>Back</Button>
              }
              {
                (props.index < props.lastIndex) && <Button formNoValidate type='submit' data-index='continue' onClick={handleButtonClick}>Continue</Button>
              }
              {
                ((props.index === props.lastIndex) && !(props.questionnaireId === 'JOB' && !props.isEditMode)) && <Button formNoValidate type='submit' data-index='finish' onClick={handleButtonClick}>Finish</Button>
              }
              {
                ((props.index === props.lastIndex) && props.questionnaireId === 'JOB' && !props.isEditMode) && <Button formNoValidate type='submit' data-index='saveDraft' onClick={handleButtonClick}>Save Draft</Button>
              }
              {
                ((props.index === props.lastIndex) && props.questionnaireId === 'JOB' && !props.isEditMode) && <Button formNoValidate type='submit' data-index='publish' onClick={handleButtonClick}>Publish</Button>
              }
            </footer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Section;