import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useJobs } from '../../context/JobsContext';
import { Area, JobModel } from '../../Typings';
import SidebarLayout from '../layouts/SidebarLayout';
import Sidebar from '../specialized/dashboard/Sidebar';
import Dashbar from '../specialized/dashboard/Dashbar';
import JobView from '../specialized/dashboard/JobView';
import JobsView from '../specialized/dashboard/JobsView';
import CandidatesView from '../specialized/dashboard/CandidatesView';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import ConversationsView from '../specialized/dashboard/ConversationsView';
import './Dashboard.css';

type SelectedContent = {
  area: Area;
  job?: JobModel;
};

const Dashboard = () => {
  const { carrier, activeJob, setActiveJob, jobs } = useJobs();
  const [ selectedContent, setSelectedContent ] = useState<SelectedContent>({ area: Area.Jobs });
  const { getUserInfo } = useAuth();
  const { mainTabName: mainTabNameParam } = useParams<{ mainTabName: string | undefined }>();
  const { jobId: jobIdParam } = useParams<{ jobId: string | undefined }>();
  const { conversationId: conversationIdParam } = useParams<{ conversationId: string | undefined }>();
  const { toggleZenDesk } = useTruckerFyApp();
  const location = useLocation<any>();
  const history = useHistory();
  const { hash } = useLocation();

  useEffect(() => {
    if (!carrier) {
      history.push('/editcarrier', { createNewCarrier: true });
    } else if (location.pathname.toLowerCase().endsWith('/dashboard') || location.pathname.toLowerCase().endsWith('/dashboard/')) {
      setSelectedContent({ area: Area.Jobs });
    } else if (mainTabNameParam) {
      const mainTabName = `${mainTabNameParam[0].toUpperCase()}${mainTabNameParam.slice(1).toLowerCase()}`;    
      const mainTab = mainTabName as keyof typeof Area;
      const area = Area[mainTab];
      if (area !== undefined) {
        setSelectedContent({ area: area });
      } else {
        history.push('/oops', { error: 'Invalid parameters passed to dashboard page.' });
      }
    } else if (jobIdParam) {
      const job = jobs.find(job => job.id === jobIdParam);
      // TODO: handle case where old bookmark links to an archived or deleted job
      setActiveJob(job);
    }
  }, [carrier, history, location.pathname, mainTabNameParam, jobIdParam, conversationIdParam, jobs, setActiveJob]);

  useEffect(() => {
    if (hash && hash.toLowerCase() === '#contactus') {  
      toggleZenDesk();
    }
  }, [hash]); // eslint-disable-line

  useEffect(() => {
    if (location.state?.createJob) {
      delete location.state.createJob;
      history.push('/editjob', { carrierId: carrier?.id });
    }
  }, [location.state, history, carrier?.id]);

  useEffect(() => {
    if (jobIdParam && activeJob) {
      setSelectedContent({
        area: Area.Jobs,
        job: activeJob
      });
    }
  }, [jobIdParam, activeJob]);

  const handleAccordionItemSelect = (area: Area, job?: JobModel) => {
    setActiveJob(job);
    setSelectedContent({ 
      area: area, 
      job: job
    }); 
  };

  const composeContent = () => {
    if (jobIdParam && activeJob)
      return <JobView />;

    switch (selectedContent?.area) {
      case Area.Jobs:
        return <JobsView />;        
      case Area.Candidates:
        return <CandidatesView />;
      case Area.Conversations:
        return <ConversationsView />;
    }
  };

  return (
    <SidebarLayout>
      <Sidebar onAccordionItemSelect={handleAccordionItemSelect} selectedSectionIndex={selectedContent.area} jobId={jobIdParam}/>
      <main className='dashboard-main'>
        <header className='dashboard-main-header'>
          {
            selectedContent && <Dashbar area={selectedContent.area} job={selectedContent.job} />
          }
        </header>
        <section className='dashboard-main-section'>
          {composeContent()}
        </section>
      </main>
    </SidebarLayout>
  );
}

export default Dashboard;