import './JobConversation.css';

import { ApolloError, useMutation } from '@apollo/client';
import { ConversationModel, MessageModel, UNAUTHENTICATED } from '../../../Typings';
import {
  CSSProperties,
  HTMLAttributes,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { ReactComponent as ArrowUpSvg } from '../../../assets/ArrowUp.svg';
import Button from '../../common/Button';
import { ReactComponent as CheckSvg } from '../../../assets/Check.svg';
import Linkify from 'react-linkify';
import Loader from '../../transition/Loader';
import { getErrorMessage, Mutations } from '../../../util/Graphql';
import TextBox from '../../common/TextBox';
import { areDatesOnSameDay } from '../../helper/DateHelper';
import { format } from 'date-fns';
import { useAuth } from '../../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import useTimer from '../../../util/Timer';
import { PersonAvatar } from '../cloudinary/PersonAvatar';
import { useTruckerFyApp } from '../../../context/TruckerFyAppContext';

interface IJobConversationProps extends HTMLAttributes<HTMLDivElement> {
  conversation: ConversationModel;
  onSeeProfileClick: () => void;
  onRefetchConversations: () => void;
}

const JobConversation = ({
  conversation,
  onSeeProfileClick,
  onRefetchConversations,
  ...props
}: IJobConversationProps) => {
  const history = useHistory();
  const { getUserInfo } = useAuth();
  const {appVisible} = useTruckerFyApp();
  const [messages, setMessages] = useState<Array<MessageModel>>();
  const [messageCount, setMessageCount] = useState(0);
  const [toggleClearMessage, setToggleClearMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [previousConversationId, setPreviousConversationId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const elementRef = useRef<HTMLDivElement>(null);

  const updateMessages = (data: any) => {
    if (data) {
      const newMessageCount = data.updateDriverMessagesAndFetchConversation.messages.length;
      if (
        newMessageCount !== messageCount ||
        conversation.id !== previousConversationId
      ) {
        setMessages(data.updateDriverMessagesAndFetchConversation.messages);
        if (
          newMessageCount !== messageCount &&
          conversation.id === previousConversationId
        ) {
          onRefetchConversations();
        }
        setPreviousConversationId(conversation.id);
        setMessageCount(newMessageCount);
      }
    }
  };

  const [
    updateDriverMessagesAndFetchConversationFunction,
    { loading: updateDriverMessagesAndFetchConversationLoading },
  ] = useMutation(Mutations.UPDATE_DRIVER_MESSAGES_AND_FETCH_CONVERSATION);

  const refreshMessages = () => {
    console.log('refreshMessages()');
    if (conversation) {
      updateDriverMessagesAndFetchConversationFunction({
        variables: { conversationId: conversation.id },
        onError(e) {
          const errorMsg = getErrorMessage(e);
          if (errorMsg === UNAUTHENTICATED) {
            history.push('/login', { isAuthExpired: true });
          } else {
            history.push('/oops', { error: errorMsg });
          }    
        },
        onCompleted(data) {
          updateMessages(data);
        },
      });
    }
  };

  useLayoutEffect(() => {
    setMessageCount(0);
  }, [conversation]);

  useLayoutEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Job Conversation';
    return () => { document.title = prevTitle; };
  }, []);

  useEffect(() => {
    if (appVisible)
      refreshMessages();
  }, [appVisible]);

  const timerCallback = () => {
    if (appVisible)
      refreshMessages();
  };

  useTimer({ callback: timerCallback, delay: parseInt(process.env.REACT_APP_JOB_CONVERSATION_MESSAGES_REFRESH_INTERVAL_MS ?? '30000') }); // Default to 30 seconds

  const composeConversationMessages = () => {
    if (!messages?.length) return;
    const messageComponents = Array<JSX.Element>();
    const composeMessages = () => {
      let lastMessageDate: Date;
      let rowIndex: number = 0;
      let lastMessageFromName: string;

      messages
        .sort((m1, m2) => new Date(m1.createdAt).getTime() - new Date(m2.createdAt).getTime())
        .forEach((message) => {
          const messageDate = new Date(message.createdAt);
          const newDate = !lastMessageDate || !areDatesOnSameDay(lastMessageDate, messageDate);
          const messageFrom = message.from.id === conversation.driver?.id
            ? 'driver'
            : 'carrier';
          const messageFromName = `${message.from.firstName} ${message.from.lastName}`;
          const messageReadDate = message.readAt
            ? new Date(message.readAt)
            : undefined;
          const getClassName = () =>
            `job-conversation-bubble${
              message.from.id === getUserInfo()?.id ? ' from-user' : ''
            }`;
          const getStyle = (): CSSProperties | undefined => {
            return !showSender
              ? {marginTop: '7px'}
              : undefined;
          };
          const showSender = !lastMessageFromName || messageFromName !== lastMessageFromName || newDate;

          const composeBubble = () => (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {showSender 
                ? (
                  <div className='userName'>
                    <div style={{float: 'left', paddingRight: '5px'}}>
                      <PersonAvatar publicId={message.from.photoUrl} size={20} />
                    </div>
                    <span className='medium-gray fs-12 ls--4'>
                      {`${messageFromName}`}
                    </span>
                  </div>
                ) 
                : null
              }
              <div key={message?.id} className={getClassName()} style={getStyle()}>
                <div className='fs-14 fw-3 ls-2'>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target='blank' href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {message.message}
                  </Linkify>
                </div>
                <div
                  className={`job-conversation-bubble-check-time ${messageFrom}`}
                >
                  {messageReadDate ? (
                    <CheckSvg
                      title={format(messageReadDate, 'M/d/yy h:mm aa')}
                    />
                  ) : null}
                  <span className='medium-gray fs-12 ls--4'>
                    {format(messageDate, 'h:mm aa')}
                  </span>
                </div>
              </div>
            </div>
          );

          if (newDate) {
            let row = (
              <tr key={rowIndex++}>
                <td
                  colSpan={2}
                  className='job-conversation-bubble-date fs-12 ls--4'
                >
                  <span>{format(messageDate, 'M/d/yy')}</span>
                </td>
              </tr>
            );
            messageComponents.push(row);
          }
          if (messageFrom === 'driver') {
            messageComponents.push(
              <tr key={rowIndex++}>
                <td style={{ paddingRight: '10px' }}>{composeBubble()}</td>
                <td />
              </tr>
            );
          } else {
            messageComponents.push(
              <tr key={rowIndex++}>
                <td />
                <td style={{ paddingLeft: '10px' }}>{composeBubble()}</td>
              </tr>
            );
          }
          lastMessageDate = messageDate;
          lastMessageFromName = messageFromName;
        });

      return messageComponents;
    };

    return (
      <table style={{ width: '100%' }}>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <tbody>{composeMessages()}</tbody>
      </table>
    );
  };

  useLayoutEffect(() => {
    elementRef?.current && elementRef.current.scrollIntoView();
  }, [messages]);

  const handleMessageChange = (newValue: string) => {
    setMessage(newValue?.trim());
  };

  const [sendMessageFunction, { loading: sendMessageloading }] = useMutation(
    Mutations.SEND_MESSAGE
  );

  const sendMessage = () => {
    if (message) {
      sendMessageFunction({
        variables: {
          jobId: conversation.job?.id,
          driverId: conversation.driver?.id,
          message: message,
        }
      }).then((res: any) => {
        setErrorMessage('');
        refreshMessages();
        setToggleClearMessage((prevValue) => !prevValue);
      })
      .catch((e: ApolloError) => {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          setErrorMessage(errorMsg);
        }
      });
    }
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    sendMessage();
  };

  const handleMessageOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const loading = () => updateDriverMessagesAndFetchConversationLoading || sendMessageloading;

  return (
    <main className='job-conversation off-black fs-16 ls-2'>
      {loading() && <Loader />}
      <section>
        {composeConversationMessages()}
        <div style={{ visibility: 'hidden' }} ref={elementRef} />
      </section>
      <footer>
        <div>
          <TextBox
            toggleClear={toggleClearMessage}
            autoFocus
            onTextChange={handleMessageChange}
            onKeyUp={handleMessageOnKeyUp}
            disabled={!conversation.driverIsMessageable}
            placeholder={conversation.driverIsMessageable ? "Message" : "Driver is not Messageable"}
          />
          <Button 
            onClick={handleButtonClick} 
            disabled={!conversation.driverIsMessageable}
            title={conversation.driverIsMessageable ? "Click to Send" : "Driver is not Messageable"}
          >
            <ArrowUpSvg />
          </Button>
        </div>
        { errorMessage !== '' && <p className='error-message'>{errorMessage}</p> }
      </footer>
    </main>
  );
};

export default JobConversation;
