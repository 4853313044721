import './index.css';

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import App from './App';
import { AuthProvider } from './context/AuthContext';
import ErrorBoundary from './context/ErrorBoundary';
import { ModalProvider } from './context/ModalContext';
import { PreferenceProvider } from './context/PreferenceContext';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { StrictMode } from 'react';
import { customFetch } from './util/customFetch';
import reportWebVitals from './reportWebVitals';

// Learn more: https://bit.ly/CRA-vitals
// See data: http://analytics.google.com/analytics/web/
const sendToAnalytics = ({
  id,
  name,
  value,
}: {
  id?: string;
  name: string;
  value: number;
}) => {
  ReactGA.event({
    category: 'web_vitals',
    action: name,
    label: id, // id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), // Integer only
    nonInteraction: true, // Avoids affecting bounce rate
    transport: 'xhr',
  });
};

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
if (trackingId) {
  const testMode = process.env.REACT_APP_GOOGLE_ANALYTICS_TEST_MODE === '1';
  ReactGA.initialize(trackingId, { testMode: testMode });
  reportWebVitals(sendToAnalytics);
}

const link = createHttpLink({
  uri: process.env.REACT_APP_TRUCKERFY_SERVER_URL,
  fetch: customFetch,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  // connectToDevTools: true
});

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <AuthProvider>
          <PreferenceProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </PreferenceProvider>
        </AuthProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root')
);
