import { FastField, Form, Formik } from 'formik';
import { ReactComponent as TrafficConeSvg } from '../../assets/TrafficCone.svg';
import { ReactComponent as TruckSideSvg } from '../../assets/TruckSide.svg';
import { useModal } from '../../context/ModalContext';
import Button, { ButtonTheme } from '../common/Button';
import Textarea from '../common/Textarea';
import * as Yup from 'yup';
import './EditConfirmText.css';

type EditConfirmTextProps = {
  prompt: string,
  text: string,
  textPlaceholder?: string,
  okButtonText?: string,
  handleOk?: (text: string) => void,
  handleCancel?: () => void,
};

const EditConfirmTextSchema = Yup.object().shape({
  text: Yup.string().required('Required')
});

const EditConfirmText = ({ prompt, text, textPlaceholder = 'Message', okButtonText = 'Continue', handleOk = () => {}, handleCancel = undefined }: EditConfirmTextProps) => {
  const { destroy } = useModal();
  const handleCancelClick = () => { handleCancel ? handleCancel() : destroy() };

  const handleSubmit = (values: any) => {
    handleOk(values.text);
  };

  return (
    <section className='modal-edit-confirm-text'>
      <Formik
        initialValues={{
          text: text
        }}
        onSubmit={formValues => handleSubmit(formValues)}
        validationSchema={EditConfirmTextSchema}
      >
        <Form>
          <table>
            <thead>
              <tr><th><div className='modal-edit-confirm-text-header'><TrafficConeSvg /><TruckSideSvg /><TrafficConeSvg /></div></th></tr>
            </thead>
            <tbody>
              <tr><td><span className='off-black fw-5 fs-18 ls-02'>{prompt}</span></td></tr>
              <tr><td><div><FastField component={Textarea} name='text' autoFocus placeholder={`${textPlaceholder}`} className='off-black' rows={7} /></div></td></tr>
            </tbody>
            <tfoot>
              <tr><td>
                <div className='modal-edit-confirm-text-footer'>
                  <Button type="submit">{okButtonText}</Button>
                  <Button type="button" theme={ButtonTheme.White} style={{ marginLeft: '20px' }} onClick={handleCancelClick}>Cancel</Button>
                </div>
              </td></tr>
            </tfoot>
          </table>
        </Form>
      </Formik>
    </section>
  );
}

export default EditConfirmText;