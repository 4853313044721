/* We want to highlight requirements that are in conflict with a driver's profile.
 * There are various cases:
 * Job has a scalar requirement, driver profile is a scalar, different and not "any" - mismatch
 * Job has an array requirement, driver profile is a scalar not in the array and not "any" - mismatch
 * Job has an array requirement, driver profile is an array. Driver profile elments not in the requirement are "missing"
 * Job has a scalar requirement, driver profile is an array and not a singleton "any". If requirement is not in the array - mismatch
 * Job has a number requirement, driver has a number profile. requirement number can be > or < profile, what to return?
 *  For salary, we want a mismatch if job < profile.
 *  For years experience we want a mismatch if job > profile.
 *  For previous jobs we wnat a mismatch if job < profile.
 */

import { useQuestionnaire } from "../../context/QuestionnaireContext";

enum homeTime {
  keepPromises = 0,
  flexible = 1, 
  advanceNotice = 2
};
enum daysOut {
  homeDaily = 0,
  homeWeekly = 1,
  homeWeekends = 2,
  multiWeek = 3,
  any = 4
};

// const homeTime = { keepPromises: 0, flexible: 1, advanceNotice: 2 }; // for fast comparisons
// const daysOut = {
//   homeDaily: 0,
//   homeWeekly: 1,
//   homeWeekends: 2,
//   multiWeek: 3,
//   any: 3,
// }; // ditto

interface IProfileQuestionAnswers {
  questionId: string;
  answer: boolean | string | number | object | object[] | undefined;
}

export default function useProfileAnswerAssessor() {
  const questionnaireService = useQuestionnaire();

  const isScalarMatch = (id: string, profile: IProfileQuestionAnswers[], requirements: IProfileQuestionAnswers[]) => {
    const profileAnswer = profile?.find((el) => el.questionId === id)?.answer;
    let requirement = requirements.find((el) => el.questionId === id)?.answer;
    if (Array.isArray(requirement)) {
      const requirementArray = requirement as object[];
      if (requirementArray.length === 1) 
        requirement = requirementArray[0];
      else
        console.error(
          `Expected a singleton answer for question ${id}`,
          requirement
        );
    }
    return singleMatch(id, profileAnswer, requirement);
  };

  // const lessThanOrEqual = (id, profile, requirements) => {
  //   const profileAnswer = profile?.find((el) => el.questionId === id)?.answer;
  //   let requirement = requirements.find((el) => el.questionId === id)?.answer;
  //   if (Array.isArray(requirement)) {
  //     if (requirement.length === 1) requirement = requirement[0];
  //     else
  //       console.error(
  //         `Expected a singleton answer for question ${id}`,
  //         requirement
  //       );
  //   }
  //   return profileAnswer <= requirement;
  // };

  // const includes = (id, a1, a2) => {
  //   const r1 = a1?.find((el) => el.questionId === id)?.answer;
  //   let r2 = a2?.find((el) => el.questionId === id)?.answer;
  //   if (Array.isArray(r2)) {
  //     if (r2.length === 1) r2 = r2[0];
  //     else
  //       console.error(
  //         `includes expected a singleton array for a2, question ${id}`,
  //         r2
  //       );
  //   }
  //   return (
  //     (r1 && Array.isArray(r1) && (r1.includes(r2) || r1[0] === 'any')) ||
  //     r2 === 'any'
  //   );
  // };

  // const meetsIncomeRequirement = (profile, requirements) => {
  //   const incomeRequirement = getScalarAnswer(profile, 'income');
  //   const minIncome = requirements.find((el) => el.questionId === 'income')
  //     ?.answer[0];
  //   return minIncome >= incomeRequirement || !minIncome;
  // };

  // const matchManyToMany = (id, profile, requirements) => {
  //   const profileAnswer = profile?.find((el) => el.questionId === id)?.answer;
  //   const requirement = requirements.find((el) => el.questionId === id)?.answer;
  //   return matchManyToManySub(id, profileAnswer, requirement);
  // };

  // const matchManyToManySub = (id, pa, requirement) => {
  //   const profileAnswer = Array.isArray(pa) ? pa : [pa];
  //   if (Array.isArray(requirement)) {
  //     const isAny =
  //       !profileAnswer || profileAnswer[0] === 'any' || requirement[0] === 'none';
  //     const question = quex.job.questionById(id);
  //     try {
  //       if (question && requirement) {
  //         const choices = question.choices || question.answers;
  //         return requirement.map((el, i) => {
  //           const separator = i < requirement.length - 1 ? ', ' : '';
  //           const text = choices.find((c) => c.id === el)?.text;
  //           const value = text ? `${text}${separator}` : `${el}${separator}`;
  //           const matches = isAny || profileAnswer.includes(el);
  //           return { value, matches };
  //         });
  //       } else return [];
  //     } catch (error) {
  //       console.error(error);
  //       console.info(id, requirement, profileAnswer);
  //       return [];
  //     }
  //   } else return [];
  // };

  // const getMissing = (id, pa, requirement) => {
  //   let profileAnswer;
  //   if (typeof pa === 'string') profileAnswer = [pa];
  //   else if (!pa) profileAnswer = [];
  //   else profileAnswer = pa;
  //   const missing = Array.isArray(requirement)
  //     ? profileAnswer.filter((el) => !(requirement.includes(el) || el === 'any'))
  //     : profileAnswer.filter((el) => el !== requirement);
  //   return missing.map((el, i) => {
  //     const choices = quex.driver.questionById(id)?.choices || [];
  //     const choice = choices.find((c) => c.id === el);
  //     const separator = i < missing.length - 1 ? ', ' : '';
  //     return `${choice ? choice.text : el}${separator}`;
  //   });
  // };

  const singleMatch = (id: string, profileAnswer: any, requirement: any) => {
    if (id === 'experience')
      return parseInt(requirement as string) <= profileAnswer;
    else if (id === 'jobs')
      return parseInt(requirement as string) >= profileAnswer;
    else if (id === 'homeTime')
      return homeTime[profileAnswer.toString() as keyof typeof homeTime] >= homeTime[requirement.toString() as keyof typeof homeTime];
    else if (id === 'daysOut')
      return daysOut[profileAnswer.toString() as keyof typeof daysOut] >= daysOut[requirement.toString() as keyof typeof daysOut];
    else if (id === 'pets') 
      return !(profileAnswer && !requirement);
    
    return (
      requirement === 'any' ||
      requirement === 'both' ||
      (typeof profileAnswer === 'string' && 
        (requirement === profileAnswer || profileAnswer === 'any')) ||
      (Array.isArray(profileAnswer) &&
        (profileAnswer.includes(requirement) || profileAnswer[0] === 'any'))
    );
  };

  return { isScalarMatch };
}