/* This function adds a <script type="application/ld+json"> object to the page header
 * This enables Google to index the page as a "JobPosting", enabling semantic search and display in Google job listings
 * https://developers.google.com/search/docs/appearance/structured-data/job-posting
 * https://schema.org/JobPosting
 */
import { Carrier as tCarrier } from '../../Typings';

import { Helmet } from 'react-helmet-async';
import { Corporation } from 'schema-dts';
import React from 'react';
import { helmetJsonLdProp } from 'react-schemaorg';

const cloudinaryRoot =
  'https://res.cloudinary.com/truckerfy/image/upload/c_thumb,h_256,w_256/v1/'; // 256px square

interface Props {
  carrier: tCarrier;
}

interface iQuestion {
  questionId: string;
  answer: string | string[] | number | number[];
}

export const CarrierLdJson: React.FC<Props> = ({ carrier }) => {
  if (!carrier) return null;
  const {
    name,
    photoUrl,
    postalCode,
    city: addressLocality,
    street1: streetAddress,
    state: addressRegion,
    url,
    description
  } = carrier;

  const logo = photoUrl ? `${cloudinaryRoot}${photoUrl}` : '';
  let validThrough = new Date();
  validThrough.setMonth(validThrough.getMonth() + 2);

  // return the Helmet object - this will be inserted automatically into the <head> of the page
  return (
    <Helmet
      script={[
        helmetJsonLdProp<Corporation>({
          '@context': 'https://schema.org',
          '@type': 'Corporation',
          address: {
            '@type': 'PostalAddress',
            streetAddress,
            addressLocality,
            addressRegion,
            postalCode,
            addressCountry: 'US'
          },
          logo,
          name,
          description,
          url
        }),
      ]}
    />
  );
};
