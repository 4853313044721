import { useState, useLayoutEffect, useCallback, TableHTMLAttributes, MouseEvent } from 'react';
import { ReactComponent as AngleDownSvg } from '../../assets/AngleDown.svg';
import { SortDirection } from '../../Typings';
import './Grid.css';

export enum ColumnType {
  Custom,
  Date,
  Key,
  Text
};

export interface Column<T> {
  caption?: string;
  noSort?: boolean;
  type: ColumnType;
  hidden?: boolean;
  renderer?: (item: T) => JSX.Element;
  sorter?: (left: T, right: T) => number;
};

type SortColumn = {
  key: string,
  direction: SortDirection
};

export interface GridProps<T> extends TableHTMLAttributes<HTMLTableElement> {
  columns: Map<string, Column<T>>;
  sortColumn?: SortColumn | undefined;
  toggleClearSortColumn?: boolean;
  rowSelect?: boolean;
  data: T[];
  gridRowClick?: (key: any) => void;
  children?: never;
};

function getProperty<T, K extends keyof T>(obj: T, propertyName: K): T[K] {
  return obj[propertyName];
};

const Grid = <T extends unknown>({ columns, data, sortColumn, toggleClearSortColumn, rowSelect = true, gridRowClick, ...props }: GridProps<T>) => {
  const [ sortColumnInternal, setSortColumnInternal ] = useState<SortColumn | undefined>(sortColumn);
  const [ dataInternal, setDataInternal ] = useState<T[]>(data);
  const [ selectedRowKey, setSelectedRowKey ] = useState<string | null>(null);

  const sorter = useCallback((item1: any, item2: any) => {
    if (sortColumnInternal) {
      const column = columns.get(sortColumnInternal.key);
      const sign = sortColumnInternal.direction === SortDirection.Desc ? -1 : 1;
      
      if (column?.sorter) {
        return column.sorter(item1, item2) * sign;
      }

      let propVal1 = getProperty(item1, sortColumnInternal.key);
      let propVal2 = getProperty(item2, sortColumnInternal.key);

      if (column?.type === ColumnType.Date) {
        const delta = new Date(propVal1).getTime() - new Date(propVal2).getTime();
        if (delta === 0) return 0;
        return (delta < 1 ? -1 : 1) * sign;
      }

      if (column?.type === ColumnType.Text) {
        propVal1 = propVal1 || '';
        propVal2 = propVal2 || '';
        return propVal1.localeCompare(propVal2) * sign;
      }
    }

    return 0;
  }, [columns, sortColumnInternal]);
  
  useLayoutEffect(() => {
    setSortColumnInternal(undefined);
  }, [toggleClearSortColumn]);
  
  useLayoutEffect(() => {
    const newData = [...data];
    newData.sort(sorter);
    setDataInternal(newData);
  }, [data, sortColumnInternal, sorter]);

  const handleColumnSort = (e: MouseEvent<HTMLTableCellElement>) => {
    const key = e.currentTarget.getAttribute('data-index');
    if (key) {
      const newSortColumn: SortColumn = { 
        key: key,
        direction: (sortColumnInternal && sortColumnInternal.key === key) 
          ? (sortColumnInternal.direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) 
          : SortDirection.Asc
      };
      setSortColumnInternal(newSortColumn);
    }
  };
  
  const getSvgClass = () => sortColumnInternal && `grid-column-sort-arrow-${sortColumnInternal.direction === SortDirection.Asc ? 'up' : 'down'}`;

  const getSortIndicator = (key: string) => {
    if (sortColumnInternal && sortColumnInternal.key === key)
      return <AngleDownSvg className={getSvgClass()} />
    return <AngleDownSvg style={{ visibility: 'hidden' }} />
  };

  const composeColumns = () => {
    const elements = Array<JSX.Element>();
    columns.forEach((value, key) => {
      if (!value.hidden) {
        elements.push(
          !!value.noSort
            ? <th key={key} style={(value.type === ColumnType.Key ? { display: 'none'} : { display: ''})}>{value.caption}</th>
            : <th key={key} className='sortable' data-index={key} style={(value.type === ColumnType.Key ? { display: 'none'} : { display: ''})} onClick={handleColumnSort}>
                <span>{value.caption}</span>
                {getSortIndicator(key)}
              </th>     
        );
      }
    });
    return elements;
  };

  const composeCells = (entity: T) => {
    const elements = Array<JSX.Element>();
    const entityResolved = entity as any;
    columns.forEach((column, key) => {
      const valueResolved = getProperty(entityResolved, key);
      let content: any;

      switch (column.type) {
        case ColumnType.Date:
          content = valueResolved ? new Date(valueResolved).toLocaleDateString('en-US') : '';
          break;
        case ColumnType.Custom:
          content = column.renderer ? column.renderer(entityResolved) : valueResolved;
          break;
        default: // Key, Text
          content = valueResolved ?? '';
      }
      
      if (column.type === ColumnType.Key)
        elements.push( <td key={key} style={{ display: 'none'}}>{content}</td> );
      else
        elements.push( <td key={key}>{content}</td> );
    });
    return elements;
  };

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>) => {
    if (gridRowClick) {
      const dataIndex = e.currentTarget.getAttribute('data-index');
      setSelectedRowKey(dataIndex);
      gridRowClick(dataIndex);
    }
  };

  const getRowClassName = (dataIndex: string | null) => ((rowSelect && dataIndex === selectedRowKey) ? 'row-select': '');

  const composeBody = () => {
    return dataInternal.map((entity, index) => {
      const columnsArray = Array.from(columns);
      const columnMeta = columnsArray.find(pair => pair[1].type === ColumnType.Key);
      let dataIndex: any;

      if (columnMeta) {
        type RowKey = keyof typeof entity;
        const key: RowKey = columnMeta[0] as any;
        dataIndex = entity[key];
      }

      return <tr key={`grid-row-${index}`} onClick={handleRowClick} data-index={dataIndex} className={getRowClassName(dataIndex)}>
        {composeCells(entity)}
      </tr>;
    });
  };

  return (
    <div className='grid-container'>
      <table className='grid fs-14 ls-2' {...props}>
        <thead className='fw-5'>
          <tr>
            {composeColumns()}
          </tr>
        </thead>
        <tbody>
          {composeBody()}
        </tbody>
        <tfoot />
      </table>
    </div>
  );
};

export default Grid;