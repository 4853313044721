import { useRef, HTMLAttributes } from 'react';
import './ProgressBar.css';

export interface IProgressBarProps extends HTMLAttributes<HTMLElement> {
  progress: number;
};

const ProgressBar = ({ progress, ...props }: IProgressBarProps) => {
  const sectionRef = useRef<HTMLElement>(null);
  const getClassName = () => `progress-bar${props.className ? ` ${props.className}` : ''}`;
  const getPercentWidth = () => {
    if (sectionRef.current) {
      return `${sectionRef.current.offsetWidth}px`;
    }
    return '0';
  };

  return (
    <section ref={sectionRef} {...props} className={getClassName()}>{`${progress}%`}
      <div className='progress-bar-indicator' style={{ width: `${progress}%`}}>
        <div className='progress-bar-percent' style={{ width: getPercentWidth() }}>{`${progress}%`}</div>
      </div>
    </section>
  );
};

export default ProgressBar;