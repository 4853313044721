import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { getErrorMessage, Queries } from '../../util/Graphql';
import { JobModel } from '../../Typings';
import JobPost from '../specialized/dashboard/JobPost';
import { isUuid } from '../../util/ObjectUtil';
import { ReactComponent as AppStoreBadgeSvg } from '../../assets/AppStoreBadge.svg';
import Navbar, { NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import NavbarLayout from '../layouts/NavbarLayout';
import Loader from '../transition/Loader';
import GoogePlayBadgePng from '../../assets/GooglePlayBadge.png';
import './Job.css';

const Job = () => {
  const history = useHistory();
  const { jobId } = useParams<{ jobId: string }>();
  const [ job, setJob ] = useState<JobModel>();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - View Job';

    return () => { document.title = prevTitle; };
  }, []);

  const handleJobDataFetch = (data: any) => {
    if (data?.getJobAnon) {
      setJob(data.getJobAnon as JobModel);
    } else {
      history.push('/notfound');
    }
  };

  const [getJobFunction, { loading: jobLoading }] = useLazyQuery(
    Queries.GET_JOB_ANON, 
    { 
      fetchPolicy: 'no-cache', 
      variables: { id: jobId },
      onError(e) {
        const errorMsg = getErrorMessage(e);
        history.push('/oops', { error: errorMsg });
      },
      onCompleted(data) {
        handleJobDataFetch(data); 
      } 
    }
  );

  useEffect(() => {
    if (isUuid(jobId))
      getJobFunction();
    else
      history.push('/notfound');
  }, [jobId, getJobFunction, history]);

  return (
    <NavbarLayout>
      <header className='job-page-header'>
        <Navbar>
          <NavLogo><TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} linkAddress='/' /></NavLogo>
        </Navbar>
      </header>
      <main className='job-page-main'>
        { jobLoading && <Loader />}
        {
          job &&
            <div className='job-page-container'>
              <div className='job-page-content'>
                <JobPost job={job} hidePersonalData={true} />
              </div>
              <p className='off-black fs-19 fw-5'>Apply to this job and many others on the TruckerFy app!</p>
              <div className='job-page-badge-container'>
                <AppStoreBadgeSvg title='' onClick={() => (window.location.href = process.env.REACT_APP_APPLE_STORE_URL ?? '/')} />
                <img src={GoogePlayBadgePng} alt='Google Play' onClick={() => (window.location.href = process.env.REACT_APP_GOOGLE_PLAY_URL ?? '/')} />
              </div>
            </div>
        }
      </main>
    </NavbarLayout>
  );
};

export default Job;