import { useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { ValidEmailConfirmation } from '../pages/ValidEmailConfirmation';
import { ValidateDriverEmail } from '../transition/ValidateDriverEmail';
import { QuestionnaireProvider } from '../../context/QuestionnaireContext';
import { DriverApp } from '../pages/DriverApp';
import CheckYourEmail from '../pages/CheckYourEmail';
import NotFound from '../pages/NotFound';
import Oops from '../pages/Oops';
import Validate from '../transition/Validate';
import Recover from "../transition/Recover";
import Job from '../pages/Job';
import Driver from '../pages/Driver';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import SignupForm from '../shared/SignupForm';
import NoMobile from '../pages/NoMobile';
import CarrierJobs from '../pages/CarrierJobs';

const UnprotectedRoutes = () => {
  const { isUser } = useAuth();
  const { referrer, clearReferrer } = useTruckerFyApp();
  const location = useLocation();

  const Home = () => {
    window.location.href = '/home.html';
    return <></>;
  };

  const Privacy = () => {
    window.location.href = 'https://carriers.truckerfy.com/privacy-policy';
    return <></>;
  };

  useLayoutEffect(() => {
    if (referrer && location.pathname !== '/login') {
      clearReferrer();
    }
  }, [referrer, location.pathname, clearReferrer]);

  const composeLoginRoute = () => {
    if (isUser()) {
      if (referrer && referrer !== '/login' && referrer !== '/logout') {        
        return <Redirect to={referrer} />;
      }
      return <Redirect to={`/dashboard${location.hash && location.hash.toLowerCase() === '#contactus' ? '/#contactus' : ''}`} />;
    }

    return <Login />;
  };

  const composeSignupRoute = () => {
    if (isUser()) {
      if (referrer && referrer !== '/signup' && referrer !== '/logout') {        
        return <Redirect to={referrer} />;
      }
      return <Redirect to={`/dashboard${location.hash && location.hash.toLowerCase() === '#contactus' ? '/#contactus' : ''}`} />;
    }

    return <Signup />;
  };

  return (
    <Switch>
      <Route exact path={['/', '/home', '/home.htm', '/home.html']} component={Home} />
      <Route exact path={'/signuphosted'} render={() => (<SignupForm hostedExternally={true} />)} />
      <Route exact path={['/carrier/:carrierId', '/dot/:dotNumber']}>
        <QuestionnaireProvider>
          <CarrierJobs/>
        </QuestionnaireProvider>
      </Route>
      <Route exact path='/job/:jobId'>
        <QuestionnaireProvider>
          <Job/>
        </QuestionnaireProvider>
      </Route>
      <Route exact path='/driver/anon/:driverId'>
        <QuestionnaireProvider>
          <Driver/>
        </QuestionnaireProvider>
      </Route>
      <Route path='/login'>
        {composeLoginRoute()}
      </Route>
      <Route path='/signup'>
        {composeSignupRoute()}
      </Route>
      <Route path='/nomobile' component={NoMobile} />
      <Route path='/privacy' component={Privacy} />
      <Route path='/checkyouremail/:email?' component={CheckYourEmail} />
      <Route path='/validate/:id/:email/:token' component={Validate} />
      <Route path='/recover/:email/:token' component={Recover}/>
      <Route path='/oops' component={Oops} />

      {/* Driver-specific routes */}
      <Route path='/driverapp' component={DriverApp} />
      <Route
        path='/validateDriverEmail/:id/:email'
        component={ValidateDriverEmail}
      />

      {/* Deep links: these will invoke the driverApp if the links are opened on the same device */}
      {/* <Route path='/driverApp' component={DriverApp} /> */}
      <Route
        path='/emailConfirmed/:email/:accessToken/:refreshToken'
        component={ValidEmailConfirmation}
      />

      <Route path='*' component={NotFound} />
    </Switch>
  );
};

export default UnprotectedRoutes;
