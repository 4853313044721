import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { TABLET_VIEWPORT_WIDTH } from '../../Typings';
import Button, { ButtonTheme } from '../common/Button';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import ConfirmEmail from '../../assets/MacBookAir-ConfirmEmail.png';
import NavbarLayout from '../layouts/NavbarLayout';
import './NoMobile.css';

const NoMobile = () => {
  const { zenDeskVisible, toggleZenDesk } = useTruckerFyApp();
  const [ mobile, setMobile ] = useState(false);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Mobile Unsupported';

    return () => { document.title = prevTitle; };
  }, []);

  const handleWindowResize = useCallback(() => {
    const isMobile = window.innerWidth < TABLET_VIEWPORT_WIDTH;
    setMobile(isMobile)
  }, []);

  useLayoutEffect(() => {
    const isMobile = window.innerWidth < TABLET_VIEWPORT_WIDTH;
    setMobile(isMobile)
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  const handleContactUs = () => {
    if (!zenDeskVisible) toggleZenDesk();
  };

  return (
    <NavbarLayout>
      <header className={`no-mobile-header${mobile ? ' mobile' : ''}`}>
        <Navbar>
          <NavLogo>
            <TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} linkAddress='/' />
          </NavLogo>
          <NavEnd>
            <Button
              theme={ButtonTheme.White}
              onClick={handleContactUs}
            >
              Contact us
            </Button>
          </NavEnd>
        </Navbar>
      </header>
      <main className={`no-mobile-main${mobile ? ' mobile' : ''} fw-3 fs-16 ls-2`}>
        <div>
          <p className={`fs-${mobile ? '32' : '38'} ls-4`} style={{ lineHeight: '150%', marginTop: '0' }}>
          Thanks for signing up!
          </p>
          <p>Unfortunately, our application does not support mobile devices at this time.  
            Please sign in to truckerfy.com using a desktop browser.</p>
          <p>We apologize for the inconvenience.</p>
        </div>
        { !mobile && <img src={ConfirmEmail} alt='Macbook' /> }
      </main>
    </NavbarLayout>
  );
};

export default NoMobile;