import React, { useEffect, useRef } from 'react';

export type TimerProps = {
  callback: React.EffectCallback,
  delay: number | null,
};

const useTimer = ({ callback, delay }: TimerProps ): React.MutableRefObject<number | null> => {
  const timeoutRef = useRef<number | null>(null);
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay === 'number') {
      timeoutRef.current = window.setInterval(() => callbackRef.current(), delay);

      return () => window.clearInterval(timeoutRef.current || 0);
    }
  }, [delay]);

  return timeoutRef;
};

export default useTimer;