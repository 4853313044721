import { ApolloError, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Carrier, JobModel, JobStatus, UNAUTHENTICATED, UserInfo } from '../Typings';
import { Mutations, Queries, getErrorMessage } from '../util/Graphql';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { getJobModels } from '../components/helper/JobHelper';
import { useTruckerFyApp } from './TruckerFyAppContext';
import { useHistory } from 'react-router-dom';
import useTimer from '../util/Timer';
import { useAuth } from "./AuthContext";

type JobsService = {
  carrier: Carrier | undefined;
  jobs: Array<JobModel>;
  activeJob: JobModel | undefined;
  setActiveJob: (job: JobModel | undefined) => void;
  updateActiveJobStatus: (status: JobStatus) => Promise<any>;
  archiveActiveJobAndSendMessageToRemainingApplicants: (message: string) => Promise<any>;
  findOrCreateConversationWithDriver: (jobId: string, driverId: string) => Promise<any>;
  refresh: () => void;
};

const JobsContext = createContext<JobsService>({} as JobsService);

const JobsProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const [ carrier, setCarrier ] = useState<Carrier>();
  const [ jobs, setJobs ] = useState<Array<JobModel>>([]);
  const [ activeJob, setActiveJobInternal ] = useState<JobModel>();
  const [ updateJobPublicationStatusFunction ] = useMutation(Mutations.UPDATE_JOB_PUBLICATION_STATUS);
  const [ archiveJobAndSendMessageToRemainingApplicantsFunction ] = useMutation(Mutations.ARCHIVE_JOB_AND_SEND_MESSAGE_TO_REMAINING_APPLICANTS);
  const [ findOrCreateConversationWithDriverFunction ] = useMutation(Mutations.FIND_OR_CREATE_CONVERSATION_WITH_DRIVER);
  const [ initializationComplete, setInitializationComplete ] = useState(false);
  const { appVisible } = useTruckerFyApp();
  const { getUserInfo, setUserInfo } = useAuth();

  // This stuff for testing sitemap code

  // const logSitemap = (data: any) => {
  //   if (data) {
  //     if (data.publishedJobs && data.publishedJobs.length > 0) {
  //       console.log( 
  //         data.publishedJobs.map(
  //           (el: any) => ({
  //             url: `/job/${el.id}`, 
  //             lastMod: `${el.lastUpdatedAt}`
  //           })
  //         )
  //       )
  //     }
  //   }
  // };

  // useQuery(
  //   Queries.GET_PUBLISHED_JOBS, 
  //   { 
  //     fetchPolicy: 'no-cache', 
  //     onError(e) {
  //       console.log(e);
  //       const errorMsg = getErrorMessage(e);
  //       history.push('/oops', { error: errorMsg });
  //     },
  //     onCompleted(data) { 
  //       logSitemap(data); 
  //     } 
  //   }
  // );

  const updateCarrier = (data: any) => {
    if (data) {
      if (data.getMyCarriers && data.getMyCarriers.length > 0) {
        let newCarrier = data.getMyCarriers[0];
        setCarrier(newCarrier);

        // Temporarily mitigate for sessions that don't have carrierId in UserInfo...
        const newUserInfo = { ...getUserInfo(), carrierId: newCarrier?.id } as UserInfo;
        setUserInfo(newUserInfo);
        // ... end temporary mitigation.

        ReactGA.set({ carrierId: newCarrier.id });
        ReactGA.event({
          category: 'meta',
          action: 'carrierId',
          label: carrier?.id, // id unique to current page load
          value: 1, // values must be integers
          nonInteraction: true, // avoids affecting bounce rate
          transport: 'xhr'
        });

        refreshJobs(newCarrier);
      } else {
        setInitializationComplete(true);
      }
    }
  };

  useQuery(
    Queries.GET_MY_CARRIERS, 
    { 
      fetchPolicy: 'no-cache', 
      onError(e) {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          history.push('/oops', { error: errorMsg });
        }
      },
      onCompleted(data) { 
        updateCarrier(data); 
      } 
    }
  );

  const [ getCarrierJobsFunction ]  = useLazyQuery(
    Queries.GET_CARRIER_JOBS, 
    { 
      fetchPolicy: 'no-cache', 
      onError(e) {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          history.push('/oops', { error: errorMsg });
        }
      },
      onCompleted(data) { 
        if (data) {
          const tempJobs = getJobModels(data);
          setJobs(tempJobs);
          setInitializationComplete(true);
        }
      } 
    }
  );

  const refreshJobs = useCallback ((localCarrier: Carrier | undefined = undefined) => {
    console.log('refreshJobs()');
    localCarrier = localCarrier ?? carrier;
    if (localCarrier) {
      getCarrierJobsFunction({
        variables: { carrierId: localCarrier.id }
      });
    };
  },[carrier, getCarrierJobsFunction]);

  const refresh = () => refreshJobs();

  useEffect(() => {
    if (appVisible)
      refreshJobs();
  }, [appVisible, refreshJobs]);

  const timerCallback = () => {
    if (appVisible)
      refreshJobs();
  };

  useTimer({ callback: timerCallback, delay: parseInt(process.env.REACT_APP_JOBS_REFRESH_INTERVAL_MS ?? '90000') }); // Default to 90 seconds

  const setActiveJob = (job: JobModel | undefined) => setActiveJobInternal(job);
  
  const updateActiveJobStatus = async (status: JobStatus) => {
    if (!activeJob) return Promise.resolve();
    
    return await updateJobPublicationStatusFunction({
      variables: {
        id: activeJob.id,
        publicationStatus: status
      }
    })
    .then(() => {
      setActiveJobInternal(previousJob => {
        if (previousJob) {
          const newJob: JobModel = {...previousJob, jobDate: undefined}; // Had to add jobDate: undefined because the spread operator isn't handling properties here
          newJob.publicationStatus = status;
          return newJob;
        }
        return undefined;
      });
    })
    .catch((e: ApolloError) => Promise.reject(e));
  };

  const archiveActiveJobAndSendMessageToRemainingApplicants = async (message: string) => {
    if (!activeJob) return Promise.resolve();
    
    return await archiveJobAndSendMessageToRemainingApplicantsFunction({
      variables: {
        id: activeJob.id,
        message: message
      }
    })
    .then(() => {
      setActiveJobInternal(previousJob => {
        if (previousJob) {
          const newJob: JobModel = {...previousJob, jobDate: undefined}; // Had to add jobDate: undefined because the spread operator isn't handling properties here
          newJob.publicationStatus = JobStatus.Archived;
          return newJob;
        }
        return undefined;
      });
    })
    .catch((e: ApolloError) => Promise.reject(e));
  };

  const findOrCreateConversationWithDriver = async (jobId: string, driverId: string) => {
    return await findOrCreateConversationWithDriverFunction({
      variables: {
        jobId: jobId,
        driverId: driverId
      }
    })
    .then((res: any) => {
      return res.data.findOrCreateConversationWithDriver.id;
    })
    .catch((e: ApolloError) => Promise.reject(e));
  };

  return (
    <JobsContext.Provider
      value={{
        carrier,
        jobs,
        activeJob,
        setActiveJob,
        updateActiveJobStatus,
        archiveActiveJobAndSendMessageToRemainingApplicants,
        findOrCreateConversationWithDriver,
        refresh
      }}
    >
      { initializationComplete && children }
    </JobsContext.Provider>
  );
};

const useJobs = () => {
  const context = useContext(JobsContext);
  return context;
}

export { JobsProvider, useJobs };
