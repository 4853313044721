import { Chart, ChartMeta, Element, PointElement, LineController, Interaction, ChartTypeRegistry } from 'chart.js';
import { AnyObject } from 'chart.js/types/basic';

type ChartMetaWithPt = {
  _pt: PointElement | null;
} & ChartMeta<Element<AnyObject, AnyObject>, Element<AnyObject, AnyObject>, keyof ChartTypeRegistry>;

// Draws an enhanced tooltip and ball point along the curve follows the mouse cursor
(Interaction.modes as any).interpolate = function (chart: any, e: any, option: any) {
  const x = e.x;
  const items = [];
  const metas = chart.getSortedVisibleDatasetMetas();
  const indicatorOptions = {
    radius: 4,
    borderWidth: 2.4,
    borderColor: 'var(--forest-green)',
    backgroundColor: 'white'
  };
  
  for (let i = 0; i < metas.length; i++) {
    const meta = metas[i];
    const pt = meta.dataset.interpolate({ x }, 'x');
    if (pt) {
      const element = new PointElement({...pt, options: {...indicatorOptions}});
      meta._pt = element;
      items.push({element, index: -1, datasetIndex: meta.index });
    } else {
      meta._pt = null;
    }
  }
  return items;
};

// @ts-ignore
const getLabelAndValue = LineController.prototype.getLabelAndValue;
(LineController.prototype as any).getLabelAndValue = function(index: number) {
  if (index === -1) {
    const meta = this.getMeta();
    const pt = meta._pt;
    const vScale = meta.vScale;
    return {
      label: this.chart.config.options.interpolatedPointLabel,
      value: Math.round(+vScale.getValueForPixel(pt.y)*10)/10
    };
  }
  return getLabelAndValue.call(this, index);
};

const InterpolatedPointChartPlugin = {
  id: 'interpolatedPoint',
  afterDraw(chart: Chart) {
    const metas = chart.getSortedVisibleDatasetMetas() as ChartMetaWithPt[];
    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];
      if (meta._pt) {
        meta._pt.draw(chart.ctx);
      }
    }    
  },

  afterEvent(chart: Chart, args: any) {
    if (args.event.type === 'mouseout') {
      const metas = chart.getSortedVisibleDatasetMetas() as ChartMetaWithPt[];

      for (let i = 0; i < metas.length; i++) {       
        metas[i]._pt = null;
      }
      args.changed = true;
    }
  }
};

export default InterpolatedPointChartPlugin;