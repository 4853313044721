import { FieldInputProps, FormikProps } from 'formik';
import FormFieldMessage from '../../common/FormFieldMessage';
import Select from '../../common/Select';
import ValidationErrorSvg from '../../../assets/ValidationError.svg';
import './SelectAnswer.css';

export type SelectAnswerProps = {
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  placeholderText: string;
  dropdownWidth: number;
  choicesJson: Array<any>;
};

const SelectAnswer = ({ field, form, placeholderText, dropdownWidth, choicesJson }: SelectAnswerProps) => {
  const isInvalid = () => form.touched[field.name] && form.errors[field.name];

  return (
    <>
      <Select
        field={field}
        form={form}
        suppressValidation={true}
        className='select-answer'     
        style={{ width: `${dropdownWidth}px` }}
        required
      >
        <option key='0' value='' hidden>{placeholderText}</option>
        {
          choicesJson.map((item: any, index: number) => <option key={index + 1} value={item.id}>{item.text}</option>)
        }
      </Select>
      {
        isInvalid() && 
          <div className='question-answer-validation'>
            <img src={ValidationErrorSvg} alt='Validation' />
            <FormFieldMessage text={form.errors[field.name]} />
          </div>
      }
    </>
  );
};

export default SelectAnswer;