class Questionnaire {
  private _json: any;
  private _questions: any;

  constructor(json: any) {
    this._json = json;
  }

  get json(): any {
    return this._json;
  }

  get questions(): Array<any> {
    if (!this._questions) {
      this._questions = new Array<any>();
      for (const s of Object.keys(this._json.sections)) {
        if (this._json.sections[s].columns) {
          for (const c of this._json.sections[s].columns) {
            for (const q of c.questions) {
              this._questions.push(q);
            }
          }
        } else {
          for (const q of this._json.sections[s].questions) {
            this._questions.push(q);
          }
        }
      }
    }
    return this._questions;
  }

  getQuestion(id: string): any {
    return this.questions.find(q => q.id === id);
  }

  getBooleanAnswer(questionId: string, answers: any, defaultVal: boolean | undefined = undefined): boolean | undefined {
    const answer = this.getSingleAnswerValue(questionId, answers, defaultVal);
    return answer === false 
      ? false 
      : answer
        ? ((answer === true || answer === 'yes') ? true : ((answer === 'no') ? false : undefined)) // By this logic, if answer === 'someRandomText', return undefined (rather than false)
        : defaultVal;
  }

  getSingleAnswerValue(questionId: string, answers: any, defaultVal?: any): any | undefined {
    let answer = answers?.find((a: any) => a.questionId === questionId)?.answer;
    if (Array.isArray(answer)) {
      answer = (answer.length > 0) ? answer[0] : null;
    }
    return answer === false ? false : (answer || defaultVal);
  }

  getSingleAnswerText(questionId: string, answers: any, defaultVal?: string): string | undefined {
    const answer = this.getSingleAnswerValue(questionId, answers, defaultVal);
    let text = null;
    if (answer || answer === false) {
      const choices = this.getQuestion(questionId)?.choices;
      if (choices) {
        text = choices.find((c: any) => c.id === answer)?.text ?? ((answer === true || answer === 'yes') ? 'Yes' : (answer === false || answer === 'no') ? 'No' : answer);
      } else {
        text = (answer === true || answer === 'yes') ? 'Yes' : (answer === false || answer === 'no') ? 'No' : answer;
      }
    }
    return text || defaultVal;
  }

  getMultipleAnswerTextArray(questionId: string, answers: any): Array<string> {
    let answer = answers?.find((a: any) => a.questionId === questionId)?.answer;
    let textValues = new Array<string>();
    if (answer || answer === false) {
      const choices = this.getQuestion(questionId)?.choices;
      (Array.isArray(answer) ? answer : new Array<any>(answer)).forEach(a => textValues.push(choices?.find((c: any) => c.id === a)?.text ?? a));
    } 
    return textValues;
  }

  getMultipleAnswerIdArray(questionId: string, answers: any): Array<string> {
    let answer = answers?.find((a: any) => a.questionId === questionId)?.answer;
    let textValues = new Array<string>();
    if (answer || answer === false) {
      const choices = this.getQuestion(questionId)?.choices;
      (Array.isArray(answer) ? answer : new Array<any>(answer)).forEach(a => textValues.push(choices?.find((c: any) => c.id === a)?.id ?? a));
    } 
    return textValues;
  }

  getMultipleAnswerTextCsv(questionId: string, answers: any, defaultVal?: string): string | undefined {
    let value = this.getMultipleAnswerTextArray(questionId, answers)?.join(', ');
    return (value.length > 0) ? value : defaultVal;
  }
};

export default Questionnaire;