import React, { createContext, useContext, useState } from 'react';
import { CandidateSort, JobCandidateSort, JobsSort, SortDirection } from '../Typings';

export enum PreferenceType {
  CandidateSort,
  JobCandidateSort,
  JobsDraftSort,
  JobsArchivedSort,
  JobsPublishedSort,
  JobsFilterIndex,
  JobsTabIndex
};

type CandidateSortType = Array<{ type: CandidateSort, sort: SortDirection, checked: boolean }>;
type JobCandidateSortType = Array<{ type: JobCandidateSort, sort: SortDirection, checked: boolean }>;
type JobsSortType = Array<{ type: JobsSort, sort: SortDirection, checked: boolean }>;

interface PreferenceInfo {
  dummyValue: number;
};

interface Preference {
  getPreference(type: PreferenceType): any;
  setPreference(type: PreferenceType, preference: any): void;
  deletePreference(type: PreferenceType): void;
};

const PreferenceContext = createContext<Preference>({} as Preference);

const convertToCamelCase = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

const PreferenceProvider = ({ children }: { children: React.ReactNode }) => {

  const [preferenceState, setPreferenceState] = useState<PreferenceInfo>({ dummyValue: 0 });

  const defaultCandidateSort : CandidateSortType = [
    { type: CandidateSort.LastMessage, sort: SortDirection.Desc, checked: true },
    { type: CandidateSort.Matching, sort: SortDirection.Asc, checked: true },
    { type: CandidateSort.JobTitle, sort: SortDirection.Asc, checked: false },
    { type: CandidateSort.Status, sort: SortDirection.Asc, checked: false },
    { type: CandidateSort.Name, sort: SortDirection.Asc, checked: false },
    { type: CandidateSort.CDL, sort: SortDirection.Asc, checked: false }
  ];

  const defaultJobCandidateSort : JobCandidateSortType = [
    { type: JobCandidateSort.LastMessage, sort: SortDirection.Desc, checked: true },
    { type: JobCandidateSort.Matching, sort: SortDirection.Asc, checked: true },
    { type: JobCandidateSort.Status, sort: SortDirection.Asc, checked: false },
    { type: JobCandidateSort.Name, sort: SortDirection.Asc, checked: false },
    { type: JobCandidateSort.CDL, sort: SortDirection.Asc, checked: false }
  ];

  const defaultJobsDraftSort : JobsSortType = [
    { type: JobsSort.JobDate, sort: SortDirection.Desc, checked: true },
    { type: JobsSort.PostingTitle, sort: SortDirection.Asc, checked: false },
    { type: JobsSort.InternalTitle, sort: SortDirection.Asc, checked: false }
  ];

  const defaultJobsPublishedSort : JobsSortType = [
    { type: JobsSort.JobDate, sort: SortDirection.Desc, checked: true },
    { type: JobsSort.PostingTitle, sort: SortDirection.Asc, checked: false },
    { type: JobsSort.InternalTitle, sort: SortDirection.Asc, checked: false }
  ];

  const defaultJobsArchivedSort : JobsSortType = [
    { type: JobsSort.JobDate, sort: SortDirection.Desc, checked: true },
    { type: JobsSort.PostingTitle, sort: SortDirection.Asc, checked: false },
    { type: JobsSort.InternalTitle, sort: SortDirection.Asc, checked: false }
  ];

  const defaultJobsFilterIndex : number = 0; // Grid (default), GridView = 0, TileView = 1

  const defaultJobsTabIndex : number = 1; // Published (default), Draft = 0, Published = 1, Archived = 2

  const getDefaultPreference = (prefType: PreferenceType) => {
    switch (prefType) {
      case PreferenceType.CandidateSort:
        return defaultCandidateSort;
      case PreferenceType.JobCandidateSort:
        return defaultJobCandidateSort;
      case PreferenceType.JobsDraftSort:
        return defaultJobsDraftSort;
      case PreferenceType.JobsPublishedSort:
        return defaultJobsPublishedSort;
      case PreferenceType.JobsArchivedSort:
        return defaultJobsArchivedSort;
      case PreferenceType.JobsFilterIndex:
        return defaultJobsFilterIndex;
      case PreferenceType.JobsTabIndex:
        return defaultJobsTabIndex;
    };
  };

  const getPreference = (prefType: PreferenceType) => {
    const prefName = convertToCamelCase(PreferenceType[prefType]);
    const valueStr = localStorage.getItem(prefName);
    const value = valueStr === null ? null : JSON.parse(valueStr);
    return value ?? getDefaultPreference(prefType);
  };

  const setPreference = (prefType: PreferenceType, value: any) => {
    const newState = {...preferenceState};
    
    localStorage.setItem(convertToCamelCase(PreferenceType[prefType]), JSON.stringify(value));

    newState.dummyValue = 1 - newState.dummyValue;
    setPreferenceState(newState);
  };

  const deletePreference = (prefType: PreferenceType) => {
    localStorage.removeItem(convertToCamelCase(PreferenceType[prefType]));
  };

  return (
    <PreferenceContext.Provider
      value={{
        getPreference,
        setPreference,
        deletePreference
      }}
    >
      { children }
    </PreferenceContext.Provider>
  );
};

const usePref = () => {
  const context = useContext(PreferenceContext);
  return context;
};

export { PreferenceProvider, usePref };
export type { PreferenceInfo };