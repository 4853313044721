import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../context/ModalContext';
import { useAuth } from '../../context/AuthContext';
import { CheckYourEmailMode } from './CheckYourEmail';
import { MOBILE_VIEWPORT_WIDTH } from '../../Typings';
import SignupDialog from '../dialogs/Signup';
import NavbarLayout from '../layouts/NavbarLayout';
import Navbar, { NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import SignupForm from '../shared/SignupForm';
import Loader from '../transition/Loader';
import './Signup.css';

const Signup = () => {
  // eslint-disable-next-line
  const [ mobile, setMobile ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  // eslint-disable-next-line
  const [ mode, setMode ] = useState({mobile: false, composed: false});  
  const history = useHistory();
  const { compose, destroy } = useModal();
  const { login } = useAuth();

  const handleLogin = useCallback((payload: any, persist: boolean) => {
    destroy();
    const expiresAt = new Date(payload.tokens.expiresAt);
    login({
      accessToken: payload.tokens.accessToken,
      refreshToken: payload.tokens.refreshToken,
      expiresAt: expiresAt,
      userInfo: {
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        photoUrl: payload.photoUrl,
        carrierId: payload.carrierRoles.length > 0 ? payload.carrierRoles[0].carrier.id : null,
        roles: payload.carrierRoles.map((carrierRole: any) => carrierRole.role)
      }
    }, persist);
    // Note - Redirection to the referrer or dashboard occurs via UnprotectedRoutes.tsx
  }, [destroy, login]);

  const handleLoading = (isLoading: boolean) => setLoading(isLoading);

  const handleSignup = useCallback((email: string) => {
    destroy();
    history.push('/checkyouremail', { mode: CheckYourEmailMode.VerifyEmail, email: email });
  }, [destroy, history]);
  
  const handleCloseButtonClick = useCallback(() => {
    destroy();
    history.push('/');
  }, [destroy, history]);

  const composeSignup = useCallback(() => {
    compose(<SignupDialog handleLogin={handleLogin} handleSignup={handleSignup} isModalSubscriber={true} preserveOnClose={true} handleCloseButtonClick={handleCloseButtonClick} />);
    mode.composed = true;
  }, [ handleSignup]); // eslint-disable-line

  const handleWindowResize = useCallback(() => {
    const isMobile = window.innerWidth < MOBILE_VIEWPORT_WIDTH;
    mode.mobile = isMobile;
    setMobile(isMobile);
  }, []); // eslint-disable-line

  useEffect(() => {
    handleWindowResize();
  }, [handleWindowResize]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  useEffect(() => {
    if (!mode.mobile) {
      composeSignup();
    }
    else if (mode.composed) {
      destroy();
      mode.composed = false;
    }
  }, [mode.mobile, mode.composed, composeSignup, destroy]); // eslint-disable-line

  if (mode.mobile) {
    return (
      <>
        { loading && <Loader className='signup-loader' /> }
        <NavbarLayout>
          <header className='signup-header'>
            <Navbar>
              <NavLogo>
                <TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} linkAddress='/' />
              </NavLogo>
            </Navbar>
          </header>
          <main className='signup-main'>
            <div>
              <div className='signup-main-top'>
                <p>Great!&nbsp;&nbsp;Let's get started.</p>
              </div>
              <SignupForm handleLoading={handleLoading} handleLogin={handleLogin} handleSignup={handleSignup} />
            </div>
          </main>
        </NavbarLayout>
      </>
    );
  }

  return (
    <></>
  );
};

export default Signup;