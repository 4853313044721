import { useState, ReactNode, PropsWithChildren, HTMLAttributes, MouseEvent, useEffect } from 'react';
import Divider from './Divider';
import './Tabbar.css';

export type TabProps = {
   caption: ReactNode;
   dataIndex?: number;
   className?: string;
   activeClassName?: string;
   onClick?: (e: MouseEvent<HTMLLIElement>) => void;
};

const Tab = ({ caption, className, dataIndex, onClick }: PropsWithChildren<TabProps>) => {
  const getClass = () => `tabbar-list-item${className ? ` ${className}` : ''}`;

  return (
    <li className={getClass()} data-index={dataIndex} onClick={onClick}>{caption}</li>
  );
};

interface ITabbarProps extends HTMLAttributes<HTMLDivElement> {
  tabsMeta: TabProps[];
  activeTabIndex?: number;
  filter?: ReactNode;
  activeClassName?: string;
  onActiveTabChange?: (index: number) => void;
  children?: never;
};

const Tabbar = ({ tabsMeta, activeTabIndex, filter, activeClassName, onActiveTabChange = () => {}, ...props }: ITabbarProps) => {
  const [ activeTabIndexInternal, setActiveTabIndexInternal ] = useState(activeTabIndex ?? 0);

  useEffect(() => { setActiveTabIndexInternal(activeTabIndex ?? 0) }, [activeTabIndex]);

  const handleTabClick = (e: MouseEvent<HTMLLIElement>) => {
    const indexStr = e.currentTarget.getAttribute('data-index');
    if (indexStr) {
      setActiveTabIndexInternal(+indexStr);
      onActiveTabChange(+indexStr);
    }
  };

  return (
    <div {...props}>
      <div className='tabbar-container'>
        <ol className='tabbar-list'>
          {
            tabsMeta.map((tab: TabProps, index: number) =>
              <Tab 
                key={`job-view-tab-${index}`} 
                caption={tab.caption} 
                className={ (activeTabIndexInternal === index) ? (tab.activeClassName ?? activeClassName) : undefined } 
                dataIndex={index} 
                onClick={handleTabClick} />
            )
          }
        </ol>
        {
          filter ?? <div className='tabbar-filter'>{filter}</div>
        }        
      </div>
      <Divider className='tabbar-divider' />
    </div>
  );
};

export default Tabbar;