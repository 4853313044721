/* This function adds a <script type="application/ld+json"> object to the page header
 * This enables Google to index the page as a "JobPosting", enabling semantic search and display in Google job listings
 * https://developers.google.com/search/docs/appearance/structured-data/job-posting
 * https://schema.org/JobPosting
 */
import { Carrier as tCarrier, JobModel as tJob } from '../../../Typings';

import { Helmet } from 'react-helmet-async';
import { JobPosting } from 'schema-dts';
import React from 'react';
import { helmetJsonLdProp } from 'react-schemaorg';

const cloudinaryRoot =
  'https://res.cloudinary.com/truckerfy/image/upload/c_thumb,h_256,w_256/v1/'; // 256px square

interface Props {
  job: tJob;
  carrier: tCarrier;
}

interface iQuestion {
  questionId: string;
  answer: string | string[] | number | number[];
}

export const JobLdJson: React.FC<Props> = ({ carrier, job }) => {
  const { title, description, requirements, publishedAt } = job;
  if (!carrier || !publishedAt) return null;
  const {
    name,
    photoUrl,
    postalCode,
    city: addressLocality,
    street1: streetAddress,
    state: addressRegion,
    url: sameAs,
  } = carrier;

  // extract other parts of the job that need some processing

  // publishedAt seems to arrive as a string when it should be a date. Deal with the ambiguity.
  let datePosted =
    typeof publishedAt === 'string'
      ? publishedAt
      : publishedAt && publishedAt.toISOString();
  datePosted = datePosted.substring(0, 10);

  const employmentType = requirements.find(
    (q: iQuestion) => q.questionId === 'employeeType'
  )?.answer?.toString()?.toUpperCase() || 'W2';
  const income =
    requirements.find((q: iQuestion) => q.questionId === 'income')?.answer[0] ||
    0;
  const logo = photoUrl ? `${cloudinaryRoot}${photoUrl}` : '';
  let validThrough = new Date();
  validThrough.setMonth(validThrough.getMonth() + 2);

  // return the Helmet object - this will be inserted automatically into the <head> of the page
  return (
    <Helmet
      script={[
        helmetJsonLdProp<JobPosting>({
          '@context': 'https://schema.org',
          '@type': 'JobPosting',
          title,
          description,
          datePosted,
          validThrough: validThrough.toISOString().substring(0,10),
          directApply: false,
          employmentType,
          hiringOrganization: {
            '@type': 'Organization',
            name,
            sameAs,
            logo,
          },
          jobImmediateStart: true,
          jobLocation: {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              streetAddress,
              addressLocality,
              addressRegion,
              postalCode,
              addressCountry: 'US',
            },
          },
          baseSalary: {
            '@type': 'MonetaryAmount',
            currency: 'USD',
            value: {
              '@type': 'QuantitativeValue',
              value: income,
              unitText: 'MONTH',
            },
          },
          occupationalCategory: '53-3032.00',
        }),
      ]}
    />
  );
};
