export const MOBILE_VIEWPORT_WIDTH = 660;// 624;
export const MINI_TABLET_VIEWPORT_WIDTH = 788;
export const TABLET_VIEWPORT_WIDTH = 920;
export const UNAUTHENTICATED = 'UNAUTHENTICATED';

interface UserInfo {
  id: string
  lastName: string
  firstName: string
  email: string
  photoUrl: string
  carrierId: string
  roles: Array<string>
}

type Carrier = {
  id: string;
  name: string;
  dotNumber: string;
  postalCode: string;
  ou?: string;
  description?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  url?: string;
  profile?: string;
  photoUrl?: string;
  parentId?: string;
  bannerPhoto?: string;
};

type Job = {
  id: string;
  carrierId: string;
  title?: string;
  internalTitle?: string;
  internalReference?: string;
  description?: string;
  requirements?: any;
  publishedAt?: Date;
  createdAt?: Date,
  lastUpdatedAt?: Date
};

export enum JobStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED'
};

type JobApplicantSummary = {
  new: number;
  reviewed: number;
  rejected: number;
};

export class JobModel {
  constructor(
    id: string,
    createdAt: Date,
    title: string,
    internalTitle?: string,
    internalReference?: string,
    description?: string,
    jobApplicantSummary?: JobApplicantSummary,
    publicationStatus = JobStatus.Draft,
    publishedAt?: Date,
    requirements?: any,
    favoritesByDate?: Array<{date: Date, count: number}>,
    viewsByDate?: Array<{date: Date, anonymousViews: number, knownViews: number}>,
    numCandidates?: number,
    numUnreadMessagesForCarrier?: number,
    numFavorites?: number,
    jobInterests?: Array<any>,
    lastUpdatedAt?: Date
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.title = title;
    this.internalTitle = internalTitle;
    this.internalReference = internalReference;
    this.description = description;
    this.jobApplicantSummary = jobApplicantSummary;
    this.publicationStatus = publicationStatus;
    this.publishedAt = publishedAt;
    this.requirements = requirements;
    this.favoritesByDate = favoritesByDate;
    this.viewsByDate = viewsByDate;
    this.numCandidates = numCandidates;
    this.numUnreadMessagesForCarrier = numUnreadMessagesForCarrier;
    this.numFavorites = numFavorites;
    this.jobInterests = jobInterests;
    this.lastUpdatedAt = lastUpdatedAt;
  }
  
  id = '';
  createdAt: Date = new Date();
  title = '';
  internalTitle?: string;
  internalReference?: string;
  description?: string;
  jobApplicantSummary?: JobApplicantSummary;
  publicationStatus = JobStatus.Draft;
  publishedAt?: Date;
  requirements?: any;
  favoritesByDate?: Array<{date: Date, count: number}>;
  viewsByDate?: Array<{date: Date, anonymousViews: number, knownViews: number}>;
  numCandidates?: number;
  numUnreadMessagesForCarrier?: number;
  numFavorites?: number;
  jobInterests?: Array<any>;
  lastUpdatedAt?: Date;
  
  get jobDate() {
    return this.publicationStatus as JobStatus === JobStatus.Draft 
      ? this.createdAt
      : this.publicationStatus as JobStatus === JobStatus.Published
        ? this.publishedAt
        : this.lastUpdatedAt;
  }
};

export interface IJobCandidate {
  driver: any;
  matchScore: MatchScore | undefined;
};

export enum JobInterestStatus {
  Favorite = 'FAVORITE',
  Applicant = 'APPLICANT',
  NotInterested = 'NOT_INTERESTED',
  Rejected = 'REJECTED',
  OfferMade = 'OFFER_MADE',
  Hired = 'HIRED',
  Suggested = 'SUGGESTED'
};

type MatchScore = {
  score: number;
  label: string;
  color: string;
}

type JobInterestModel = IJobCandidate & {
  id: string;
  jobId: string;
  matchScore: MatchScore;
  status: string;
  displayStatus: string;
  jobTitle: string;
  driverName: string;
  cdl: string;
  endorsements: string;
  experience: string;
  workRoutes: string;
  driver?: any;
  conversation?: { id: string, lastMessage?: { from: { id: string }, createdAt: any, readAt: any }, messages: [{ id: string }] };
  driverIsMessageable: Boolean;
};

type ConversationModel = IJobCandidate & {
  id: string;
  job?: any;
  driver?: any;
  driverIsMessageable: Boolean;
  jobInterest?: any;
  lastMessage?: any;
  matchScore?: MatchScore;
};

type MessageModel = {
  id: string;
  from?: any;
  to?: any;
  message: string;
  createdAt: any;
  readAt: any;
};

export enum Area {
  Jobs,
  Candidates,
  Conversations
};

export enum Color {
  DarkGrey = '#57585B',
  MediumGrey = '#898A8D',
  MapSelectedRegionFillGreen = '#2CDDC0',
  MapSelectedRegionStrokeBlue = '#ECF0F3',
  White = '#FFFFFF'
};

export enum JobTab {
  Overview,
  Candidates,
  Conversations
};

export enum EdgeAlignment {
  Left, // Line up left edge of container with left edge of root element (default)
  Right
};

export enum SortDirection {
  Asc,
  Desc
};

export enum CandidateSort {
  Name,
  JobTitle,
  LastMessage,
  Status,
  Matching,
  CDL
};

export enum JobCandidateSort {
  Name,
  LastMessage,
  Status,
  Matching,
  CDL
};

export enum JobsSort {
  JobDate,
  PostingTitle,
  InternalTitle
}

export type { UserInfo, Carrier, Job, JobApplicantSummary, MatchScore, JobInterestModel, ConversationModel, MessageModel };
