import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Prompt, useParams, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as TruckFront } from '../../assets/TruckFront.svg';
import { FastField, Form, Formik } from 'formik';
import { Job, UNAUTHENTICATED } from '../../Typings';
import { deepEqual } from '../../util/ObjectUtil';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuth } from '../../context/AuthContext';
import { useJobs } from '../../context/JobsContext';
import { getErrorMessage, Mutations, Queries } from '../../util/Graphql';
import { PersonAvatar } from '../specialized/cloudinary/PersonAvatar';
import * as Yup from 'yup';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo from '../common/TruckerFyLogo';
import NavbarLayout from '../layouts/NavbarLayout';
import Input from '../common/Input';
import Button from '../common/Button';
import Divider from '../common/Divider';
import Loader from '../transition/Loader';
import Textarea from '../common/Textarea';
import './EditJob.css';

const EditJobSchema = Yup.object().shape({
  title: Yup.string().required('Posting Title is required'),
});

const EditJob = () => {
  const message = '\nLeave page?\n\nChanges you made may not be saved.';
  const { getUserInfo } = useAuth();
  const [ createJobFunction, { loading: createJobLoading } ] = useMutation(Mutations.CREATE_JOB);
  const [ updateJobFunction, { loading: updateJobLoading } ] = useMutation(Mutations.UPDATE_JOB);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ carrierId, setCarrierId ] = useState<string>('');
  const [ job, setJob ] = useState<Job>();
  const [ hasPendingChanges, setHasPendingChanges ] = useState(false);
  const [ isClone, setIsClone ] = useState<boolean | undefined>(false);
  const history = useHistory();
  const location = useLocation<any>();
  const formikRef = useRef(null);
  const { jobId } = useParams<{ jobId: string | undefined }>();
  const { carrier } = useJobs();

  const fetchJob = (data: any) => {
    if (jobId && data) {
      const json = data.getJob;
      const job = {
        id: jobId,
        carrierId: carrierId,
        title: json.title,
        internalTitle: json.internalTitle,
        internalReference: json.internalReference,
        description: json.description,
        requirements: json.requirements
      }
      setJob(job);
    }
  };

  const [ getJobFunction, { loading: getJobLoading }] = useLazyQuery(
    Queries.GET_JOB,
    { 
      fetchPolicy: 'no-cache',
      onError(e) {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          history.push('/oops', { error: errorMsg });
        }
      },
      onCompleted(data) { 
        fetchJob(data);
      } 
    }
  );

  const handleWindowBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
    if (hasPendingChanges) {
      e.preventDefault();
      return e.returnValue = 'Are you sure you want to exit?'
    }
  }, [hasPendingChanges]);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Edit Job';

    return () => { document.title = prevTitle; };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleWindowBeforeUnload);
  }, [hasPendingChanges]); // eslint-disable-line

  // Via internal redirect
  useLayoutEffect(() => {
    if (location.state?.job) { // Existing job
      setJob(location.state.job);
      setCarrierId(location.state.job.carrierId);
      setIsClone(location.state?.isClone);
    } else if (location.state?.carrierId) { // New job
      setCarrierId(location.state.carrierId);
    }
  }, []); // eslint-disable-line

  // Via external link
  useLayoutEffect(() => {
    if (carrier) {
      setCarrierId(carrier.id);
      if (!location.state?.job && jobId) { // Existing job (external link)
        getJobFunction({
          variables: { id: jobId }
        });
      }
    }
  }, [carrier]); // eslint-disable-line

  const handleSubmit = (createdOrUpdatedjob: Job) => {
    document.body.style.cursor = 'wait';
    const jobFunction = job ? updateJobFunction : createJobFunction;
    const jobFunctionVars = job
      ? {
          id: createdOrUpdatedjob.id,
          title: createdOrUpdatedjob.title,
          internalTitle: createdOrUpdatedjob.internalTitle,
          internalReference: createdOrUpdatedjob.internalReference,
          description: createdOrUpdatedjob.description
        }
      : {
          carrierId: createdOrUpdatedjob.carrierId,
          title: createdOrUpdatedjob.title,
          internalTitle: createdOrUpdatedjob.internalTitle,
          internalReference: createdOrUpdatedjob.internalReference,
          description: createdOrUpdatedjob.description
        };
    jobFunction({
      variables: jobFunctionVars
    })
    .then((res: any) => {
      setErrorMessage('');
      setHasPendingChanges(false);
      const targetJob = job ? res.data.updateJob : res.data.createJob;
      history.push('/questionnaire/job/1', { targetId: targetJob?.id, savedAnswers: targetJob?.requirements, isEditMode: job && !isClone, isQuestionnaire: true });
    })
    .catch(e => {
      let errorMsg = getErrorMessage(e);
      if (errorMsg === UNAUTHENTICATED) {
        history.push('/login', { isAuthExpired: true });
      } else {
        errorMsg = errorMsg === 'duplicate' ? 'Job already exists' : errorMsg;
        setErrorMessage(`Unable to save.  ${errorMsg}`);
      }
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  const composeHeaderText = () => {
    if (jobId) {
      return (
        <>
          <span className='edit-job-main-text'>Welcome back, {getUserInfo()?.firstName}!  Let's edit this Job!</span><br/>
          <span className='edit-job-main-subtext'>Be sure to include details about your job to help you find the perfect truck driver matches.</span>
        </>
      );
    }

    return (
      <>
        <span className='edit-job-main-text'>Great, {getUserInfo()?.firstName}!  Let's create a Job!</span><br/>
        <span className='edit-job-main-subtext'>We'll ask you details about your job to help you find the perfect truck driver matches.</span>
      </>
    );
  };

  const validate = (values: any) => {
    if (formikRef.current) {
      const current: any = formikRef.current;
      const initialValues = current.initialValues;
      const isEqual = deepEqual(initialValues, values)
      setHasPendingChanges(!isEqual);
    }
    return {};
  };

  const isLoading = () => createJobLoading || updateJobLoading || getJobLoading;

  const composeFormik = () => {
    return (carrierId !== '' && (!jobId || job)) &&
      <Formik
        innerRef={formikRef}
        initialValues={{
          id: job?.id ?? '',
          carrierId: job?.carrierId ?? carrierId,
          title: job?.title,
          internalTitle: job?.internalTitle,
          internalReference: job?.internalReference,
          description: job?.description,
          requirements: job?.requirements
        }}
        onSubmit={jobValues => handleSubmit(jobValues)}
        validationSchema={EditJobSchema}
        validate={validate}
      >
        {() => (
          <Form className='edit-job-main-form' style={isLoading() ? {opacity: '0.4'} : {}}>
            <table style={{ width: '900px' }}>
              <tbody>
                <tr><td><span>Posting Title</span><FastField autoFocus component={Input} name='title' placeholder='Posting Title' type='text' maxLength={50} /></td></tr>
                <tr><td><span>Internal Title</span><FastField component={Input} name='internalTitle' placeholder='Internal Title' type='text' maxLength={50} /></td></tr>
                <tr><td><span>Description</span><FastField component={Textarea} name='description' placeholder='Job description' rows={10} /></td></tr>
                { errorMessage !== '' && <tr><td className='error-message'><span style={{color: 'darkred'}}>{errorMessage}</span></td></tr> }
              </tbody>
            </table>
            <footer>
              <Button disabled={(createJobLoading || updateJobLoading)} type='submit'>CONTINUE</Button>
            </footer>
          </Form>
        )}
      </Formik>;
  };

  return (
    <>
      <Prompt when={hasPendingChanges} message={message} />
      <NavbarLayout>
        { isLoading() && <Loader /> }
        <header className='edit-job-header'>
          <Navbar>
            <NavLogo><TruckerFyLogo /></NavLogo> 
            <NavEnd>
              <span style={{color: 'white', paddingRight: '10px'}}>{getUserInfo()?.firstName} {getUserInfo()?.lastName}</span>
              <PersonAvatar publicId={getUserInfo()?.photoUrl} size={44} />
            </NavEnd>
          </Navbar>
        </header>
        <main className='edit-job-main'>
          <div>
            <div>
              <p>
                <TruckFront className='edit-job-main-image' />
                { composeHeaderText() }
              </p>
              <Divider className='edit-job-main-divider' />
            </div>
            { composeFormik() }
          </div>
        </main>
      </NavbarLayout>
    </>
  );
};

export default EditJob;