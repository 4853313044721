import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';

interface PersonAvatarProps {
  publicId?: String;
  size?: number;
  className?: String;
  onClick?: any;
  blur?: boolean;
};

const defaultPersonAvatarUrl = process.env.REACT_APP_CLOUDINARY_DEFAULT_AVATAR?.length ? process.env.REACT_APP_CLOUDINARY_DEFAULT_AVATAR : 'avatars/production/mjaqzahargzqtnmvnrzw.png';

export const PersonAvatar = ({ publicId = defaultPersonAvatarUrl, size = 40, className, onClick, blur = false }: PersonAvatarProps) => {
  if (!publicId?.length) {
    publicId = defaultPersonAvatarUrl;
  }
  return (
    <CloudinaryContext cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD}>
      <Image
        publicId={publicId}
        // loading='lazy'
        className={className}
        onClick={onClick}
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          borderWidth: 1
        }}
      >
        <Transformation
          width={size}
          height={size}
          gravity='face'
          radius='max'
          crop='thumb'
          effect={blur ? 'blur:250' : 'blur:0'}
        />
      </Image>
    </CloudinaryContext>
  );
};