import { HTMLAttributes, ReactNode, useState, useEffect } from 'react';
import {ReactComponent as ArrowRightSvg} from '../../assets/ArrowRight.svg'
import './Slideout.css';

export enum Side {
  Left,
  Right
}

export interface ISlideoutProps extends HTMLAttributes<HTMLDivElement> {
  side?: Side;
  inline?: boolean;
  showInitially: boolean;
  toggle?: boolean;
  toggleOn?: boolean; // Slideout is extended but can only be retracted by the internal close btn
  onVisibleChange?: (isVisible: boolean) => void
  captionChildren?: ReactNode;
  children: ReactNode;
};

const Slideout = ({ side = Side.Right, inline = false, showInitially, toggle, toggleOn, onVisibleChange = () => {}, captionChildren, children, ...props }: ISlideoutProps) => {
  const [ isVisible, setVisible ] = useState(showInitially);
  const getClassName = () => `slideout${inline ? ' inline' : ''} ${side === Side.Right ? 'right' : 'left'}${isVisible ? ' show' : ''}${props.className ? ` ${props.className}` : ''}`;

  useEffect(() => {
    if (toggleOn !== undefined)
      setVisible(true);
    else if (toggle !== undefined)
      setVisible(prevValue => !prevValue);
  }, [toggle, toggleOn]);

  useEffect(() => onVisibleChange(isVisible), [isVisible, onVisibleChange]);

  const handleCloseButtonClick = () => setVisible(false);

  return (
    <div {...props} className={getClassName()}>
      <div className='slideout-caption-container'>
        <button className='slideout-btn' onClick={handleCloseButtonClick}>
          <ArrowRightSvg />
        </button>
        <div className='slideout-caption-container-custom'>
          {captionChildren}
        </div>
      </div>
      <div className='slideout-content-container'>
        {children}
      </div>
    </div>
  );
};

export default Slideout;