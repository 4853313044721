import * as Yup from 'yup';
import { useState, MouseEvent, useEffect, useRef, useCallback } from 'react';
import { Form, Formik, FastField } from 'formik';
import { ApolloError, useMutation } from '@apollo/client';
import { getErrorMessage, Mutations } from '../../util/Graphql';
import { IModalSubscriber, ModalButton } from '../../context/ModalContext';
import { UNAUTHENTICATED } from '../../Typings';
import Button from '../common/Button';
import Input from '../common/Input';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import Loader from '../transition/Loader';
import ReactGA from 'react-ga4';
import './Login.css';

export enum LoginMode {
  Login,
  LoginExpired,
  ForgotPassword,
  ResetPassword
};

type LoginExtraParams = {
    email: string,
    token: string
};

type LoginBaseProps = {
  mode?: LoginMode;
  handleLogin?: (payload: any, persist: boolean) => void;
  handleForgotPassword?: (email: string) => void;
  handleCloseButtonClick?: () => void;
  extraParams?: LoginExtraParams;
};

export type LoginProps = LoginBaseProps & IModalSubscriber

const Login = ({
  mode = LoginMode.Login,
  handleLogin = () => {},
  handleForgotPassword = () => {},
  handleCloseButtonClick = () => {},
  extraParams,
  showBackButton = () => {},
  buttonClick,
}: LoginProps) => {
  const [ loginFunction, { loading: loginLoading } ] = useMutation(Mutations.LOGIN);
  const [ loginWithGoogleFunction, { loading: loginWithGoogleLoading } ] = useMutation(Mutations.LOGIN_WITH_GOOGLE);
  const [ sendPasswordRecoveryEmailFunction, { loading: sendPasswordRecoveryEmailLoading } ] = useMutation(Mutations.SEND_PASSWORD_RECOVERY_EMAIL);
  const [ resetPasswordFunction, { loading: resetPasswordLoading } ] = useMutation(Mutations.RESET_PASSWORD);
  
  const [ errorMessage, setErrorMessage ] = useState(() => mode === LoginMode.LoginExpired ? 'Your session expired.' : '');
  const [ successMessage, setSuccessMessage ] = useState('');
  const [ loginMode, setLoginMode ] = useState(mode);
  const [ bypassLoginValidation, setBypassLoginValidation ] = useState(false);
  const [ bypassForgotPasswordValidation ] = useState(false);
  const [ bypassResetPasswordValidation ] = useState(false);
  const signInWithGoogleRef = useRef<HTMLDivElement>(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().when([], {
      is: () => !bypassLoginValidation,
      then: Yup.string().email('Invalid email').required('Email is required'),
      otherwise: Yup.string().notRequired()
    }),
    password: Yup.string().when([], {
      is: () => !bypassLoginValidation,
      then: Yup.string().required('Password is required'),
      otherwise: Yup.string().notRequired()
    })
  });

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().when([], {
      is: () => !bypassForgotPasswordValidation,
      then: Yup.string().email('Invalid email').required('Email is required'),
      otherwise: Yup.string().notRequired()
    })
  });

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().when([], {
      is: () => !bypassResetPasswordValidation,
      then: Yup.string().required('Password is required'),
      otherwise: Yup.string().notRequired()
    }),
    newPasswordConfirm: Yup.string().when([], {
      is: () => !bypassResetPasswordValidation,
      then: Yup.string().required('Password is required').oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
      otherwise: Yup.string().notRequired()
    })
  });

  const handleForgotPasswordAnchorMouseDown = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setBypassLoginValidation(true);
  };

  const handleForgotPasswordAnchorClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    showBackButton(true);
    setLoginMode(LoginMode.ForgotPassword);
  };

  const handleForgotPasswordAnchorMouseUp = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setBypassLoginValidation(false);
  };

  const handleForgotPasswordSubmit = (values: any) => {
    document.body.style.cursor = 'wait';
    sendPasswordRecoveryEmailFunction({
      variables: {
        email: values.email
      }
    })
    .then((res: any) => {
      setErrorMessage('');

      ReactGA.event({
        category: 'meta',
        action: 'auth',
        label: 'send_reset_link', // id unique to current page load
        value: 1, // values must be integers
        nonInteraction: true, // avoids affecting bounce rate
        transport: 'xhr'
      });

      handleForgotPassword(values.email);
    })
    .catch((e: ApolloError) => {
      const errorMsg = getErrorMessage(e);
      setErrorMessage(errorMsg);
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  const handleResetPasswordSubmit = (values: any) => {
    document.body.style.cursor = 'wait';
    resetPasswordFunction({
      variables: {
        email: extraParams?.email,
        newPassword: values.newPassword
      },
      context: { headers: { 'x-access-token': extraParams?.token } }
    })
    .then((res: any) => {
      setSuccessMessage('Password Reset Successfully');

      ReactGA.event({
        category: 'meta',
        action: 'auth',
        label: 'reset_password', // id unique to current page load
        value: 1, // values must be integers
        nonInteraction: true, // avoids affecting bounce rate
        transport: 'xhr'
      });

      setLoginMode(LoginMode.Login);
    })
    .catch((e: ApolloError) => {
      const errorMsg = getErrorMessage(e);
      setErrorMessage(errorMsg);
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  const handleLoginSubmit = (values: any) => {
    document.body.style.cursor = 'wait';
    loginFunction({
      variables: {
        email: values.email,
        password: values.password
      }
    })
    .then((res: any) => {
      setErrorMessage('');
      handleLogin(res.data.login, values.rememberThisDevice);
    })
    .catch((e: ApolloError) => {
      let errorMsg = getErrorMessage(e);
      if (errorMsg === UNAUTHENTICATED)
        errorMsg = 'Unknown email or password';
      setErrorMessage(errorMsg);
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  const isLoading = () => {
    return loginLoading || loginWithGoogleLoading || sendPasswordRecoveryEmailLoading || resetPasswordLoading;
  };

  const handleGoogleResponse = useCallback(async (response: any) => {
    console.log(response);

    document.body.style.cursor = 'wait';
    loginWithGoogleFunction({
      variables: {
        credential: response.credential
      }
    })
    .then((res: any) => {
      setErrorMessage('');
      handleLogin(res.data.loginWithGoogle, false);
    })
    .catch((e: ApolloError) => {
      let errorMessage = getErrorMessage(e);
      if (errorMessage === UNAUTHENTICATED)
        errorMessage = 'Unknown email';
      setErrorMessage(errorMessage);
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  }, [loginWithGoogleFunction, handleLogin]);

  useEffect(() => {
    ReactGA.event({
      category: 'meta',
      action: 'show_dialog',
      label: 'login', // id unique to current page load
      value: 1, // values must be integers
      nonInteraction: true, // avoids affecting bounce rate
      transport: 'xhr'
    });
    
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleResponse
    });

    const ref = signInWithGoogleRef.current;
    if (ref) {
      google.accounts.id.renderButton(ref, { width: '317px' });
    }
  }, [handleGoogleResponse, signInWithGoogleRef]);

  useEffect(() => {
    if (buttonClick) {
      if (buttonClick.button === ModalButton.Back) {
        showBackButton(false)
        setLoginMode(LoginMode.Login);
      } else {
        handleCloseButtonClick();
      }
    }
  }, [buttonClick, showBackButton, handleCloseButtonClick]);

  if (loginMode === LoginMode.ForgotPassword) {
    return (
      <section className='modal-login forgot-password'>
        { isLoading() && <Loader /> }
        <Formik
          key={0}
          initialValues={{
            email: ''
          }}
          onSubmit={values => handleForgotPasswordSubmit(values)}
          validationSchema={ForgotPasswordSchema}
        >
          {() => (
            <Form style={isLoading() ? {opacity: '0.4'} : {}}>
              <table>
                <thead>
                  <tr><th><span className='off-black fw-b fs-18 ls-2' style={{ textAlign: 'center'}}>Forgot password?</span></th></tr>
                </thead>
                <tbody>
                  <tr><td><span className='off-black fw-3 fs-14 ls-2'>Enter the email address associated with your account. We'll send you a link to reset your password.</span></td></tr>
                  <tr><td><div><FastField autoFocus component={Input} name='email' placeholder='Email address' type='email' /></div></td></tr>
                  { errorMessage !== '' && <tr><td className='error-message'><span>{errorMessage}</span></td></tr> }
                </tbody>
              </table>
              <footer>
                <Button disabled={isLoading()} type='submit'>SEND RESET LINK</Button>
              </footer>
            </Form>
          )}
        </Formik>
      </section>    
    );
  };

  if (loginMode === LoginMode.ResetPassword) {
    return (
      <section className='modal-login reset-password'>
        { isLoading() && <Loader /> }
        <Formik
          key={0}
          initialValues={{
            newPassword: '',
            newPasswordConfirm: ''
          }}
          onSubmit={values => handleResetPasswordSubmit(values)}
          validationSchema={ResetPasswordSchema}
        >
          {() => (
            <Form style={isLoading() ? {opacity: '0.4'} : {}}>
              <table>
                <thead>
                  <tr><th><span className='off-black fw-b fs-18 ls-2' style={{ textAlign: 'center'}}>Reset Password</span></th></tr>
                </thead>
                <tbody>
                  <tr><td><span className='off-black fw-3 fs-14 ls-2'>Enter a new password to reset the password for your account.</span></td></tr>
                  <tr><td><div><FastField component={Input} name='newPassword' placeholder='New Password' type='password' /></div></td></tr>
                  <tr><td><div><FastField component={Input} name='newPasswordConfirm' placeholder='Confirm New Password' type='password' /></div></td></tr>
                  { successMessage !== '' && <tr><td className='success-message'><span>{successMessage}</span></td></tr> }
                  { errorMessage !== '' && <tr><td className='error-message'><span>{errorMessage}</span></td></tr> }
                </tbody>
              </table>
              <footer>
                <Button disabled={isLoading()} type='submit'>RESET PASSWORD</Button>
              </footer>
            </Form>
          )}
        </Formik>
      </section>    
    );
  };

  return (
    <section className='modal-login login'>
      { isLoading() && <Loader /> }
      <Formik
        key={1}
        initialValues={{
          email: '',
          password: '',
          rememberThisDevice: true
        }}
        onSubmit={values => handleLoginSubmit(values)}
        validationSchema={LoginSchema}
      >
        {() => (
          <Form style={isLoading() ? {opacity: '0.4'} : {}}>
            <table>
              <thead>
                <tr><th><TruckerFyLogo className='truckerfy-logo' theme={TruckerFyLogoTheme.OnWhite} isNotLink={true} /></th></tr>
                <tr><th><div ref={signInWithGoogleRef} style={{ height: '40px' }} /></th></tr>
              </thead>
              <tbody>
                <tr><td><div><FastField component={Input} name='email' placeholder='Email address' type='email' /></div></td></tr>
                <tr><td><div><FastField component={Input} name='password' placeholder='Password' type='password' /></div></td></tr>
                <tr><td><label><FastField type='checkbox' name='rememberThisDevice' style={{marginLeft: '10px'}} /><span>Remember this device</span></label></td></tr>
                { errorMessage !== '' && <tr><td className='error-message'><span>{errorMessage}</span></td></tr> }
              </tbody>
            </table>
            <footer>
              <Button disabled={isLoading()} type='submit'>SIGN IN</Button>
              <span className='login-link' onMouseDown={handleForgotPasswordAnchorMouseDown} onMouseUp={handleForgotPasswordAnchorMouseUp} onClick={handleForgotPasswordAnchorClick}>Forgot password?</span>
            </footer>
          </Form>
        )}
      </Formik>
    </section>
  );
};
  
export default Login;