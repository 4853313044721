import './FormFieldMessage.css';

export enum FormFieldMessageLevel {
  Error,
  Info
};

type FormFieldMessageProps = {
  text: any,
  level?: FormFieldMessageLevel
};

const FormFieldMessage = ({ text, level = FormFieldMessageLevel.Error }: FormFieldMessageProps) => (
  <span className={`form-field-message-${level === FormFieldMessageLevel.Error ? 'error' : 'info'}`}>{text}</span>
);

export default FormFieldMessage;