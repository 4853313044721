import { Redirect, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Mutations, getErrorMessage } from '../../util/Graphql';
import Loader from './Loader';
import Oops from '../pages/Oops';

export const ValidateDriverEmail = () => {
  const { id: idParam, email: emailParam } = useParams<{ id: string; email: string }>();
  const id = decodeURIComponent(idParam);
  const email = decodeURIComponent(emailParam);
  console.log(id, email);
  const [validateDriverMutation, { data, loading, error }] = useMutation(Mutations.VALIDATE_DRIVER_EMAIL, {
    variables: { id, email },
  });

  if (loading) {
    return <Loader />; 
  }

  if (error) { 
    const errorMsg = getErrorMessage(error);
    return <Oops error={errorMsg} />;
  }
  
  if (data) {
    const {
      email,
      tokens: { accessToken, refreshToken },
    } = data.validateEmail;
    // TBD: what happens here if the driver opens the confirmation email on a device on which the app is not installed?
    return (
      <Redirect
        to={{
          pathname: `/emailConfirmed/${email}/${accessToken}/${refreshToken}`,
        }}
      />
    );
  }
  
  validateDriverMutation();
  return <Loader />; // TODO: AJM - Unnecessary? Line 31 should detect a fetch in progress
};