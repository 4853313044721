import { FieldInputProps, FormikProps } from 'formik';
import FormFieldMessage from '../../common/FormFieldMessage';
import Input from '../../common/Input';
import ValidationErrorSvg from '../../../assets/ValidationError.svg';
import './DateAnswer.css';

export type DateAnswerProps = {
  field: FieldInputProps<any>;
  form: (string | undefined) & FormikProps<any>;
  placeholderText: string;
};

const DateAnswer = ({ field, form, ...props }: DateAnswerProps) => {
  const isInvalid = () => form.touched[field.name] && form.errors[field.name];
  return (
    <>
      <Input
        field={field}
        form={form}
        placeholder={props.placeholderText}
        type='date'
        style={{ width: '215px' }}
        className='date-answer'
        suppressValidation={true}
        required
      />
      {
        isInvalid() && 
          <div className='question-answer-validation'>
            <img src={ValidationErrorSvg} alt='Validation' />
            <FormFieldMessage text={form.errors[field.name]} />
          </div>
      }
    </>
  );
};

export default DateAnswer;