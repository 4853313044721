import './DriverApp.css';

import Navbar, { NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';

import { AppStoreButtons } from '../common/AppStoreButtons';
import { Link } from 'react-router-dom';

export const DriverApp = () => (
  <section className='landing'>
    <Navbar>
      <NavLogo>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} />
        </Link>
      </NavLogo>
    </Navbar>

    <div className='driverApp-main'>
      <h1>
        If you are a professional truck driver we have a mobile app just for
        you!
      </h1>
      <p>
        TruckerFy helps you find high-quality jobs that match your specific
        needs!
      </p>
      <AppStoreButtons />
    </div>
  </section>
);
