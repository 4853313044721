import { CloudinaryContext, Image } from 'cloudinary-react';

interface BannerImageProps {
  publicId?: string;
  width?: number;
  className?: string;
  onClick?: any;
};

const defaultBannerImageUrl = process.env.REACT_APP_CLOUDINARY_DEFAULT_BANNER?.length ? process.env.REACT_APP_CLOUDINARY_DEFAULT_BANNER : 'banners/production/ojwr0bbpkczzglehqwa9.png';

export const BannerImage = ({ publicId = defaultBannerImageUrl, width, className, onClick }: BannerImageProps) => {
  if (!publicId?.length) {
    publicId = defaultBannerImageUrl;
  }
  return (
    <CloudinaryContext cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD}>
      <Image
        publicId={publicId}
        // loading='lazy'
        className={className}
        onClick={onClick}
        style={{
          width: width,
          borderWidth: 1
        }}
      />
    </CloudinaryContext>
  );
};