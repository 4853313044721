import React, { useState, useRef, KeyboardEvent, InputHTMLAttributes, CSSProperties, ChangeEvent } from 'react';
import { Glyph } from './Input';
import FormFieldMessage from './FormFieldMessage';
import ValidationError from '../../assets/ValidationError.svg';
import './Input.css';
import './NumberBox.css';

export interface INumberBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  monetary?: boolean;
  isInvalid?: boolean;
  errorText?: string;
};

const NumberBox = ({ monetary = false, isInvalid = false, errorText, ...props }: INumberBoxProps) => {
  const [ currentValue, setCurrentValue ] = useState(props.defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClearMouseDown = (e: React.MouseEvent<HTMLImageElement>) => { 
    if (inputRef.current) {
      setCurrentValue(undefined);

      // @ts-ignore
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(inputRef.current, '');
        const changeEvent = new Event('change', { bubbles: true });
        inputRef.current.dispatchEvent(changeEvent);
      }
    }
    e.preventDefault();
  };

  const getRightPadding = () => {
    let padding = 15;
    if (currentValue !== undefined) padding += 19;
    if (isInvalid) padding += 24;
    return `${padding}px`;
  };

  const getRightPosition = (glyph: Glyph) => {
    const rightPos = (glyph === Glyph.Clear && isInvalid) ? 32 : 7;
    return `${rightPos}px`;
  };

  const getClassName = () => `custom-input${props.className ? ` ${props.className}` : ''}${monetary ? ' monetary' : ''} fw-3`;

  const getStyle = (style: CSSProperties | undefined) => ({
    ...style,
    ...props.style
  });

  const handleInput = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1'); // Digits-only
  
  const handleChangeInternal = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    setCurrentValue(val);
    if (props.onChange)
      props.onChange(e);
  };

  return (
    <div className='custom-input-container'>
      <div>
        <input
          ref={inputRef}
          maxLength={10}
          {...props}
          min={undefined}
          max={undefined}
          type='number'
          className={getClassName()}     
          style={getStyle({ paddingRight: getRightPadding() })}
          onInput={handleInput}
          onChange={handleChangeInternal}
        />
        { monetary && <i tabIndex={-1} className='custom-input-monetary'>$</i> }
        { (currentValue !== undefined) && <i tabIndex={-1} className='custom-input-clear' title='Clear field' style={{ right:getRightPosition(Glyph.Clear) }} onMouseDown={handleClearMouseDown}>&times;</i> }
        {
          isInvalid &&
            <img
              width='20px'
              className='custom-input-validation'
              title='Please fix the error displayed beneath the field'
              src={ValidationError} alt='Validation'
            />
        }
      </div>
      { isInvalid && <FormFieldMessage text={errorText} /> }
    </div>
  );
};

export default NumberBox;