import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTruckerFyApp } from '../../context/TruckerFyAppContext';
import { TABLET_VIEWPORT_WIDTH } from '../../Typings';
import Button, { ButtonTheme } from '../common/Button';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import ConfirmEmail from '../../assets/MacBookAir-ConfirmEmail.png';
import NavbarLayout from '../layouts/NavbarLayout';
import './CheckYourEmail.css';

export enum CheckYourEmailMode {
  VerifyEmail,
  ResetPassword,
};

type CheckYourEmailProps = {
  mode?: CheckYourEmailMode;
  email?: String;
};

const CheckYourEmail = ({ mode = CheckYourEmailMode.VerifyEmail, email = 'sample@test.com' }: CheckYourEmailProps) => {
  const location = useLocation<any>();
  const [ displayEmail, setDisplayEmail ] = useState('email');
  const [ checkYourEmailMode, setCheckYourEmailMode ] = useState(mode);
  const { zenDeskVisible, toggleZenDesk } = useTruckerFyApp();
  const [ mobile, setMobile ] = useState(false);
  const { email: emailParam } = useParams<{ email: string | undefined }>();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Check Your Email';

    return () => { document.title = prevTitle; };
  }, []);

  const handleWindowResize = useCallback(() => {
    const isMobile = window.innerWidth < TABLET_VIEWPORT_WIDTH;
    setMobile(isMobile)
  }, []);

  useLayoutEffect(() => {
    const isMobile = window.innerWidth < TABLET_VIEWPORT_WIDTH;
    setMobile(isMobile)
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  useLayoutEffect(() => {
    setCheckYourEmailMode(
      location.state?.mode ?? CheckYourEmailMode.VerifyEmail
    );
    let tempEmail = location.state?.email;
    if (!tempEmail) {
      if (emailParam) {
        tempEmail = decodeURIComponent(emailParam);
      }
    }
    setDisplayEmail(tempEmail ?? email);
  }, [emailParam, email, location.state]);

  const composeMessage = () => {
    if (checkYourEmailMode === CheckYourEmailMode.VerifyEmail) {
      return (
        <>
          <p>
            Almost there! We sent an email to&nbsp;
            <span style={{ fontWeight: 500 }}>{displayEmail}.</span>
          </p>
          <p>
            Click the link in your inbox to verify your email. Once that's done,
            we can set up an account for your company.
          </p>
        </>
      );
    } else if (checkYourEmailMode === CheckYourEmailMode.ResetPassword) {
      return (
        <>
          <p>
            Almost there! We sent an email to&nbsp;
            <span style={{ fontWeight: 500 }}>{displayEmail}.</span>
          </p>
          <p>Click the link in your inbox to reset your password.</p>
        </>
      );
    }
  };

  const handleContactUs = () => {
    if (!zenDeskVisible) toggleZenDesk();
  };

  return (
    <NavbarLayout>
      <header className={`check-your-email-header${mobile ? ' mobile' : ''}`}>
        <Navbar>
          <NavLogo>
            <TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} linkAddress='/' />
          </NavLogo>
          <NavEnd>
            <Button
              theme={ButtonTheme.White}
              onClick={handleContactUs}
            >
              Contact us
            </Button>
          </NavEnd>
        </Navbar>
      </header>
      <main className={`check-your-email-main${mobile ? ' mobile' : ''} fw-3 fs-16 ls-2`}>
        <div>
          <p className={`fs-${mobile ? '32' : '38'} ls-4`} style={{ lineHeight: '150%', marginTop: '0' }}>
            Check your email
          </p>
          {composeMessage()}
          {/* <p>
            Questions?&nbsp;
            <span className='check-your-email-link' onClick={handleContactUs}>
              Contact us
            </span>
          </p> */}
        </div>
        { !mobile && <img src={ConfirmEmail} alt='Macbook' /> }
      </main>
    </NavbarLayout>
  );
};

export default CheckYourEmail;