import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';

interface CarrierLogoProps {
  publicId?: string;
  size?: number;
  className?: string;
  onClick?: any;
};

const defaultCarrierLogoUrl = process.env.REACT_APP_CLOUDINARY_DEFAULT_LOGO?.length ? process.env.REACT_APP_CLOUDINARY_DEFAULT_LOGO : 'logos/production/wzozpp0i75vi9nmsagll.png';

export const CarrierLogo = ({ publicId = defaultCarrierLogoUrl, size = 40, className, onClick }: CarrierLogoProps) => {
  if (!publicId?.length) {
    publicId = defaultCarrierLogoUrl;
  }
  return (
    <CloudinaryContext cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD}>
      <Image
        publicId={publicId}
        // loading='lazy'
        className={className}
        onClick={onClick}
        style={{
          width: size,
          height: size,
          borderRadius: '10px',
          borderWidth: 1
        }}
      >
        <Transformation
          width={size}
          height={size}
          crop='thumb'
        />
      </Image>
    </CloudinaryContext>
  );
};
