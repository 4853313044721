import React, { useRef, useState, useEffect, useCallback, HTMLAttributes } from 'react';
import { EdgeAlignment } from '../../Typings';
import './DropdownContainer.css';

export interface DropdownContainerProps extends HTMLAttributes<HTMLElement> {
  containerEdgeAlignment?: EdgeAlignment;
  toggleHideDropdown?: boolean;
  root: JSX.Element;
  children: React.ReactNode;
};

const DropdownContainer = ({ containerEdgeAlignment = EdgeAlignment.Right, toggleHideDropdown, root, children, ...props }: DropdownContainerProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [ showDropdownInternal, setShowDropdownInteral ] = useState(false); 

  const handleWindowClick = useCallback((e: MouseEvent) => {
    if (showDropdownInternal) {
      setShowDropdownInteral(false);
      const ref = contentRef.current;
      if (ref) {
        ref.classList.remove('show');
      }
    }
  }, [showDropdownInternal]);

  const handleWindowKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      showDropdownInternal && setShowDropdownInteral(false);
      const ref = contentRef.current;
      if (ref) {
        ref.classList.remove('show');
      }
    }
  }, [showDropdownInternal]);

  const handleRootComponentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const ref = contentRef.current;
    if (ref) {
      ref.classList.toggle('show');
    }

    if (root.props.onClick) {
      root.props.onClick(e);
    }

    setShowDropdownInteral(!showDropdownInternal);
    e.stopPropagation();
  };

  const getClass = () => `dropdown-container${props.className ? ` ${props.className}` : ''}`;

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => { // Cleanup
      window.removeEventListener('click', handleWindowClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, [handleWindowClick, handleWindowKeyDown]);

  useEffect(() => {
    setShowDropdownInteral(false);
    const ref = contentRef.current;
    if (ref) {
      ref.classList.remove('show');
    }
  }, [toggleHideDropdown]);

  const handleDropdownContainerClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  return (
    <div className={getClass()} onClick={handleDropdownContainerClick} {...props}>
      {
        React.cloneElement(
          root,
          {
            className: `dropdown-container-component${showDropdownInternal ? ' show-filter' : ''}${root.props.className ? ` ${root.props.className}` : ''}`,
            onClick: handleRootComponentClick
          }
        )
      }
      <div ref={contentRef} className={`dropdown-container-content${containerEdgeAlignment === EdgeAlignment.Left ? ' align-left-edge' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default DropdownContainer;