import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../context/JobsContext';
import { ReactComponent as Arrow45Svg } from '../../../assets/Arrow45.svg'
import { ReactComponent as CloneSvg } from '../../../assets/Clone.svg';
import { ReactComponent as EditSvg } from '../../../assets/Edit.svg';
import { ReactComponent as ProfileSvg } from '../../../assets/Profile.svg'
import { ReactComponent as MessagesSvg } from '../../../assets/Conversation.svg'
import { ReactComponent as TruckFrontSvg } from '../../../assets/Job.svg'
import { ReactComponent as TruckFastSvg } from '../../../assets/vector-icons/materialcommunityicons-truck-fast.svg'
import { ReactComponent as EyeSvg } from '../../../assets/vector-icons/materialcommunityicons-eye.svg'
import { ReactComponent as SummitSvg } from '../../../assets/vector-icons/materialcommunityicons-summit.svg'
import { JobModel, JobStatus, UNAUTHENTICATED } from '../../../Typings';
import Navbar, { NavEnd } from '../../common/Navbar';
import Button from '../../common/Button';
import StateButton from '../../common/StateButton';
import Slideout from '../../common/Slideout';
import JobPost from './JobPost';
import FavoritesChart from './FavoritesChart';
import ViewsChart from './ViewsChart';
import { timeAgo } from '../../../util/TimeAgo';
import { getErrorMessage, Mutations } from '../../../util/Graphql';
import { ApolloError, useMutation } from '@apollo/client';
import Loader from '../../transition/Loader';
import { useModal } from '../../../context/ModalContext';
import EditConfirmText from '../../dialogs/EditConfirmText';
import './JobOverview.css';

enum Widget {
  Candidates = 'Candidates',
  Messages = 'Unread Messages',
  Favs = 'Saved as favorite',
  Views = 'Views'
};

type OverviewWidgetProps = {
  widget: Widget;
  quantity: number;
  setParentTabIndex?: (idx: number) => void;
};

const OverviewWidget = ({ widget, quantity, setParentTabIndex = () => {} }: OverviewWidgetProps) => {
  const composeImage = () => {
    let img: JSX.Element;
    let imgClass = 'glyph';
    switch (widget) {
      case Widget.Candidates:
        imgClass += ' hoverable';
        img = <ProfileSvg key='job-overview-svg1' className='move-up' />;
        break;
      case Widget.Messages:
        imgClass += ' hoverable';
        img = <MessagesSvg key='job-overview-svg2' />;
        break;
      case Widget.Views:
        img = <SummitSvg key='job-overview-svg3' />;
        break;
      default:
        img = <TruckFrontSvg key='job-overview-svg4' className='fix-color' />;
        break;
    };

    return (
      <div className='job-overview-widget-img-container'>
        <div className={`job-overview-widget-img ${imgClass}`}>
          {img}         
        </div>
      </div>
    );
  };

  const getArrowClass = () => {
    switch (widget) {
      case Widget.Candidates:
        return 'forest-green';
      case Widget.Messages:
        return 'medium-gray';
      default:
        return 'dark-gray';
    };
  };

  const handleWidgetClick = () => {
    // compose(<Announcement announcementMessage={`'${widget}' widget clicked`}/>);
    if (widget === Widget.Candidates)
      setParentTabIndex(1);
    else if (widget === Widget.Messages)
      setParentTabIndex(2);
  };

  return (
    <div className={`job-overview-widget${(widget === Widget.Favs || widget === Widget.Views) ? ' no-hover' : ''}`} style={{cursor: `${widget === Widget.Candidates || widget === Widget.Messages ? 'pointer' : 'default'}`}} onClick={handleWidgetClick}>
      { (widget === Widget.Candidates || widget === Widget.Messages) && <Arrow45Svg className={getArrowClass()} /> }
      <div className='job-overview-widget-inner'>
        {composeImage()}
        <p className='off-black fw-b fs-18 ls2'>{quantity}</p>
        <p className='off-black fw-3 fs-12 ls2'>{widget}</p>
      </div>
    </div>
  );
};

type OverviewViewsProps = {
  job: JobModel;
  setParentTabIndex?: (idx: number) => void;
};

const OverviewViews = ({ job, setParentTabIndex = () => {} }: OverviewViewsProps) => {
  const composeFavoritesChart = () => {
    if (job.favoritesByDate?.length)
      return (<FavoritesChart />);
    return (<div className='no-data fs-18'><span>No data</span></div>);
  };

  const composeViewsChart = () => {
    if (job.viewsByDate?.length)
      return (<ViewsChart />);
    return (<div className='no-data fs-18'><span>No data</span></div>);
  };

  const getViewsCount = () => {
    return job?.viewsByDate?.map(v => ((v.anonymousViews ?? 0) + (v.knownViews ?? 0))).reduce((p, n) => p + n, 0) ?? 0;
  };

  return (
    <div className='job-overview-views-container'>
      <div className='job-overview-view-container'>
        <div className='job-overview-view'>
          <div className='job-overview-view-header'>
            <TruckFastSvg />
            <span className='off-black fw-b fs-18 ls-2'>Favorites</span>
          </div>
          <div className='job-overview-view-chart'>
            {composeFavoritesChart()}
          </div>
        </div>
        <div className='job-overview-content-widget-container'>
          <OverviewWidget widget={Widget.Candidates} quantity={job?.numCandidates ?? 0} setParentTabIndex={setParentTabIndex} />
          <OverviewWidget widget={Widget.Messages} quantity={job.numUnreadMessagesForCarrier ?? 0} setParentTabIndex={setParentTabIndex} />
          <OverviewWidget widget={Widget.Favs} quantity={job.numFavorites ?? 0} />
        </div>     
      </div>
      <div className='job-overview-view-container'>
        <div className='job-overview-view'>
          <div className='job-overview-view-header'>
            <EyeSvg />
            <span className='off-black fw-b fs-18 ls-2'>Views</span>
          </div>
          <div className='job-overview-view-chart'>
            {composeViewsChart()}        
          </div>
        </div>
        <div className='job-overview-content-widget-container'>
          <OverviewWidget widget={Widget.Views} quantity={getViewsCount()} />
        </div>     
      </div>
    </div>
  );
};

export type JobOverviewProps = {
  job: JobModel;
  setParentTabIndex?: (idx: number) => void;
};

const JobOverview = ({ job, setParentTabIndex = () => {} }: JobOverviewProps) => {
  const { compose, destroy } = useModal();
  const { carrier, updateActiveJobStatus, archiveActiveJobAndSendMessageToRemainingApplicants } = useJobs();
  const [ jobStatus, setJobStatus ] = useState<JobStatus | undefined>();
  const [ toggleSlideout, setToggleSlideout ] = useState<boolean | undefined>();
  const [ isSlideoutVisible, setIsSlideoutVisible ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(false);
  const history = useHistory();

  const handlePreviewJobPostButtonClick = () => {
    setToggleSlideout(prevValue => !prevValue);
  };

  const handleEditClick = () => {
    history.push(`/editjob/${job.id}`, { job: job });
  };

  const [cloneJobFunction, { loading: cloneJobLoading }] = useMutation(Mutations.CLONE_JOB);

  const handleCloneClick = () => {
    document.body.style.cursor = 'wait';
    cloneJobFunction({
      variables: {
        jobId: job.id
      }
    })
    .then((res: any) => {
      history.push(`/editjob/${job.id}`, { job: res.data.cloneJob, isClone: true });
    })
    .catch((e: ApolloError) => {
      const errorMsg = getErrorMessage(e);
      if (errorMsg === UNAUTHENTICATED) {
        history.push('/login', { isAuthExpired: true });
      } else {
        history.push('/oops', { error: errorMsg });
      }
    })
    .finally(() => {
      document.body.style.cursor = 'default';
    });
  };

  const handleArchiveJob = async (text: string) => {
    destroy();
    document.body.style.cursor = 'wait';
    setIsLoading(true);
    await archiveActiveJobAndSendMessageToRemainingApplicants(text)
      .catch((e: ApolloError) => {
        const errorMsg = getErrorMessage(e);
        if (errorMsg === UNAUTHENTICATED) {
          history.push('/login', { isAuthExpired: true });
        } else {
          console.error(`Failed to archive job and send message. ${e}`); // TODO: AJM - Need to display message in an error dialog
          history.push('/oops', { error: errorMsg });
        }
      })
      .finally(() => { 
        document.body.style.cursor = 'default'
        setIsLoading(false);
      });
  };

  const handlePublicationStatusUpdate = async (jobPubStatus: JobStatus) => {
    if (job.publicationStatus === jobPubStatus) return;
    if (jobPubStatus === JobStatus.Archived) {
      const prompt = `Archive the '${job.internalTitle ?? job.title}' job and send the following message to the remaining candidates?`;
      const msg = `Thank you for considering the '${job.title ?? 'driver'}' position with ${carrier?.name ?? 'us'}, but the position is no longer available. We will keep your information on file in case anything changes and we wish you the best of luck in your job search.`;
      compose(<EditConfirmText prompt={prompt} text={msg} okButtonText='Archive &amp; Send' handleOk={handleArchiveJob} />);
    } else {
      document.body.style.cursor = 'wait';
      await updateActiveJobStatus(jobPubStatus)
        .catch((e: any) => {
          const errorMsg = getErrorMessage(e);
          if (errorMsg === UNAUTHENTICATED) {
            history.push('/login', { isAuthExpired: true });
          } else {
            console.error(`Failed to save job publication status change. ${e}`); // TODO: AJM - Need to display message in an error dialog
            history.push('/oops', { error: errorMsg });
          }
        })
        .finally(() => document.body.style.cursor = 'default');
    }
  };

  const composeToolbar = () => 
    <Navbar className='job-bar'>
      <NavEnd>
        <Button key='btn-preview-job-post' style={{marginLeft: '20px', marginRight: '10px'}} type='button' onClick={handlePreviewJobPostButtonClick}>{`${isSlideoutVisible ? 'Hide' : 'Preview'} Job Post`}</Button>
        <div className='job-bar-btn-container' title='Edit Job' onClick={handleEditClick}>
          <EditSvg/>
          <span>&nbsp;Edit</span>
        </div>
        <div className='job-bar-btn-container' title='Clone Job' onClick={handleCloneClick}>
          <CloneSvg/>
          <span>&nbsp;Clone</span>
        </div>
      </NavEnd>
    </Navbar>;

  const getPublishedAtBlurb = () => {
    return job.publishedAt
      ? `Published ${timeAgo(job.publishedAt)} (created ${job.createdAt})`
      : `created ${job.createdAt}`;
  };

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - Job Overview';

    return () => { document.title = prevTitle; };
  }, []);

  useLayoutEffect(() => setJobStatus(job.publicationStatus), [job.publicationStatus]);

  const onSlideoutVisibleChange = (isVisible: boolean) => setIsSlideoutVisible(isVisible); 
  
  return (
    <div key='jobOverview_div' className='job-overview'>
      { (cloneJobLoading || isLoading) && <Loader /> }
      {composeToolbar()}
      <div className='job-overview-content'>
        <Slideout key='job-overview-slideout' showInitially={true} toggle={toggleSlideout} onVisibleChange={onSlideoutVisibleChange} style={{ marginTop: '-24px'}}>
          <JobPost key='job-overview-job-post' job={job} hidePersonalData={false} />
        </Slideout>
        <p className='off-black fw-b fs-18 ls2' style={{ marginLeft: '4px', marginTop: '0', marginBottom: '4px' }}>Dashboard</p>
        <div className='job-overview-content-chart-container'>
          <OverviewViews job={job} setParentTabIndex={setParentTabIndex} />
        </div>
        <p className='off-black fw-b fs-18 ls2' style={{ marginLeft: '4px', marginBottom: '10px'}}>
          Status 
          <span className='off-black fw-n fs-12' style={{ marginLeft: '15px' }}>{getPublishedAtBlurb()}</span>
        </p>
        <div className='job-overview-content-btn-container'>
          <StateButton key='job-status-draft-btn' selected={jobStatus === JobStatus.Draft} onClick={() => handlePublicationStatusUpdate(JobStatus.Draft)}>{jobStatus === JobStatus.Draft ? 'Draft' : 'Set to Draft'}</StateButton> 
          &nbsp;        
          <StateButton key='job-status-published-btn' selected={jobStatus === JobStatus.Published} onClick={() => handlePublicationStatusUpdate(JobStatus.Published)}>{jobStatus === JobStatus.Published ? 'Published' : 'Set to Published'}</StateButton>         
          &nbsp;        
          <StateButton key='job-status-archived-btn' selected={jobStatus === JobStatus.Archived} onClick={() => handlePublicationStatusUpdate(JobStatus.Archived)}>{jobStatus === JobStatus.Archived ? 'Archived' : 'Send to Archive'}</StateButton>         
        </div>
      </div>
    </div>
  );
};

export default JobOverview;
