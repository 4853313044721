import { Chart } from 'chart.js';

interface CrosshairsChart extends Chart {
  crosshairs?: any;
  showHorizontalLine: boolean;
}

interface CrosshairsMouseEvent extends MouseEvent {
  x: number;
  y: number;
  inChartArea: boolean;
  event: MouseEvent;
}

// Draws a vertical dotted line that follows the mouse cursor
const CrosshairsChartPlugin = {
  id: 'crosshairs',

  afterInit: (chart: CrosshairsChart) => {
    chart.showHorizontalLine = false;
    chart.crosshairs = { x: 0, y: 0 };
  },

  afterEvent: (chart: CrosshairsChart, e: CrosshairsMouseEvent) => {
    const evt = e.event;
    const { x, y } = evt;

    if (!e.inChartArea) {
      chart.crosshairs = {
        x,
        y,
        draw: false
      };
      chart.draw();
      return;
    }

    chart.crosshairs = { x, y, draw: true };
    chart.draw();
  },

  afterDatasetsDraw: (chart: CrosshairsChart) => {
    const { ctx, chartArea: { top, bottom, left, right } } = chart;
    const { x, y, draw } = chart.crosshairs;

    if (!draw) return;

    ctx.save();
    ctx.lineWidth = 2;
    ctx.setLineDash([2, 2]);
    ctx.strokeStyle = 'var(--medium-gray)';

    ctx.beginPath();
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);

    if (chart.showHorizontalLine) {
      ctx.moveTo(left, y);
      ctx.lineTo(right, y);
    }

    ctx.stroke();
    ctx.restore();
  }
};

export default CrosshairsChartPlugin;