import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuestionnaire } from '../../context/QuestionnaireContext';
import { useAuth } from '../../context/AuthContext';
import { PersonAvatar } from '../specialized/cloudinary/PersonAvatar';
import Divider from '../common/Divider';
import Loader from '../transition/Loader';
import Navbar, { NavEnd, NavLogo } from '../common/Navbar';
import NavbarLayout from '../layouts/NavbarLayout';
import Section from '../specialized/questionnaire/Section';
import TruckerFyLogo from '../common/TruckerFyLogo';
import './Questionnaire.css';

const Questionnaire = () => {
  const [ doc, setDoc ] = useState<any>();
  const [ answers, setAnswers ] = useState<{[key: string]: Array<string>}>();
  const [ isSectionMutating, setIsSectionMutating ] = useState(false);
  const { questionnaireId, section } = useParams<{ questionnaireId: string, section: string }>(); 
  const { getUserInfo } = useAuth();
  const questionnaireService = useQuestionnaire();
  const location = useLocation<any>();
  const history = useHistory();

  const handleAnswersChange = (a: any) => setAnswers(a);

  const handleMutating = (isMutating: boolean) => setIsSectionMutating(isMutating);

  const composeSection = () => {
    const index = +section - 1;
    if (doc) {
      const templateJson = doc;
      const sections = templateJson.sections;
      const sectionJson = Object.values(sections)[index];

      if (!sectionJson)
        history.push('/oops', { error: 'Invalid parameters passed to questionnaire page.' })
      else
        return (
          <Section
            key={index}
            questionnaireId={questionnaireId.toUpperCase()}
            targetId={location.state?.targetId}
            isEditMode={location.state?.isEditMode}
            templateJson={templateJson} 
            sectionJson={sectionJson}
            answers={answers}
            index={index} 
            lastIndex={Object.values(sections).length - 1}
            handleAnswersChange={handleAnswersChange}
            handleMutating={handleMutating}
          />
        );
    }
  };

  useEffect(() => {
    if (questionnaireId && !doc) {
      const questionnaire = questionnaireService.getQuestionnaire(questionnaireId);
      if (questionnaire) {
        setDoc(questionnaire.json);

        let tempAnswers: {[key: string]: Array<string>} = {};
        const savedAnswers = location.state?.savedAnswers
        if (savedAnswers) {
          if (Array.isArray(savedAnswers)) {
            savedAnswers.forEach((qa: any) => tempAnswers[qa.questionId] = qa.answer);
          } else {
            tempAnswers = (savedAnswers as unknown) as ({[key: string]: Array<string>});
          }
        }
        setAnswers(tempAnswers);
      }
    }
  }, [questionnaireId, doc, location.state?.savedAnswers, questionnaireService]);

  return (
    <NavbarLayout>
      { isSectionMutating && <Loader /> }
      <header className='questionnaire-header'>
        <Navbar>
          <NavLogo><TruckerFyLogo /></NavLogo> 
          <NavEnd>
            <span style={{ color: 'white', paddingRight: '10px' }}>{getUserInfo()?.firstName} {getUserInfo()?.lastName}</span>
            <PersonAvatar publicId={getUserInfo()?.photoUrl} size={44} />
          </NavEnd>
        </Navbar>
      </header>
      <main className='questionnaire-main'>
        <div>
          <Divider />
          { doc && composeSection() }
        </div>
      </main>
    </NavbarLayout>
  );
};

export default Questionnaire;